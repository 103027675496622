import React from 'react'

export const PreviewContext = React.createContext({
  isDragging: false,
  onIsDragging: null,
  imageBoundRect: null,
  onImageBoundRectChanged: null,
  draggedPosition: null,
  onDraggedPosition: null,
  transitionProperty: 'none',
  onTransitionProperty: null,
  toolboxComponentAction: null,
  onToolboxComponentActionChanged: null,
  typeDisplay: '',
  url: '',
  onIsLoadingFile: null,
  isLoadingFile: false,
  isLoadingPage: false,
  updatePositionCenterScreenById: null,
  onChangeScale: null,
  byteLength: 0,
  zoomDetail: {
    initialScale: 0,
    pageFitScale: 0,
    pageHeightScale: 0,
    pageWidthScale: 0,
    orientation: '',
    scaleList: [],
  },
  updateZoomDetail: null,
  activeDrawingArea: '',
  updateActiveDrawingArea: null,
})
