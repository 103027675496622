import React from 'react';
import PropTypes from 'prop-types';

import PasswordShowHide from '../PasswordShowHide/PasswordShowHide';
import { withLabel } from '../../../hoc/withLabel/withLabel';

const PasswordField = ({
  isEmpty,
  isInvalid,
  onChange,
  value,
  name = null,
  maxLength = null,
  style = null,
  id = null,
  onKeyDown = null,
  autoComplete = "off"
}) => {
  return (
    <PasswordShowHide
      name={name}
      maxLength={maxLength}
      id={id}
      onKeyDown={onKeyDown}
      password={value}
      isEmpty={isEmpty}
      isInvalid={isInvalid}
      onChange={onChange}
      style={style}
      autoComplete={autoComplete} />
  );
};

PasswordField.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  isEmpty: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
  maxLength: PropTypes.any,
  value: PropTypes.string,
  autoComplete: PropTypes.string,
  style: PropTypes.object,
  onKeyDown: PropTypes.any,
};

export default withLabel(PasswordField);