import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { createTeamMemberTourGuideService } from '../../services/tourGuideService/createTeamMemberTourGuideService';
import { tourGuideAction } from '../../shared/tourGuideAction';
import { userDetailService } from '../../services/userDetailService/userDetailService';

function CreateTeamMemberTourGuide({ run }) {
    const [autoRun, setAutoRun] = useState(false);
    const [steps] = useState([
        {
            target: '.create-team-member-form',
            title: 'Add Team Member',
            content: (<p>Please note that every mandatory field must be filled (marked with a red asterisk <span className="tour-red">*</span>). Therefore, other than password, please complete the information by filling out the empty fields.</p>),
            disableBeacon: true,
            lastStep: true
        }
    ]);

    const customStyles = {
        overlay: {
            position: 'fixed',
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 999999
        },
        spotlight: {
            zIndex: 999999
        },
        buttonClose: {
            display: 'none',
        },
        floater: {
            tooltip: {
                zIndex: 999999
            }
        }
    };

    const handleJoyrideCallback = data => {
        const { status, lifecycle } = data;

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && lifecycle === "complete") {
            tourGuideAction("createTeamMember", "false", () => {
                userDetailService.emitReload(true);
            });
        }
    };

    useEffect(() => {
        const subscription = createTeamMemberTourGuideService.reload().subscribe((value) => {
            if (value && run) {
                setAutoRun(run);
            }
        });

        return () => {
            subscription.unsubscribe();
        }
    }, [run])

    return (
        <div className="tour-guide-user-menus">
            <Joyride
                callback={handleJoyrideCallback}
                run={autoRun}
                steps={steps}
                styles={customStyles}
                floaterProps={{disableAnimation: true}}
                showSkipButton={true}
                locale={{ back: 'Back', close: 'Close', last: 'Close', next: 'Next', skip: 'Skip' }}
                continuous={true} />
        </div>
    )
}

export default CreateTeamMemberTourGuide
