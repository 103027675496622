export const widgetType = {
  orderAttachment: "order-attachment",
  orderMoveProgress: "order-move-progress",
  orderStatusChange: "order-status-change",
  orderCommentCustomer: "order-comment-customer",
  orderCommentUser: "order-comment-user",
  annotationResolve: "annotation-resolve",
  annotationChanges: "annotation-updates",
  annotationCommentCustomer: "annotation-comment-customer",
  annotationCommentUser: "annotation-comment-user",
  annotationCommentPublic: "annotation-comment-public"
}