export default [
    {
        fullName: "January",
        shortName: "Jan"
    },
    {
        fullName: "February",
        shortName: "Feb"
    },
    {
        fullName: "March",
        shortName: "Mar"
    },
    {
        fullName: "April",
        shortName: "Apr"
    },
    {
        fullName: "May",
        shortName: "May"
    },
    {
        fullName: "June",
        shortName: "Jun"
    },
    {
        fullName: "July",
        shortName: "Jul"
    },
    {
        fullName: "August",
        shortName: "Aug"
    },
    {
        fullName: "September",
        shortName: "Sep"
    },
    {
        fullName: "October",
        shortName: "Oct"
    },
    {
        fullName: "November",
        shortName: "Nov"
    },
    {
        fullName: "December",
        shortName: "Dec"
    }
]