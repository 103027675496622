import { Subject } from 'rxjs';

const subject = new Subject();
const subjectTwo = new Subject();
const subjectCloseStepTwo = new Subject();

export const selectServiceTourGuideService = {
    emitReload: val => subject.next(val),
    reload: () => subject.asObservable()
};

export const selectServiceTourGuideStepTwoService = {
    emitReload: (callback) => subjectTwo.next(callback),
    reload: () => subjectTwo.asObservable()
};

export const selectServiceTourGuideStepTwoCloseService = {
    emitReload: val => subjectCloseStepTwo.next(val),
    reload: () => subjectCloseStepTwo.asObservable()
};