import { shapeClassname, toolboxColorPalette, attrsShapes } from './constants'
import {
  getDefaultKeyBinding,
  KeyBindingUtil,
  SelectionState,
  Modifier,
} from 'draft-js'

export const convertToImgRectPosition = (pos, imageBoundRect) => {
  return {
    x: (pos.x - imageBoundRect.x) / imageBoundRect.scale,
    y: (pos.y - imageBoundRect.y) / imageBoundRect.scale,
  }
}

export const revertToCanvasDimentionPosition = (pos, imageBoundRect) => {
  return {
    x: pos.x * imageBoundRect.scale + imageBoundRect.x,
    y: pos.y * imageBoundRect.scale + imageBoundRect.y,
  }
}

// v0.2
export const convertToScreenPosition = (pos, groupAttrs) => {
  return {
    x: pos.x + groupAttrs.x,
    y: pos.y + groupAttrs.y,
  }
}

// v0.2
export const convertToCanvasGroupPosition = (pos, groupAttrs) => {
  return {
    x: pos.x - groupAttrs.x,
    y: pos.y - groupAttrs.y,
  }
}

// v0.2
export const getGroupContainer = (pos, groups) => {
  let groupID = -1

  if (groups.length > 0) {
    groups.forEach((group, idx) => {
      const drawArea = {
        x: group.attrs.x,
        y: group.attrs.y,
        maxWidth: group.attrs.x + group.attrs.width,
        maxHeight: group.attrs.y + group.attrs.height,
      }

      if (
        pos.x >= drawArea.x &&
        pos.x <= drawArea.maxWidth &&
        pos.y >= drawArea.y &&
        pos.y <= drawArea.maxHeight
      ) {
        groupID = idx
      }
    })
  }

  return groupID
}

// v0.2
const getMaxWidthDrawArea = (startPos, movePos, groupAttrs) => {
  // const secreenStartPos = convertToScreenPosition(startPos, groupAttrs)
  const secreenMovePos = convertToScreenPosition(movePos, groupAttrs)
  const drawArea = {
    x: groupAttrs.x,
    y: groupAttrs.y,
    maxWidth: groupAttrs.x + groupAttrs.width,
    maxHeight: groupAttrs.y + groupAttrs.height,
  }

  // check area allowed drawing
  // x area
  if (secreenMovePos.x > drawArea.maxWidth) {
    return groupAttrs.width - startPos.x
  } else if (secreenMovePos.x < drawArea.x) {
    return 0 - startPos.x
  }

  return movePos.x - startPos.x
}

// v0.2
const getMaxHeightDrawArea = (startPos, movePos, groupAttrs) => {
  // const secreenStartPos = convertToScreenPosition(startPos, groupAttrs)
  const secreenMovePos = convertToScreenPosition(movePos, groupAttrs)
  // TODO: refactoring, make this func
  const drawArea = {
    x: groupAttrs.x,
    y: groupAttrs.y,
    maxWidth: groupAttrs.x + groupAttrs.width,
    maxHeight: groupAttrs.y + groupAttrs.height,
  }

  // check area allowed drawing
  // y area
  if (secreenMovePos.y > drawArea.maxHeight) {
    return groupAttrs.height - startPos.y
  } else if (secreenMovePos.y < drawArea.y) {
    return 0 - startPos.y
  }

  return movePos.y - startPos.y
}

// v0.2
export const createShapeObject = (
  action,
  groupAttrs,
  startPos,
  movePos = null,
  pencilPoints = [],
  color = toolboxColorPalette.BLUE,
  attrShape = {}
) => {
  let attrs = {}
  let width = 0
  let height = 0

  if (movePos !== null) {
    width = getMaxWidthDrawArea(startPos, movePos, groupAttrs)
    height = getMaxHeightDrawArea(startPos, movePos, groupAttrs)
  }

  switch (action.type) {
    case shapeClassname.ARROW:
      attrs = {
        id: ID(),
        x: startPos.x,
        y: startPos.y,
        points: [0, 0, width, height],
        fill: color,
        stroke: color,
        shadowColor: color,
        pointerLength: attrsShapes.pointerLength,
        pointerWidth: attrsShapes.pointerWidth,
        strokeWidth: attrsShapes.strokeWidth,
        ...attrShape,
      }
      break
    case shapeClassname.RECT:
      attrs = {
        id: ID(),
        x: startPos.x,
        y: startPos.y,
        width: width,
        height: height,
        stroke: color,
        shadowColor: color,
        fill: attrsShapes.shapeFill,
        strokeWidth: attrsShapes.strokeWidth,
        ...attrShape,
      }
      break
    case shapeClassname.CIRCLE:
      attrs = {
        id: ID(),
        x: startPos.x,
        y: startPos.y,
        stroke: color,
        fill: color,
        shadowColor: color,
        radius: attrsShapes.radius,
        strokeWidth: attrsShapes.strokeWidth,
        ...attrShape,
      }
      break
    default:
      attrs = {
        id: ID(),
        points: pencilPoints,
        stroke: color,
        shadowColor: color,
        strokeWidth: attrsShapes.strokeWidth,
        ...attrShape,
      }
  }

  return {
    className: action.type,
    attrs: {
      ...attrs,
    },
    scale: 1,
    page: 1,
    isUnsaved: true,
    unsavedComment: '',
  }
}

// v0.2
const getPercent = (n, total) => {
  const i = total - n
  const p = (i / n) * 100

  return p
}

// v0.2
const getScaledByPercent = (percent, n) => {
  return n + (n * percent) / 100
}

// v0.2
export const updatePositionScaled = (shapeAttrs, currentScaleGroupAttr) => {
  if (typeof shapeAttrs !== 'undefined' && shapeAttrs.length > 0) {
    shapeAttrs.forEach((item) => {
      if (
        item.attrs.groupAttrs.width !== currentScaleGroupAttr.width &&
        item.attrs.groupAttrs.height !== currentScaleGroupAttr.height
      ) {
        const coordianatePercent = {
          x: getPercent(
            item.attrs.groupAttrs.width,
            currentScaleGroupAttr.width
          ),
          y: getPercent(
            item.attrs.groupAttrs.height,
            currentScaleGroupAttr.height
          ),
        }

        if (item.className === shapeClassname.CIRCLE) {
          item.attrs.x = getScaledByPercent(coordianatePercent.x, item.attrs.x)
          item.attrs.y = getScaledByPercent(coordianatePercent.y, item.attrs.y)
        } else if (item.className === shapeClassname.ARROW) {
          item.attrs.x = getScaledByPercent(coordianatePercent.x, item.attrs.x)
          item.attrs.y = getScaledByPercent(coordianatePercent.y, item.attrs.y)
          const width = getScaledByPercent(
            coordianatePercent.x,
            item.attrs.points[2]
          )
          const height = getScaledByPercent(
            coordianatePercent.y,
            item.attrs.points[3]
          )
          item.attrs.points = [0, 0, width, height]
        } else if (item.className === shapeClassname.RECT) {
          item.attrs.x = getScaledByPercent(coordianatePercent.x, item.attrs.x)
          item.attrs.y = getScaledByPercent(coordianatePercent.y, item.attrs.y)
          item.attrs.width = getScaledByPercent(
            coordianatePercent.x,
            item.attrs.width
          )
          item.attrs.height = getScaledByPercent(
            coordianatePercent.y,
            item.attrs.height
          )
        } else if (item.className === shapeClassname.LINE) {
          const points = []
          item.attrs.points.forEach((p, k) => {
            let x = 0
            if (k % 2 === 0) {
              x = getScaledByPercent(coordianatePercent.x, p)
            } else {
              x = getScaledByPercent(coordianatePercent.y, p)
            }
            points.push(x)
          })
          item.attrs.points = points
        }

        item.attrs.groupAttrs = currentScaleGroupAttr
      }
    })
  }

  return shapeAttrs
}

export const parseAttrShape = (
  action,
  startPos,
  movePos = null,
  pencilPoints = [],
  color = toolboxColorPalette.BLUE,
  imageBoundRect = null,
  attrShape = {}
) => {
  let attrs = {}
  let width = 0
  let height = 0

  if (movePos !== null) {
    width = getWidthMaxShape(imageBoundRect, movePos, startPos)
    height = getHeightMaxShape(imageBoundRect, movePos, startPos)
  }

  switch (action.type) {
    case shapeClassname.ARROW:
      attrs = {
        id: ID(),
        x: startPos.x,
        y: startPos.y,
        points: [0, 0, width, height],
        fill: color,
        stroke: color,
        shadowColor: color,
        pointerLength: attrsShapes.pointerLength,
        pointerWidth: attrsShapes.pointerWidth,
        strokeWidth: attrsShapes.strokeWidth,
        ...attrShape,
      }
      break
    case shapeClassname.RECT:
      attrs = {
        id: ID(),
        x: startPos.x,
        y: startPos.y,
        width: width,
        height: height,
        stroke: color,
        shadowColor: color,
        fill: attrsShapes.shapeFill,
        strokeWidth: attrsShapes.strokeWidth,
        ...attrShape,
      }
      break
    case shapeClassname.CIRCLE:
      attrs = {
        id: ID(),
        x: startPos.x,
        y: startPos.y,
        stroke: color,
        fill: color,
        shadowColor: color,
        radius: attrsShapes.radius,
        strokeWidth: attrsShapes.strokeWidth,
        ...attrShape,
      }
      break
    default:
      attrs = {
        id: ID(),
        points: pencilPoints,
        stroke: color,
        shadowColor: color,
        strokeWidth: attrsShapes.strokeWidth,
        ...attrShape,
      }
  }

  return {
    className: action.type,
    attrs: {
      ...attrs,
    },
    scale: imageBoundRect.scale,
    page: imageBoundRect.scale,
    isUnsaved: true,
    unsavedComment: '',
  }
}

const getWidthMaxShape = (imageBoundRect, movePos, startPos) => {
  const posPreviewBoundary = {
    x: imageBoundRect.x + imageBoundRect.width,
    y: imageBoundRect.y + imageBoundRect.height,
  }

  const revrtStartPos = revertToCanvasDimentionPosition(
    startPos,
    imageBoundRect
  )
  const revrtMovePos = revertToCanvasDimentionPosition(movePos, imageBoundRect)

  if (revrtMovePos.x > posPreviewBoundary.x) {
    return (posPreviewBoundary.x - revrtStartPos.x) / imageBoundRect.scale
  } else if (revrtMovePos.x < imageBoundRect.x) {
    return (imageBoundRect.x - revrtStartPos.x) / imageBoundRect.scale
  }

  return movePos.x - startPos.x
}

const getHeightMaxShape = (imageBoundRect, movePos, startPos) => {
  const posPreviewBoundary = {
    y: imageBoundRect.y + imageBoundRect.height,
  }

  const revrtStartPos = revertToCanvasDimentionPosition(
    startPos,
    imageBoundRect
  )
  const revrtMovePos = revertToCanvasDimentionPosition(movePos, imageBoundRect)

  if (revrtMovePos.y > posPreviewBoundary.y) {
    return (posPreviewBoundary.y - revrtStartPos.y) / imageBoundRect.scale
  } else if (revrtMovePos.y < imageBoundRect.y) {
    return (imageBoundRect.y - revrtStartPos.y) / imageBoundRect.scale
  }

  return movePos.y - startPos.y
}

export const getSelectionContent = (editorState) => {
  const currentContent = editorState.getCurrentContent()
  const firstBlock = currentContent.getBlockMap().first()
  const lastBlock = currentContent.getBlockMap().last()
  const firstBlockKey = firstBlock.getKey()
  const lastBlockKey = lastBlock.getKey()
  const lengthOfLastBlock = lastBlock.getLength()

  return new SelectionState({
    anchorKey: firstBlockKey,
    anchorOffset: 0,
    focusKey: lastBlockKey,
    focusOffset: lengthOfLastBlock,
  })
}

export const setStrokeContent = (editorState) => {
  const currentContent = editorState.getCurrentContent()
  const currentSelection = getSelectionContent(editorState)

  return Modifier.applyInlineStyle(
    currentContent,
    currentSelection,
    'STRIKETHROUGH'
  )
}

export const setClearStrokeContent = (editorState) => {
  const currentContent = editorState.getCurrentContent()
  const currentSelection = getSelectionContent(editorState)

  return Modifier.removeInlineStyle(
    currentContent,
    currentSelection,
    'STRIKETHROUGH'
  )
}

const { hasCommandModifier } = KeyBindingUtil
export const saveKeyBindingFn = (e) => {
  if (e.keyCode === 13 && hasCommandModifier(e)) {
    return 'editor-send-comment'
  }
  return getDefaultKeyBinding(e)
}

export const ID = () => {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Math.random().toString(36).substr(2, 9)
}

export const hexToRgb = (hex) => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null
}

// const heighToolbox = 107
export const heightHeader = 60
export const marginHeader = 20

export const centeringPDFByHeight = (el, heightPage) => {
  // const heightScreen = window.innerHeight - 60
  // const paddingHeight = (heightScreen - heightPage) / 2

  // el.scrollTop = window.innerHeight / 2 - 60 - paddingHeight

  // put top by window screen
  const top = window.innerHeight / 2 - (heightHeader + marginHeader)
  el.scrollTop = top
}

export const isDrawingArea = (name = '') => {
  return name.search('drawing-area') > -1
}
