import React from 'react';

import './SkeletonLine.scss';

const SkeletonLine = ({ style }) => {
  return (
    <div className="skeleton-pulse skeleton-line" style={style}></div>
  );
};

export default SkeletonLine;