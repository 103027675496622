import React, { useRef, useEffect } from 'react'

import './CommentList.scss'
import Comment from './Comment'
import Avatar from '../../../Avatar/Avatar'

const CommentList = ({
  onEditComment,
  onDeleteComment,
  onShowConfirmDelete,
  onResolveComment,
  onReopenComment,
  isMinimize = false,
  comments = [],
  isTooltip = false,
  isResolve = false,
  isReadOnly = false,
}) => {
  const messagesEndRef = useRef()

  useEffect(() => {
    if (comments.length > 0) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
    }
  }, [comments])

  return (
    <div
      className={`comment-container-wrapper ${
        isReadOnly ? 'comment-list-readonly' : ''
      }`}
    >
      {comments.length > 0 && (
        <ul className="list-unstyled m-0">
          {comments.map((comment, idx) => (
            <Comment
              comment={comment}
              isMinimize={isMinimize}
              key={idx}
              onEditComment={onEditComment}
              onShowConfirmDelete={onShowConfirmDelete}
              isTooltip={isTooltip}
              isResolve={isResolve}
              onDeleteComment={onDeleteComment}
              onResolveComment={onResolveComment}
              onReopenComment={onReopenComment}
              isReadOnly={isReadOnly}
            />
          ))}
        </ul>
      )}

      {comments.length > 1 && isMinimize && (
        <div className="comment-preview">
          <div className="ml-3 mt-3 d-flex align-items-center ">
            <div className="avatar-comment-preview">
              <Avatar
                src={comments[comments.length - 1].user.avatar}
                user={{ firstname: comments[comments.length - 1].user.name }}
                radius={32}
              />
            </div>
            <div className="reply-number ml-2">
              <span>
                {comments.length - 1}&nbsp;
                {comments.length > 2 ? 'replies' : 'reply'}
              </span>
            </div>
          </div>
        </div>
      )}
      <div ref={messagesEndRef} />
    </div>
  )
}

export default CommentList
