import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useCallback,
} from 'react'
import { EditorState, convertToRaw } from 'draft-js'

import CommentEditor from '../CommentEditor/CommentEditor'
import CommentList from '../Comment/CommentList'
import CommentEditingEditor from '../CommentEditingEditor/CommentEditingEditor'
import { AnnotationActionContext } from '../../context/annotationActionContext'
import { TooltipContext } from '../../context/tooltipContext'
import { VersionContext } from '../../context/versionContext'
import { ShapeContext } from '../../context/shapeContext'

const CommentContainer = ({
  comments = [],
  isMinimize = false,
  isTooltip = false,
  isResolve = false,
  onTooltipClose = null,
}) => {
  const wrapperRef = useRef()
  const {
    onSendComment,
    onSaveEditComment,
    onDeleteComment,
    onResolveComment,
    onReopenComment,
  } = useContext(AnnotationActionContext)
  const { selectedAnnotation, selectedTopic } = useContext(TooltipContext)
  const { selectedVersion } = useContext(VersionContext)
  const { clearActiveDrawingArea, isReadOnly } = useContext(ShapeContext)

  const [editCommentState, setEditCommentState] = useState(
    EditorState.createEmpty()
  )
  const [isEditingComment, setIsEditingComment] = useState(false)
  const [editingCommentId, setEditingCommentId] = useState(null)
  const [isTopic, setIsTopic] = useState(false)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const handleClickOutside = useCallback((e) => {
    if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
      setIsEditingComment(false)
    }
  }, [])

  const handleSendComment = useCallback(
    (editorState, mentions = []) => {
      const editorJSON = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      )

      if (comments.length === 0 && isTooltip) {
        // create topic
        const annotation = JSON.stringify(selectedAnnotation)
        onSendComment(
          selectedVersion.id,
          editorJSON,
          mentions,
          null,
          annotation
        )
      } else {
        // reply topic
        onSendComment(
          selectedVersion.id,
          editorJSON,
          mentions,
          selectedTopic.topicId,
          null,
          false
        )
      }

      if (typeof clearActiveDrawingArea === 'function') {
        clearActiveDrawingArea(true)
      }
    },
    [
      clearActiveDrawingArea,
      comments.length,
      isTooltip,
      onSendComment,
      selectedAnnotation,
      selectedTopic,
      selectedVersion.id,
    ]
  )

  const handleUpdateComment = useCallback(
    (editorState, mentions = [], callback = null) => {
      const editorJSON = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      )
      onSaveEditComment(
        selectedVersion.id,
        editingCommentId,
        editorJSON,
        mentions,
        isTopic
      )

      setIsEditingComment(false)

      if (typeof callback === 'function') {
        callback()
      }
    },
    [editingCommentId, isTopic, onSaveEditComment, selectedVersion.id]
  )

  const handleEditComment = useCallback(
    (editCommentEditorState, commentId) => {
      const selectedComment = comments.filter((x) => x.id === commentId)

      setEditCommentState(editCommentEditorState)
      setEditingCommentId(commentId)
      setIsTopic(selectedComment[0].isTopic)
      setIsEditingComment(true)
    },
    [comments]
  )

  const handleShowConfirmDelete = useCallback(() => {
    // close editing editor
    setIsEditingComment(false)
  }, [])

  const handleDeleteComment = useCallback(
    (versionId, commentId, isTopic) => {
      onDeleteComment(versionId, commentId, isTopic)
    },
    [onDeleteComment]
  )

  const handleResolveComment = useCallback(
    (topicId) => {
      onResolveComment(selectedVersion.id, topicId)
    },
    [onResolveComment, selectedVersion.id]
  )

  const handleReopenComment = useCallback(
    (topicId) => {
      onReopenComment(selectedVersion.id, topicId)
    },
    [onReopenComment, selectedVersion.id]
  )

  return (
    <>
      {comments.length > 0 && (
        <CommentList
          comments={comments}
          isMinimize={isMinimize}
          onEditComment={handleEditComment}
          onDeleteComment={handleDeleteComment}
          onShowConfirmDelete={handleShowConfirmDelete}
          onResolveComment={handleResolveComment}
          onReopenComment={handleReopenComment}
          isResolve={isResolve}
          isTooltip={isTooltip}
          isReadOnly={isReadOnly}
        />
      )}

      {isEditingComment ? (
        <div ref={wrapperRef}>
          <CommentEditingEditor
            editCommentState={editCommentState}
            onSend={handleUpdateComment}
            onCancel={() => setIsEditingComment(false)}
          />
        </div>
      ) : (
        !isReadOnly && (
          <CommentEditor
            onSend={handleSendComment}
            onTooltipClose={onTooltipClose}
            isTooltip={isTooltip}
          />
        )
      )}
    </>
  )
}

export default React.memo(CommentContainer)
