import * as actionTypes from "./actionTypes";

export const apiStart = () => {
  return {
    type: actionTypes.API_START
  };
};

export const apiStop = () => {
  return {
    type: actionTypes.API_STOP
  };
};