import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import tippy from 'tippy.js'
import { isFloat } from '../../../shared/utility'

const MembershipAllocatedHours = ({ user, isLoading, style, history }) => {
  const containerRef = useRef()
  const [extraRetail, setExtraRetail] = useState(0)
  const [subscriptionRetail, setSubscriptionRetail] = useState(0)
  const [customTippy, setCustomTippy] = useState()

  useEffect(() => {
    if (user !== null) {
      let totalExtra = 0,
        totalSubscription = 0

      totalExtra += parseInt(user.retail ? user.retail.allocatedHourExtra : 0)
      totalSubscription += parseInt(
        user.retail ? user.retail.allocatedHourSubscription : 0
      )
      if (totalExtra > 0) setExtraRetail(totalExtra)
      if (totalSubscription > 0) setSubscriptionRetail(totalSubscription)
    }

    const activeOrderPopup = document.getElementById('allocated-hour-popup')

    const customTippy = tippy(containerRef.current, {
      content: activeOrderPopup.innerHTML,
      animation: 'shift-away',
      zIndex: 1020,
      appendTo: containerRef.current,
      allowHTML: true,
    })
    setCustomTippy(customTippy)
  }, [user])

  useEffect(() => {
    const activeOrderPopup = document.getElementById('allocated-hour-popup')

    if (customTippy)
      customTippy.setProps({
        content: activeOrderPopup.innerHTML,
        animation: 'shift-away',
        zIndex: 1020,
        appendTo: containerRef.current,
        allowHTML: true,
      })
  }, [customTippy])

  if ((user === null && isLoading) || (user === null && !isLoading)) {
    return null
  }

  const used =
    user.membership.allocatedHour.used !== undefined
      ? parseFloat(user.membership.allocatedHour.used) +
        (user.retail
          ? parseFloat(user.retail ? user.retail.allocatedHourUsed : 0) / 3600
          : 0)
      : 0

  const space = parseFloat(
    user.membership.allocatedHour.quota !== undefined
      ? user.membership.allocatedHour.quota
      : 0
  )

  const percentOfUsed = user.membership.orders.used ? (used / space) * 100 : 0

  return (
    <div
      className="membership-status"
      ref={containerRef}
      style={Object.assign(style, {})}
    >
      <div id="allocated-hour-popup" style={{ display: 'none' }}>
        <span>
          Your allocated hours per month minus the time used against your active
          orders.
        </span>
        {(extraRetail > 0 || subscriptionRetail > 0) && (
          <>
            <div>
              <div className="d-flex justify-content-between mt-2">
                <div>{user.membership.title} membership</div>
                <div>
                  {user.membership.allocatedHour.packageAllocatedHours} hours
                </div>
              </div>
              {subscriptionRetail > 0 ? (
                <div className="d-flex justify-content-between mt-1 text-subscription-retail">
                  <div>Subscription Allocated Hours</div>
                  <div>
                    {user.retail.allocatedHourSubscription / 3600} hours
                  </div>
                </div>
              ) : (
                ''
              )}
              {extraRetail > 0 ? (
                <div className="d-flex justify-content-between mt-1 text-extra-retail">
                  <div>Extra Allocated Hours</div>
                  <div>{user.retail.allocatedHourExtra / 3600} hours</div>
                </div>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </div>
      <div className="clearfix text-membership-status w-100">
        <div className="float-left d-flex">
          <span className="text-12 text-light">Used Hours</span>
          {subscriptionRetail > 0 && (
            <span className="dot-subscription-retail"> </span>
          )}
          {extraRetail > 0 && <span className="dot-extra-retail"> </span>}
        </div>
        <div className="float-right clearfix d-flex align-items-center">
          <div
            className={`text-12 ${
              parseInt(percentOfUsed) >= 90 ? 'text-danger' : 'text-light'
            }`}
          >
            <strong>
              {user && isFloat(parseFloat(used)) ? used.toFixed(1) : used}
            </strong>
            <strong>&nbsp;Hours</strong>
          </div>
        </div>
      </div>
    </div>
  )
}

MembershipAllocatedHours.propTypes = {
  style: PropTypes.object,
}

export default withRouter(MembershipAllocatedHours)
