import { Subject } from 'rxjs';

const subject = new Subject();
const subjectClose = new Subject();

export const manageProfileTourGuideService = {
    emitReload: val => subject.next(val),
    reload: () => subject.asObservable()
};

export const manageProfileTourGuideCloseService = {
    emitReload: val => subjectClose.next(val),
    reload: () => subjectClose.asObservable()
};