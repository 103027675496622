import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

import Logo from '../Logo/Logo'
import Modal from '../Modal/Modal'
import * as Router from '../../shared/router'

const PaymentMethodRequired = ({ show, onHide }) => {
  return (
    <Modal
      show={show}
      onHideModal={onHide}
      isForceShow={true}
      modalSize="modal-md"
      useClose={false}
    >
      <div className="d-flex flex-column">
        <div className="mb-3">
          <Logo width={120} />
        </div>
        <div className="mb-3">
          <span className="text-16 font-weight-bold">
            Payment Method Required!
          </span>
        </div>
        <div className="">
          <p>You're almost done. Please complete your payment information.</p>
        </div>
        <div>
          <NavLink to={Router.paymentMethod} className="btn btn-darkprimary">
            <span>Go to payment methods</span>
          </NavLink>
        </div>
      </div>
    </Modal>
  )
}

PaymentMethodRequired.propTypes = {
  show: PropTypes.bool.isRequired,
  onHide: PropTypes.func.isRequired,
}

export default PaymentMethodRequired
