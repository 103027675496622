import React from 'react'

export const HeaderAnnotationContext = React.createContext({
  isShowPublicComment: false,
  isShowCommentPanel: false,
  onShowPublicComment: null,
  onShowInternalComment: null,
  onShowCommentPanel: null,
  isShowVersionPanel: false,
  onShowVersionPanel: null,
  isInternalTabDisabled: false,
  isPublicTabDisabled: false,
})
