import React, { useContext, useEffect, useState, useCallback } from 'react'
import * as Icon from 'react-feather'
import update from 'immutability-helper'
import tippy from 'tippy.js'
import 'tippy.js/themes/light.css'

import './Toolbox.scss'
import { shapeClassname, toolboxActionHandler } from '../../shared/constants'
import ColorPalette from '../ColorPalette/ColorPalette'
import Zoom from '../Zoom/Zoom'
import { PreviewContext } from '../../context/previewContext'
import { ShapeContext } from '../../context/shapeContext'
import FeatherIcon from '../../../FeatherIcon/FeatherIcon'
import { TooltipContext } from '../../context/tooltipContext'

const Toolbox = () => {
  const [isFitWindow, setIsFitWindow] = useState(false)
  const {
    onToolboxComponentActionChanged,
    toolboxComponentAction,
    imageBoundRect,
    onImageBoundRectChanged,
    onChangeScale,
    zoomDetail,
  } = useContext(PreviewContext)
  const { isShapeShowed, onIsShapeShowed, isReadOnly } = useContext(
    ShapeContext
  )
  const tooltipContext = useContext(TooltipContext)
  const { onHighlightUnsaved, hasUnsavedComment } = tooltipContext
  const { naturalWidth, naturalHeight } = imageBoundRect

  useEffect(() => {
    tippy('.btn-toolbox, .btn-highligh-unsaved, .btn-zoom', {
      animation: 'shift-away',
      placement: 'bottom',
      theme: 'light',
      onTrigger(instance, _) {
        instance.setContent(instance.reference.dataset.tippyContent)
      },
    })
  }, [hasUnsavedComment])

  const handleChangeColor = useCallback(
    (color) => {
      onToolboxComponentActionChanged({
        ...toolboxComponentAction,
        color: color,
      })
    },
    [onToolboxComponentActionChanged, toolboxComponentAction]
  )

  const handleToolboxAction = useCallback(
    (
      e,
      type = shapeClassname.CIRCLE,
      handler = toolboxActionHandler.MOUSE_CLICK
    ) => {
      e.preventDefault()
      e.stopPropagation()

      onToolboxComponentActionChanged({
        ...toolboxComponentAction,
        type: type,
        handler: handler,
      })
    },
    [onToolboxComponentActionChanged, toolboxComponentAction]
  )

  const handleFitWindowSize = useCallback(() => {
    if (naturalWidth !== null && naturalHeight !== null) {
      let scale = 1

      if (!isFitWindow) {
        scale = Math.max(zoomDetail.pageHeightScale, zoomDetail.pageWidthScale)
      } else {
        scale = zoomDetail.initialScale
      }

      const widthScaled = naturalWidth * scale
      const heightScaled = naturalHeight * scale

      onChangeScale(parseFloat(scale))

      onImageBoundRectChanged((x) =>
        update(x, {
          width: { $set: widthScaled },
          height: { $set: heightScaled },
          scale: { $set: scale },
        })
      )

      setIsFitWindow(!isFitWindow)
    }
  }, [
    isFitWindow,
    naturalHeight,
    naturalWidth,
    onChangeScale,
    onImageBoundRectChanged,
    zoomDetail,
  ])

  const handleChangeZoom = useCallback(
    (currentScale) => {
      const scaleMax = Math.max(
        zoomDetail.pageHeightScale,
        zoomDetail.pageWidthScale
      )

      if (currentScale === scaleMax) {
        setIsFitWindow(true)
      } else if (currentScale === zoomDetail.initialScale) {
        setIsFitWindow(false)
      }
    },
    [zoomDetail]
  )

  const handleToggleShowShape = useCallback(() => {
    onIsShapeShowed(!isShapeShowed)
  }, [isShapeShowed, onIsShapeShowed])

  const handleHighlightUnsaved = useCallback(() => {
    if (typeof onHighlightUnsaved === 'function') onHighlightUnsaved()
  }, [onHighlightUnsaved])

  return (
    <>
      <div className="toolbox-title">Markup Tools</div>
      <div className="toolbox-canvas-wrapper">
        <ul className="list-inline m-0 d-flex align-items-center">
          {hasUnsavedComment && (
            <>
              <li className="list-inline-item m-0">
                <button
                  type="button"
                  className={`btn btn-highligh-unsaved p-0 d-flex align-item-center`}
                  onClick={handleHighlightUnsaved}
                  data-tippy-content="You have an unsaved comment.<br /> Click to highlight comment!"
                >
                  <span
                    className="text-danger font-weight-bold"
                    style={{ fontSize: '18px', lineHeight: '100%' }}
                  >
                    &bull;&nbsp;
                  </span>
                  <span className="text-danger font-weight-bold">unsaved</span>
                </button>
              </li>
              <li className="list-inline-item mr-3 ml-3">
                <span className="separator"></span>
              </li>
            </>
          )}
          <li className="list-inline-item m-0">
            <button
              type="button"
              className={`btn btn-toolbox ${
                toolboxComponentAction.type === shapeClassname.MOUSE
                  ? 'active'
                  : null
              }`}
              onClick={(e) =>
                handleToolboxAction(
                  e,
                  shapeClassname.MOUSE,
                  toolboxActionHandler.MOUSE_CLICK
                )
              }
              data-tippy-content="Mouse"
            >
              <FeatherIcon className="feather" name="mouse-pointer" />
            </button>
          </li>
          <li className="list-inline-item mt-0 mb-0 ml-3 mr-3">
            <span className="separator"></span>
          </li>

          {!isReadOnly && (
            <>
              <li className="list-inline-item m-0">
                <button
                  type="button"
                  className={`btn btn-toolbox ${
                    toolboxComponentAction.type === shapeClassname.CIRCLE
                      ? 'active'
                      : null
                  }`}
                  onClick={(e) =>
                    handleToolboxAction(
                      e,
                      shapeClassname.CIRCLE,
                      toolboxActionHandler.MOUSE_CLICK
                    )
                  }
                  data-tippy-content="Dot marker"
                  disabled={isReadOnly}
                >
                  <FeatherIcon className="feather" name="message-circle" />
                </button>
              </li>
              <li className="list-inline-item m-0">
                <button
                  type="button"
                  className={`btn btn-toolbox ${
                    toolboxComponentAction.type === shapeClassname.ARROW
                      ? 'active'
                      : null
                  }`}
                  onClick={(e) =>
                    handleToolboxAction(
                      e,
                      shapeClassname.ARROW,
                      toolboxActionHandler.MOUSE_MOVE
                    )
                  }
                  data-tippy-content="Arrow marker"
                  disabled={isReadOnly}
                >
                  <FeatherIcon className="feather" name="arrow-up-right" />
                </button>
              </li>
              <li className="list-inline-item m-0">
                <button
                  type="button"
                  className={`btn btn-toolbox ${
                    toolboxComponentAction.type === shapeClassname.RECT
                      ? 'active'
                      : null
                  }`}
                  onClick={(e) =>
                    handleToolboxAction(
                      e,
                      shapeClassname.RECT,
                      toolboxActionHandler.MOUSE_MOVE
                    )
                  }
                  data-tippy-content="Highlight an area"
                  disabled={isReadOnly}
                >
                  <FeatherIcon className="feather" name="square" />
                </button>
              </li>
              <li className="list-inline-item m-0">
                <button
                  type="button"
                  className={`btn btn-toolbox ${
                    toolboxComponentAction.type === shapeClassname.LINE
                      ? 'active'
                      : null
                  }`}
                  onClick={(e) =>
                    handleToolboxAction(
                      e,
                      shapeClassname.LINE,
                      toolboxActionHandler.MOUSE_MOVE
                    )
                  }
                  data-tippy-content="Free draw"
                  disabled={isReadOnly}
                >
                  <FeatherIcon className="feather" name="edit-2" />
                </button>
              </li>
              <li className="list-inline-item m-0">
                <ColorPalette
                  onChangeColor={handleChangeColor}
                  selectedColor={toolboxComponentAction.color}
                  isReadOnly={isReadOnly}
                />
              </li>
              <li className="list-inline-item m-0">
                <span className="separator"></span>
              </li>
            </>
          )}

          <li className="list-inline-item m-0">
            <Zoom onChangeZoom={handleChangeZoom} />
          </li>
          <li className="list-inline-item">
            <span className="separator"></span>
          </li>
          <li className="list-inline-item m-0">
            <button
              type="button"
              className={`btn btn-toolbox`}
              onClick={handleFitWindowSize}
              data-tippy-content={isFitWindow ? 'Fit to page' : 'Fit to screen'}
            >
              {isFitWindow ? (
                <Icon.Minimize width={18} height={18} />
              ) : (
                <Icon.Maximize width={18} height={18} />
              )}
            </button>
          </li>
          <li className="list-inline-item m-0">
            <button
              type="button"
              className={`btn btn-toolbox`}
              onClick={handleToggleShowShape}
              data-tippy-content={`${isShapeShowed ? 'Hide' : 'Show'} marker`}
            >
              {isShapeShowed ? (
                <Icon.Eye width={18} height={18} />
              ) : (
                <Icon.EyeOff width={18} height={18} />
              )}
            </button>
          </li>
        </ul>
      </div>
    </>
  )
}

export default React.memo(Toolbox)
