import React, { useState, useEffect, useContext } from 'react'
import update from 'immutability-helper'

import Canvas from './Canvas'
import {
  shapeClassname,
  toolboxActionHandler,
  positionCommentPlace,
  typeDisplayAnnotation,
} from '../../shared/constants'
import {
  parseAttrShape,
  convertToImgRectPosition,
  revertToCanvasDimentionPosition,
} from '../../shared/utils'
import { PreviewContext } from '../../context/previewContext'
import { TooltipContext } from '../../context/tooltipContext'
import { ContextMenuContext } from '../../context/contextMenuContext'
import { ShapeContext } from '../../context/shapeContext'
import { PdfContext } from '../../context/pdfContext'

const CanvasContainer = () => {
  const [startPos, setStartPos] = useState(null)
  const [points, setPoints] = useState([])
  const [isPaint, setIsPaint] = useState(false)
  const [isObjectHasCreated, setIsObjectHasCreated] = useState(false)
  const [index, setIndex] = useState(0)
  const [startX, setStartX] = useState(0)
  const [scrollLeft, setScrollLeft] = useState(0)
  const [startY, setStartY] = useState(0)
  const [scrollTop, setScrollTop] = useState(0)
  const [drawObject, setDrawObject] = useState(null)

  const shapeContext = useContext(ShapeContext)
  const previewContext = useContext(PreviewContext)
  const pdfContext = useContext(PdfContext)
  const tooltipContext = useContext(TooltipContext)
  const contextMenuContext = useContext(ContextMenuContext)
  const isCloseTooltipEvent = tooltipContext.isCloseTooltipEvent
  const selectedAttrId = shapeContext.selectedAttrId
  const canvasObjects = shapeContext.canvasObjects
  const updateCanvasObjects = shapeContext.updateCanvasObjects
  const onHasUnsavedComment = tooltipContext.onHasUnsavedComment

  // remove unsaved canvasObject when close tooltip
  useEffect(() => {
    if (isCloseTooltipEvent && canvasObjects.length > 0) {
      const indexTempObj = canvasObjects.findIndex(
        (x) => x.isUnsaved && x.attrs.id === selectedAttrId
      )

      if (indexTempObj > -1) {
        updateCanvasObjects((x) =>
          update(x, {
            $splice: [[indexTempObj, 1]],
          })
        )
      }

      // check unsaved object
      const objUnsaved = canvasObjects.filter((x) => x.isUnsaved)
      if (objUnsaved.length > 0) {
        onHasUnsavedComment(true)
      } else {
        onHasUnsavedComment(false)
      }
    }
  }, [
    updateCanvasObjects,
    isCloseTooltipEvent,
    selectedAttrId,
    canvasObjects,
    onHasUnsavedComment,
  ])

  // // every change tool action, remove unsaved object
  // useEffect(() => {
  //   updateCanvasObjects(x => update(x, {
  //     $set: x.filter(x => !x.isUnsaved)
  //   }));
  // }, [updateCanvasObjects, toolboxComponentAction]);

  const createObject = (movePos) => {
    const updatedPosByGroup = convertToImgRectPosition(
      startPos,
      previewContext.imageBoundRect
    )
    const updatedMovedPosByGroup = convertToImgRectPosition(
      movePos,
      previewContext.imageBoundRect
    )
    let pencilPoints = []

    const posPreviewBoundary = {
      x: previewContext.imageBoundRect.x + previewContext.imageBoundRect.width,
      y: previewContext.imageBoundRect.y + previewContext.imageBoundRect.height,
    }

    if (previewContext.toolboxComponentAction.type === shapeClassname.LINE) {
      let pencilPoint = {
        x: updatedMovedPosByGroup.x,
        y: updatedMovedPosByGroup.y,
      }

      // check area allowed drawing
      if (movePos.x > posPreviewBoundary.x) {
        pencilPoint.x =
          (posPreviewBoundary.x - previewContext.imageBoundRect.x) /
          previewContext.imageBoundRect.scale
      } else if (movePos.x < previewContext.imageBoundRect.x) {
        pencilPoint.x =
          (previewContext.imageBoundRect.x - previewContext.imageBoundRect.x) /
          previewContext.imageBoundRect.scale
      }

      // check area allowed drawing
      if (movePos.y > posPreviewBoundary.y) {
        pencilPoint.y =
          (posPreviewBoundary.y - previewContext.imageBoundRect.y) /
          previewContext.imageBoundRect.scale
      } else if (movePos.y < previewContext.imageBoundRect.y) {
        pencilPoint.y =
          (previewContext.imageBoundRect.y - previewContext.imageBoundRect.y) /
          previewContext.imageBoundRect.scale
      }

      pencilPoints = points.concat([pencilPoint.x, pencilPoint.y])
      setPoints(pencilPoints)
    }

    const obj = parseAttrShape(
      previewContext.toolboxComponentAction,
      updatedPosByGroup,
      updatedMovedPosByGroup,
      pencilPoints,
      previewContext.toolboxComponentAction.color,
      previewContext.imageBoundRect
    )

    if (obj !== null) {
      setIsObjectHasCreated(true)
      updateCanvasObjects((x) =>
        update(x, {
          $splice: [[index, 1, obj]],
        })
      )

      return obj
    }
  }

  const createDotObject = (e) => {
    const pos = e.currentTarget.getPointerPosition()
    const updatedPosByGroup = convertToImgRectPosition(
      pos,
      previewContext.imageBoundRect
    )
    const obj = parseAttrShape(
      previewContext.toolboxComponentAction,
      updatedPosByGroup,
      null,
      [],
      previewContext.toolboxComponentAction.color,
      previewContext.imageBoundRect
    )

    // removeUnsavedObject();

    setIsObjectHasCreated(true)
    updateCanvasObjects((x) =>
      update(x, {
        $push: [obj],
      })
    )

    return obj
  }

  // // remove unsaved object
  // const removeUnsavedObject = () => {
  //   const indexTempObj = canvasObjects.findIndex(x => x.isUnsaved);
  //   if (indexTempObj > -1) {
  //     updateCanvasObjects(x => update(x, {
  //       $splice: [[indexTempObj, 1]]
  //     }));
  //   }
  // };

  // // remove unsaved object on mouseup
  // const removeUnsavedObjectbyNotAttrId = (attrId) => {
  //   const indexTempObj = canvasObjects.findIndex(x => x.isUnsaved && x.attrs.id !== attrId);
  //   if (indexTempObj > -1) {
  //     updateCanvasObjects(x => update(x, {
  //       $splice: [[indexTempObj, 1]]
  //     }));
  //   }
  // };

  // show comment when click object
  const showCommentOnClickShape = (targetShape) => {
    const className = targetShape.getClassName()
    let attrs = targetShape.attrs
    let endPos = targetShape.attrs

    if (className === shapeClassname.LINE) {
      attrs = {
        x: targetShape.points()[0],
        y: targetShape.points()[1],
      }
      endPos = {
        x: attrs.x + targetShape.attrs.points[targetShape.attrs.points - 1],
        y: attrs.y + targetShape.attrs.points[targetShape.attrs.points - 2],
      }
    }

    if (className === shapeClassname.RECT) {
      endPos = {
        x: attrs.x + targetShape.attrs.width,
        y: attrs.y + targetShape.attrs.height,
      }
    } else if (className === shapeClassname.ARROW) {
      endPos = {
        x: attrs.x + targetShape.attrs.points[2],
        y: attrs.y + targetShape.attrs.points[3],
      }
    }

    let currentObject = targetShape
    const idx = canvasObjects.findIndex(
      (x) => x.attrs.id === targetShape.attrs.id
    )
    if (idx > -1) {
      currentObject = canvasObjects[idx]
    }
    // show comment by selected obj
    tooltipContext.onSelectedCommentTooltip(
      targetShape.attrs.id,
      revertToCanvasDimentionPosition(attrs, previewContext.imageBoundRect),
      currentObject
    )
    updatePosCommentPlace(attrs, endPos)
  }

  const updatePosCommentPlace = (startPos, endPos) => {
    if (endPos.y >= startPos.y) {
      tooltipContext.onTooltipPlaceChanged(positionCommentPlace.TOP)
    } else {
      tooltipContext.onTooltipPlaceChanged(positionCommentPlace.BOTTOM)
    }
  }

  const handleStageMouseDown = (e) => {
    // hide context menu (right click)
    contextMenuContext.onIsShowcontextMenu(false)
    tooltipContext.onIsCloseTooltipEvent(false)

    // only left mouse button
    if (e.evt.button !== 0) return

    if (
      previewContext.typeDisplay === typeDisplayAnnotation.PDF &&
      previewContext.toolboxComponentAction.handler ===
        toolboxActionHandler.MOUSE_CLICK
    ) {
      setStartX(e.evt.pageX - pdfContext.pdfViewerWrappRef.current.offsetLeft)
      setScrollLeft(pdfContext.pdfViewerWrappRef.current.scrollLeft)

      setStartY(e.evt.pageY - pdfContext.pdfViewerWrappRef.current.offsetTop)
      setScrollTop(pdfContext.pdfViewerWrappRef.current.scrollTop)
    }

    const mousePos = e.currentTarget.getPointerPosition()

    const posPreview = previewContext.imageBoundRect
    const posPreviewBoundary = {
      x: posPreview.x + previewContext.imageBoundRect.width,
      y: posPreview.y + previewContext.imageBoundRect.height,
    }

    // return if not image range clicked
    if (mousePos.x < posPreview.x || mousePos.x > posPreviewBoundary.x) {
      return
    }

    // return if not image range clicked
    if (mousePos.y < posPreview.y || mousePos.y > posPreviewBoundary.y) {
      return
    }

    if (previewContext.toolboxComponentAction !== null) {
      if (
        e.currentTarget.targetShape &&
        previewContext.toolboxComponentAction.handler ===
          toolboxActionHandler.MOUSE_CLICK &&
        previewContext.toolboxComponentAction.type === shapeClassname.MOUSE
      ) {
        // show comment when click object
        const targetShape = e.currentTarget.targetShape
        showCommentOnClickShape(targetShape)
      } else if (
        e.currentTarget.targetShape &&
        previewContext.toolboxComponentAction.handler ===
          toolboxActionHandler.MOUSE_CLICK &&
        previewContext.toolboxComponentAction.type === shapeClassname.CIRCLE
      ) {
        // show comment when click object
        const targetShape = e.currentTarget.targetShape
        showCommentOnClickShape(targetShape)
      } else {
        // draging shape event
        const pos = e.currentTarget.getPointerPosition()
        setStartPos(pos)
        setIndex(canvasObjects.length)

        // pencil only
        if (
          previewContext.toolboxComponentAction.type === shapeClassname.LINE
        ) {
          const updatedPosByGroup = convertToImgRectPosition(
            pos,
            previewContext.imageBoundRect
          )
          setPoints([updatedPosByGroup.x, updatedPosByGroup.y])
        }

        previewContext.onImageBoundRectChanged((v) =>
          update(v, {
            relativeX: { $set: e.evt.pageX - v.x },
            relativeY: { $set: e.evt.pageY - v.y },
          })
        )

        setIsPaint(true)
      }
    }

    e.evt.stopPropagation()
    e.evt.preventDefault()
  }

  const handleStageMouseMove = (e) => {
    // only left mouse button
    if (e.evt.button !== 0) return

    if (previewContext.toolboxComponentAction !== null && isPaint) {
      const movePos = e.currentTarget.getPointerPosition()

      if (
        previewContext.toolboxComponentAction.handler ===
        toolboxActionHandler.MOUSE_CLICK
      ) {
        // hide comment when dragging
        tooltipContext.onShowTooltip(false)

        if (previewContext.typeDisplay === typeDisplayAnnotation.IMAGE) {
          // detect has moving
          if (startPos.x !== movePos.x || startPos.y !== movePos.y) {
            // drag image handler
            previewContext.onImageBoundRectChanged((v) =>
              update(v, {
                x: { $set: e.evt.pageX - v.relativeX },
                y: { $set: e.evt.pageY - v.relativeY },
              })
            )
          }
        } else {
          // drag pdf and move container scroll
          const x =
            e.evt.pageX - pdfContext.pdfViewerWrappRef.current.offsetLeft
          const walkX = (x - startX) * 1 //scroll-fast
          const y = e.evt.pageY - pdfContext.pdfViewerWrappRef.current.offsetTop
          const walkY = (y - startY) * 1 //scroll-fast

          pdfContext.pdfViewerWrappRef.current.scrollLeft = scrollLeft - walkX
          pdfContext.pdfViewerWrappRef.current.scrollTop = scrollTop - walkY
        }

        if (!previewContext.isDragging) {
          previewContext.onIsDragging(true)
          previewContext.onTransitionProperty('none')
        }
      } else {
        setDrawObject(createObject(movePos))
      }
    }

    e.evt.stopPropagation()
    e.evt.preventDefault()
  }

  const handleStageMouseUp = (e) => {
    // only left mouse button
    if (e.evt.button !== 0) return

    const targetShape = e.currentTarget.targetShape

    // only for dot object
    if (
      isPaint &&
      previewContext.toolboxComponentAction !== null &&
      previewContext.toolboxComponentAction.handler ===
        toolboxActionHandler.MOUSE_CLICK &&
      previewContext.toolboxComponentAction.type === shapeClassname.CIRCLE &&
      !previewContext.isDragging &&
      !targetShape
    ) {
      const objDotCreated = createDotObject(e)
      updatePosCommentPlace(startPos, e.currentTarget.getPointerPosition())
      tooltipContext.onSelectedCommentTooltip(
        objDotCreated.attrs.id,
        startPos,
        objDotCreated
      )

      // we always know, every new object created, is unsaved
      onHasUnsavedComment(true)
    }

    // show comment after shape created
    if (isPaint && !previewContext.isDragging && isObjectHasCreated) {
      updatePosCommentPlace(startPos, e.currentTarget.getPointerPosition())
      tooltipContext.onSelectedCommentTooltip(
        drawObject.attrs.id,
        startPos,
        drawObject
      )

      // we always know, every new object created, is unsaved
      onHasUnsavedComment(true)
    } else if (
      isPaint &&
      !previewContext.isDragging &&
      previewContext.toolboxComponentAction !== null &&
      previewContext.toolboxComponentAction.handler ===
        toolboxActionHandler.MOUSE_MOVE &&
      previewContext.toolboxComponentAction.type !== shapeClassname.CIRCLE &&
      targetShape
    ) {
      // show comment when click object. all object, except dot
      showCommentOnClickShape(targetShape)
    }

    // if (isPaint
    //   && !previewContext.isDragging
    //   && isObjectHasCreated
    //   && previewContext.toolboxComponentAction !== null
    //   && previewContext.toolboxComponentAction.handler !== toolboxActionHandler.MOUSE_CLICK
    //   && previewContext.toolboxComponentAction.type !== shapeClassname.CIRCLE) {
    //   removeUnsavedObjectbyNotAttrId(drawObject.attrs.id);
    // }

    setIsPaint(false)
    previewContext.onIsDragging(false)
    previewContext.onTransitionProperty('all')
    setIsObjectHasCreated(false)

    e.evt.stopPropagation()
    e.evt.preventDefault()
  }

  const handleContextMenu = (e) => {
    // show menu
    // set position menu
    // cancel drawing
    contextMenuContext.onPositionContextMenu({
      x: e.evt.pageX,
      y: e.evt.pageY,
    })
    contextMenuContext.onIsShowcontextMenu(true)

    e.evt.stopPropagation()
    e.evt.preventDefault()
  }

  return (
    <Canvas
      onMouseDown={handleStageMouseDown}
      onTouchStart={handleStageMouseDown}
      onMouseUp={handleStageMouseUp}
      onTouchEnd={handleStageMouseUp}
      onMouseMove={handleStageMouseMove}
      onTouchMove={handleStageMouseMove}
      onContextMenu={handleContextMenu}
      imageBoundRect={previewContext.imageBoundRect}
      layerPdfScroll={pdfContext.layerPdfScroll}
      typeDisplay={previewContext.typeDisplay}
      objects={canvasObjects}
    />
  )
}

export default CanvasContainer
