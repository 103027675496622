export const buttonPromptType = {
  CONTINUE: 'CONTINUE',
  CONTINUECANCEL: 'CONTINUECANCEL',
  YESNO: 'YESNO',
  OK: 'OK',
}

export const promptType = {
  PRIMARY: 'PRIMARY',
  WARNING: 'WARNING',
}
