import React from 'react'
import * as Icon from 'react-feather'

function FreeTrialExpiredBanner({ onClose, bannerText }) {
  const hideCloseButton = () => {
    onClose()
  }

  return (
    <div className="announcement-content">
      <div
        className="flash-notification"
        style={{
          backgroundColor: '#EB0245',
          color: '#ffffff',
        }}
        dangerouslySetInnerHTML={{ __html: bannerText }}
      ></div>
      <button
        className="close-btn"
        onClick={() => hideCloseButton()}
        style={{ color: '#ffffff' }}
      >
        <Icon.XCircle />
      </button>
    </div>
  )
}

export default FreeTrialExpiredBanner
