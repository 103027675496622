import React, {
  useRef,
  useContext,
  useEffect,
  useCallback,
  useState,
} from 'react'
import update from 'immutability-helper'
import * as Sentry from '@sentry/browser'
import { getUnixTime } from 'date-fns'

import './ImgRender.scss'
import { PreviewContext } from '../../context/previewContext'
import { ImgContext } from '../../context/imgContextValue'
import { ShapeContext } from '../../context/shapeContext'
import { getWindowScaleList } from '../../shared/zoomHelper'
import { marginHeader, heightHeader } from '../../shared/utils'

const ImgRender = () => {
  const imgRef = useRef()
  const {
    imageBoundRect,
    onImageBoundRectChanged,
    onIsLoadingFile,
    transitionProperty,
    url,
    isLoadingFile,
    isLoadingPage,
    updateZoomDetail,
  } = useContext(PreviewContext)
  const { onImgRef, onChangeScale } = useContext(ImgContext)
  const { isLoadAnnotation } = useContext(ShapeContext)
  const [urlCached, setUrlCached] = useState(url)

  useEffect(() => {
    setUrlCached(`${url}?v=${getUnixTime(new Date())}`)
  }, [url])

  useEffect(() => {
    onImgRef(imgRef)
  }, [imgRef, onImgRef])

  useEffect(() => {
    if (!isLoadingFile && !isLoadAnnotation) {
      const evt = new Event('annotationFileReady', {
        bubbles: true,
        cancelable: false,
      })
      document.dispatchEvent(evt)
    }
  }, [isLoadingFile, isLoadAnnotation])

  const handleImageLoaded = useCallback(
    (e) => {
      const naturalWidth = e.currentTarget.naturalWidth
      const naturalHeight = e.currentTarget.naturalHeight

      const scalePage = getWindowScaleList(naturalWidth, naturalHeight, false)

      const left =
        window.innerWidth / 2 - (naturalWidth * scalePage.initialScale) / 2
      const top = heightHeader + marginHeader // header(60) + padding(20)

      onImageBoundRectChanged((v) =>
        update(v, {
          naturalWidth: { $set: naturalWidth },
          naturalHeight: { $set: naturalHeight },
          width: { $set: naturalWidth * scalePage.initialScale },
          height: { $set: naturalHeight * scalePage.initialScale },
          scale: { $set: scalePage.initialScale },
          x: { $set: left },
          y: { $set: top },
        })
      )

      onChangeScale(scalePage.initialScale)

      updateZoomDetail(scalePage)

      onIsLoadingFile(false)
    },
    [onChangeScale, onImageBoundRectChanged, onIsLoadingFile, updateZoomDetail]
  )

  const handleImageErrored = useCallback(
    (err) => {
      if (urlCached) {
        alert('File cannot loaded, Please reload your page')

        if (process.env.NODE_ENV === 'production') {
          Sentry.captureException(err)
        }
      }
    },
    [urlCached]
  )

  return (
    <div className="preview-wrapper img-viewer">
      <img
        ref={imgRef}
        src={urlCached}
        alt="preview-annotation"
        onLoad={handleImageLoaded}
        onError={handleImageErrored}
        style={{
          visibility: isLoadingPage || isLoadingFile ? 'hidden' : 'visible',
          transitionProperty: transitionProperty,
          width: imageBoundRect.width ? imageBoundRect.width + 'px' : 'auto',
          height: imageBoundRect.height ? imageBoundRect.height + 'px' : 'auto',
          left: imageBoundRect.x + 'px',
          top: imageBoundRect.y + 'px',
        }}
      />
    </div>
  )
}

export default React.memo(ImgRender)
