import React, { useState, useEffect, useContext } from 'react'
import update from 'immutability-helper'
import { format } from 'date-fns'
import validator from 'validator'
import { connect } from 'react-redux'
import { flatten } from 'lodash'

import ArchivedOrderFilter from './ArchivedOrderFilter'
import * as actions from '../../store/actions/index'
import { ArchivedOrderFilterContext } from '../../context/archivedOrderFilterContext'
import FormHandler from '../../factory/FormHandler'
import { OrderFilterCustomerListAPI } from '../../api/OrderFilter/OrderFilterCustomerList'
import toastCenter from '../../shared/toastCenter'
import { ArchivedOrderFilterSavePresetAPI } from '../../api/ArchivedOrderFilter/ArchivedOrderFilterSavePreset'
import { OrderFilterServiceListAPI } from '../../api/OrderFilter/OrderFilterServiceList'
import { OrderFilterOperatorListAPI } from '../../api/OrderFilter/OrderFilterOperatorList'
import { ArchivedOrderFilterUpdateAPI } from '../../api/ArchivedOrderFilter/ArchivedOrderFilterUpdate'
import { orderFilterService } from '../../services/orderFilterService/orderFilterService'
import { AbilityContext } from '../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../shared/ability'
import { OrderFilterManagerListAPI } from '../../api/OrderFilter/OrderFilterManagerList'
import { OrderFilterClientListAPI } from '../../api/OrderFilter/OrderFilterClientList'
import { OrderFilterCreatorListAPI } from '../../api/OrderFilter/OrderFilterCreatorList'
import { OrderFilterServiceCategoryListAPI } from '../../api/OrderFilter/OrderFilterServiceCategoryList'

export const defaultFilterOrder = {
  customSearch: '',
  operator: [],
  serviceCategory: [],
  serviceType: [],
  createdDate: { startDate: null, endDate: null },
  updatedDate: { startDate: null, endDate: null },
  customer: [],
  client: [],
  creator: [],
  manager: [],
}

let searchTimer = null

const ArchivedOrderFilterContainer = ({ height, onFilterOrder, user }) => {
  const defaultValue = {
    value: [],
    options: [],
  }
  const defaultValueDate = {
    startDate: null,
    endDate: null,
  }
  const [idPreset, setIdPreset] = useState(null)
  const [isExpand, setIsExpand] = useState(false)
  const [customer, setCustomer] = useState({ ...defaultValue })
  const [client, setClient] = useState({ ...defaultValue })
  const [creator, setCreator] = useState({ ...defaultValue })
  const [manager, setManager] = useState({ ...defaultValue })
  const [orderAssigned, setOrderAssigned] = useState({ ...defaultValue })
  const [serviceCategory, setServiceCategory] = useState({ ...defaultValue })
  const [serviceType, setServiceType] = useState({ ...defaultValue })
  const [orderCreated, setOrderCreated] = useState({ ...defaultValueDate })
  const [orderUpdatedDate, setOrderUpdatedDate] = useState({
    ...defaultValueDate,
  })
  const [customSearch, setCustomSearch] = useState('')
  const [presetName, setPresetName] = useState({
    value: '',
    isEmpty: false,
  })
  const [currentPreset, setCurrentPreset] = useState(defaultFilterOrder)

  const abilityContext = useContext(AbilityContext)

  useEffect(() => {
    if (typeof onFilterOrder === 'function') {
      onFilterOrder(currentPreset)
    }
  }, [currentPreset, onFilterOrder])

  const handleEdit = (preset) => {
    const detail = {
      id: preset.id,
      name: preset.name,
      customer: preset.customer ? preset.customer.split(',') : [],
      client: preset.client ? preset.client.split(',') : [],
      creator: preset.creator
        ? preset.creator.indexOf(',') > -1
          ? preset.creator.split(',')
          : [preset.creator]
        : [],
      manager: preset.manager ? preset.manager.split(',') : [],
      serviceCategory: preset.serviceCategory
        ? preset.serviceCategory.split(',')
        : [],
      serviceType: preset.serviceType ? preset.serviceType.split(',') : [],
      operator: preset.operator ? preset.operator.split(',') : [],
      customSearch: preset.customSearch ? preset.customSearch : '',
      createdDate: {
        startDate: preset.createdDate.startDate,
        endDate: preset.createdDate.endDate,
      },
      updatedDate: {
        startDate: preset.updatedDate.startDate,
        endDate: preset.updatedDate.endDate,
      },
    }

    if (
      abilityContext.can &&
      abilityContext.can(
        abilityAction.READ,
        abilityComponent.ORDER_FILTER_CUSTOMER
      )
    ) {
      doRequestCustomerList(null, (customerList) => {
        setCustomer(
          update(customer, {
            options: { $set: customerList },
            value: {
              $set: customerList.filter((x) =>
                detail.customer.includes(x.value)
              ),
            },
          })
        )
      })
    }

    if (
      abilityContext.can &&
      abilityContext.can(
        abilityAction.READ,
        abilityComponent.ORDER_FILTER_CLIENT
      )
    ) {
      doRequestClientList((clientList) => {
        setClient(
          update(client, {
            options: { $set: clientList },
            value: {
              $set: clientList.filter((x) => detail.client.includes(x.value)),
            },
          })
        )
      })
    }

    doRequestCreatorList([], [], (creatorOptions) => {
      setCreator((x) =>
        update(x, {
          options: { $set: creatorOptions },
          value: {
            $set: creatorOptions.filter((x) =>
              detail.creator.includes(x.value)
            ),
          },
        })
      )
    })

    doRequestManagerList((managerList) => {
      setManager(
        update(manager, {
          options: { $set: managerList },
          value: {
            $set: managerList.filter((x) => detail.manager.includes(x.value)),
          },
        })
      )
    })

    doRequestOperatorList((operatorList) => {
      setOrderAssigned(
        update(orderAssigned, {
          options: { $set: operatorList },
          value: {
            $set: operatorList.filter((x) => detail.operator.includes(x.value)),
          },
        })
      )
    })

    doRequestServiceCategoryList((serviceCategoryList) => {
      setServiceCategory(
        update(serviceCategory, {
          options: { $set: serviceCategoryList },
          value: {
            $set: serviceCategoryList.filter((x) =>
              detail.serviceCategory.includes(x.value)
            ),
          },
        })
      )
    })

    const serviceSelectedVal = []
    serviceType.options.forEach((x) => {
      serviceSelectedVal.push(
        x.options.filter((x) => detail.serviceType.includes(x.value))
      )
    })
    const flattenServiceArr = flatten(serviceSelectedVal)
    setServiceType(
      update(serviceType, {
        value: { $set: flattenServiceArr },
      })
    )
    setOrderCreated(
      update(orderCreated, {
        startDate: {
          $set: detail.createdDate.startDate
            ? new Date(detail.createdDate.startDate)
            : null,
        },
        endDate: {
          $set: detail.createdDate.endDate
            ? new Date(detail.createdDate.endDate)
            : null,
        },
      })
    )

    setOrderUpdatedDate(
      update(orderUpdatedDate, {
        startDate: {
          $set: detail.updatedDate.startDate
            ? new Date(detail.updatedDate.startDate)
            : null,
        },
        endDate: {
          $set: detail.updatedDate.endDate
            ? new Date(detail.updatedDate.endDate)
            : null,
        },
      })
    )

    setCustomSearch(detail.customSearch)

    setPresetName({ value: detail.name, isEmpty: false })

    setIdPreset(detail.id)

    setIsExpand(true)
  }

  const handleExpandFilter = () => {
    handleReset()
    setIsExpand(!isExpand)
  }

  const handleReset = () => {
    setCustomer(
      update(customer, {
        value: {
          $set: null,
        },
      })
    )

    setClient(
      update(client, {
        value: {
          $set: null,
        },
      })
    )

    setCreator(
      update(creator, {
        value: {
          $set: null,
        },
      })
    )

    setManager(
      update(manager, {
        value: {
          $set: null,
        },
      })
    )

    setOrderAssigned(
      update(orderAssigned, {
        value: {
          $set: null,
        },
      })
    )

    setServiceCategory(
      update(serviceCategory, {
        value: {
          $set: null,
        },
      })
    )

    setServiceType(
      update(serviceType, {
        value: {
          $set: null,
        },
      })
    )

    setOrderCreated(
      update(orderCreated, {
        startDate: { $set: null },
        endDate: { $set: null },
      })
    )

    setOrderUpdatedDate(
      update(orderUpdatedDate, {
        startDate: { $set: null },
        endDate: { $set: null },
      })
    )

    setCustomSearch('')

    setPresetName({ value: '', isEmpty: false })

    setIdPreset(null)

    setCurrentPreset(defaultFilterOrder)
  }

  const handleSave = (isUpdate = false, id = null) => {
    let data = new FormHandler()

    if (!validator.isEmpty(presetName.value)) {
      data.append('name', presetName.value)
    } else {
      setPresetName(
        update(presetName, {
          isEmpty: { $set: true },
        })
      )
    }

    if (customer.value !== null && customer.value.length > 0) {
      data.append('customerId', customer.value.map((x) => x.value).join(','))
    }

    if (client.value !== null && client.value.length > 0) {
      data.append('clientId', client.value.map((x) => x.value).join(','))
    }

    if (creator.value !== null && creator.value.length > 0) {
      data.append('creatorId', creator.value.map((x) => x.value).join(','))
    }

    if (manager.value !== null && manager.value.length > 0) {
      data.append('managerId', manager.value.map((x) => x.value).join(','))
    }

    if (
      abilityContext.can &&
      abilityContext.can(
        abilityAction.READ,
        abilityComponent.ORDER_FILTER_OPERATOR
      )
    ) {
      if (orderAssigned.value !== null && orderAssigned.value.length > 0) {
        data.append(
          'operatorId',
          orderAssigned.value.map((x) => x.value).join(',')
        )
      }
    }

    if (serviceCategory.value !== null && serviceCategory.value.length > 0) {
      data.append(
        'serviceCategoryId',
        serviceCategory.value.map((x) => x.value).join(',')
      )
    }

    if (serviceType.value !== null && serviceType.value.length > 0) {
      data.append('serviceId', serviceType.value.map((x) => x.value).join(','))
    }

    if (orderCreated.startDate) {
      data.append(
        'createdAtStart',
        format(orderCreated.startDate, 'yyyy/MM/dd')
      )
    }

    if (orderCreated.endDate) {
      data.append('createdAtEnd', format(orderCreated.endDate, 'yyyy/MM/dd'))
    }

    if (orderUpdatedDate.startDate) {
      data.append(
        'updatedDateStart',
        format(orderUpdatedDate.startDate, 'yyyy/MM/dd')
      )
    }

    if (orderUpdatedDate.endDate) {
      data.append(
        'updatedDateEnd',
        format(orderUpdatedDate.endDate, 'yyyy/MM/dd')
      )
    }

    if (!validator.isEmpty(customSearch)) {
      data.append('search', customSearch)
    }

    const onNext = (response) => {
      if (response.success) {
        toastCenter.message(
          'Success',
          'Preset filter has been successfully saved'
        )
        orderFilterService.emitReload(true)
        handleReset()
        setIsExpand(false)
      } else {
        toastCenter.messageServerErrorCustom(response)
      }
    }

    const onComplete = () => {
      onFilterOrder(null)
    }

    const onError = () => {
      toastCenter.messageServerError()
    }

    let saveApi = new ArchivedOrderFilterSavePresetAPI()
    if (isUpdate && id) {
      saveApi = new ArchivedOrderFilterUpdateAPI()
      saveApi.subscribe(id, data.all(), onNext, onComplete, onError)
    } else {
      saveApi.subscribe(data.all(), onNext, onComplete, onError)
    }
  }

  const updateServiceTypeListByCatId = (catIds, cb = null) => {
    const orderFilterServiceListApi = new OrderFilterServiceListAPI()

    const onNextService = (response) => {
      if (response.success) {
        if (typeof cb === 'function') {
          cb(response.data)
        }
      }
    }

    const onCompleteService = () => {}

    const onErrorService = () => {
      toastCenter.messageServerError()
    }

    let filters = null

    if (catIds !== null && catIds.length > 0) {
      const params = new URLSearchParams()
      params.append('serviceCategoryId', catIds.join(','))
      filters = params
    }

    orderFilterServiceListApi.subscribe(
      filters,
      onNextService,
      onCompleteService,
      onErrorService
    )
  }

  const doRequestCreatorList = (clientIds, customerIds, cb = null) => {
    const query = {}

    if (clientIds && clientIds.length) {
      const allClients = clientIds.map((client) => {
        return client.value
      })
      query.clientId = allClients.join(',')
    }
    if (customerIds && customerIds.length) {
      const allCustomers = customerIds.map((customer) => {
        return customer.value
      })
      query.customerId = allCustomers.join(',')
    }

    const orderFilterCreatorListApi = new OrderFilterCreatorListAPI()
    const onNextCreator = (response) => {
      if (response.success) {
        if (typeof cb === 'function') {
          cb(response.data)
        }
      }
    }
    const onCompleteCreator = () => {}
    const onErrorCreator = () => {
      toastCenter.messageServerError()
    }
    orderFilterCreatorListApi.subscribe(
      query,
      onNextCreator,
      onCompleteCreator,
      onErrorCreator
    )
  }

  const doRequestCustomerList = (clientIds, cb = null) => {
    const clients = {}

    if (clientIds && clientIds.length) {
      const allClients = clientIds.map((client) => {
        return client.value
      })
      clients.clientId = allClients.join(',')
    }

    const orderFilterCustomerListApi = new OrderFilterCustomerListAPI()
    const onNext = (response) => {
      if (response.success) {
        if (typeof cb === 'function') {
          cb(response.data)
        }
      }
    }
    const onComplete = () => {}
    const onError = () => {
      toastCenter.messageServerError()
    }
    orderFilterCustomerListApi.subscribe(clients, onNext, onComplete, onError)
  }

  const doRequestClientList = (cb = null) => {
    const orderFilterClientListApi = new OrderFilterClientListAPI()
    const onNextClient = (response) => {
      if (response.success) {
        if (typeof cb === 'function') {
          cb(response.data)
        }
      }
    }
    const onCompleteClient = () => {}
    const onErrorClient = () => {
      toastCenter.messageServerError()
    }
    orderFilterClientListApi.subscribe(
      onNextClient,
      onCompleteClient,
      onErrorClient
    )
  }

  const doRequestOperatorList = (cb = null) => {
    const orderFilterOperatorListApi = new OrderFilterOperatorListAPI()

    const onNextOperator = (response) => {
      if (response.success) {
        if (typeof cb === 'function') {
          cb(response.data)
        }
      }
    }

    const onCompleteOperator = () => {}

    const onErrorOperator = () => {
      toastCenter.messageServerError()
    }

    orderFilterOperatorListApi.subscribe(
      onNextOperator,
      onCompleteOperator,
      onErrorOperator
    )
  }

  const doRequestManagerList = (cb = null) => {
    const orderFilterManagerListApi = new OrderFilterManagerListAPI()
    const onNextManager = (response) => {
      if (response.success) {
        if (typeof cb === 'function') {
          cb(response.data)
        }
      }
    }
    const onCompleteManager = () => {}
    const onErrorManager = () => {
      toastCenter.messageServerError()
    }
    orderFilterManagerListApi.subscribe(
      onNextManager,
      onCompleteManager,
      onErrorManager
    )
  }

  const doRequestServiceCategoryList = (cb = null) => {
    const orderFilterServiceCategoryListApi = new OrderFilterServiceCategoryListAPI()

    const onNextServiceCat = (response) => {
      if (response.success) {
        if (typeof cb === 'function') {
          cb(response.data)
        }
      }
    }

    const onCompleteServiceCat = () => {}

    const onErrorServiceCat = () => {
      toastCenter.messageServerError()
    }

    orderFilterServiceCategoryListApi.subscribe(
      onNextServiceCat,
      onCompleteServiceCat,
      onErrorServiceCat
    )
  }

  const doRequestServiceList = (cb = null) => {
    let catIds = null

    if (serviceCategory && serviceCategory.value !== null) {
      catIds = serviceCategory.value.map((x) => x.value)
    }
    updateServiceTypeListByCatId(catIds, cb)
  }

  const handleSearchThrottled = (e) => {
    e.persist()

    setCustomSearch(e.target.value)

    if (searchTimer) {
      clearTimeout(searchTimer)
    }

    searchTimer = setTimeout(function () {
      setCurrentPreset((x) =>
        update(x, {
          customSearch: { $set: e.target.value },
        })
      )
    }, 500)
  }

  const archivedOrderFilterContextValue = {
    client: {
      value: client.value,
      options: client.options,
      onFocus: doRequestClientList.bind(this, (clientList) => {
        setClient((x) =>
          update(x, {
            options: { $set: clientList },
          })
        )
      }),
      onChange: (value) => {
        setClient(
          update(client, {
            value: { $set: value },
          })
        )

        setCurrentPreset((x) =>
          update(x, {
            client: { $set: value ? value.map((x) => x.value) : null },
          })
        )
      },
    },
    creator: {
      value: creator.value,
      options: creator.options,
      onFocus: doRequestCreatorList.bind(
        this,
        client.value,
        customer.value,
        (creatorList) => {
          setCreator((x) =>
            update(x, {
              options: { $set: creatorList },
            })
          )
        }
      ),
      onChange: (value) => {
        setCreator(
          update(creator, {
            value: { $set: value },
          })
        )

        setCurrentPreset((x) =>
          update(x, {
            creator: { $set: value ? value.map((x) => x.value) : null },
          })
        )
      },
    },
    customer: {
      value: customer.value,
      options: customer.options,
      onFocus: doRequestCustomerList.bind(
        this,
        client.value,
        (customerList) => {
          setCustomer((x) =>
            update(x, {
              options: { $set: customerList },
            })
          )
        }
      ),
      onChange: (value) => {
        setCustomer(
          update(customer, {
            value: { $set: value },
          })
        )

        setCurrentPreset((x) =>
          update(x, {
            customer: { $set: value ? value.map((x) => x.value) : null },
          })
        )
      },
    },
    manager: {
      value: manager.value,
      options: manager.options,
      onFocus: doRequestManagerList.bind(this, (managerList) => {
        setManager((x) =>
          update(x, {
            options: { $set: managerList },
          })
        )
      }),
      onChange: (value) => {
        setManager(
          update(manager, {
            value: { $set: value },
          })
        )

        setCurrentPreset((x) =>
          update(x, {
            manager: { $set: value ? value.map((x) => x.value) : null },
          })
        )
      },
    },
    orderAssigned: {
      value: orderAssigned.value,
      options: orderAssigned.options,
      onFocus: doRequestOperatorList.bind(this, (operatorList) => {
        setOrderAssigned((x) =>
          update(x, {
            options: { $set: operatorList },
          })
        )
      }),
      onChange: (value) => {
        setOrderAssigned(
          update(orderAssigned, {
            value: { $set: value },
          })
        )

        setCurrentPreset((x) =>
          update(x, {
            operator: { $set: value ? value.map((x) => x.value) : null },
          })
        )
      },
    },
    serviceCategory: {
      value: serviceCategory.value,
      options: serviceCategory.options,
      onFocus: doRequestServiceCategoryList.bind(
        this,
        (serviceCategoryList) => {
          setServiceCategory((x) =>
            update(x, {
              options: { $set: serviceCategoryList },
            })
          )
        }
      ),
      onChange: (value) => {
        let catIds = null

        if (value !== null) {
          catIds = value.map((x) => x.value)
        }

        setServiceCategory(
          update(serviceCategory, {
            value: { $set: value },
          })
        )

        setCurrentPreset((x) =>
          update(x, {
            serviceCategory: { $set: catIds },
          })
        )
      },
    },
    serviceType: {
      value: serviceType.value,
      options: serviceType.options,
      onFocus: doRequestServiceList.bind(this, (serviceList) => {
        setServiceType((x) =>
          update(x, {
            options: { $set: serviceList },
          })
        )
      }),
      onChange: (value) => {
        setServiceType(
          update(serviceType, {
            value: { $set: value },
          })
        )

        setCurrentPreset((x) =>
          update(x, {
            serviceType: { $set: value ? value.map((x) => x.value) : null },
          })
        )
      },
    },
    orderCreated: {
      startDate: orderCreated.startDate,
      endDate: orderCreated.endDate,
      onChange: ({ startDate, endDate }) => {
        setOrderCreated(
          update(orderCreated, {
            startDate: { $set: startDate },
            endDate: { $set: endDate },
          })
        )

        setCurrentPreset((x) =>
          update(x, {
            createdDate: {
              startDate: {
                $set: startDate
                  ? format(new Date(startDate), 'yyyy/MM/dd')
                  : null,
              },
              endDate: {
                $set: endDate ? format(new Date(endDate), 'yyyy/MM/dd') : null,
              },
            },
          })
        )
      },
    },
    orderUpdatedDate: {
      startDate: orderUpdatedDate.startDate,
      endDate: orderUpdatedDate.endDate,
      onChange: ({ startDate, endDate }) => {
        setOrderUpdatedDate(
          update(orderUpdatedDate, {
            startDate: { $set: startDate },
            endDate: { $set: endDate },
          })
        )

        setCurrentPreset((x) =>
          update(x, {
            updatedDate: {
              startDate: {
                $set: startDate
                  ? format(new Date(startDate), 'yyyy/MM/dd')
                  : null,
              },
              endDate: {
                $set: endDate ? format(new Date(endDate), 'yyyy/MM/dd') : null,
              },
            },
          })
        )
      },
    },
    customSearch: {
      value: customSearch,
      onChange: handleSearchThrottled,
    },
    presetName: {
      isEmpty: presetName.isEmpty,
      value: presetName.value,
      onChange: (e) => {
        setPresetName({
          isEmpty: validator.isEmpty(e.target.value),
          value: e.target.value,
        })
      },
      onKeyDown: (e) => {
        if (e.key === 'Enter') {
          handleSave(idPreset !== null, idPreset)
        }
      },
    },
    savePreset: () => {
      handleSave(idPreset !== null, idPreset)
    },
  }

  return (
    <ArchivedOrderFilterContext.Provider
      value={archivedOrderFilterContextValue}
    >
      <ArchivedOrderFilter
        height={height}
        onEdit={handleEdit}
        onReset={handleReset}
        isExpand={isExpand}
        onExpandFilter={handleExpandFilter}
        user={user}
      />
    </ArchivedOrderFilterContext.Provider>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    onFilterOrder: (preset) => dispatch(actions.archivedFilterOrder(preset)),
  }
}

export default connect(null, mapDispatchToProps)(ArchivedOrderFilterContainer)
