import React, { useContext } from 'react'

import './Loader.scss'
import { PreviewContext } from '../../context/previewContext'
import { typeDisplayAnnotation } from '../../shared/constants'
import Spinner from './Spinner'

const Loader = () => {
  const { typeDisplay, isLoadingPage } = useContext(PreviewContext)

  if (typeDisplay === typeDisplayAnnotation.PDF) {
    // see loader file
    return null
  }

  return (
    <div className={`page-loader ${isLoadingPage ? 'active' : ''}`}>
      <Spinner />
    </div>
  )
}

export default Loader
