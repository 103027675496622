import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as Icon from 'react-feather'
import validator from 'validator'
import { toArray } from 'lodash'

import './QuickSearch.scss'
import QuickSearchResult from '../QuickSearchResult/QuickSearchResult'
import toastCenter from '../../shared/toastCenter'
import { OrderListAPI } from '../../api/DesignOrder/OrderList'
import { urlSearchParams } from '../../containers/DesignOrderBoard/utils/helper'
import Spinner from '../Loader/Spinner/Spinner'

let quicksearchTimer

const QuickSearch = () => {
  const [isActive, setIsActive] = useState(false)
  const [isShowResult, setIsShowResult] = useState(false)
  const [isSearching, setIsSearching] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [searchResult, setSearchResult] = useState([])

  const inputRef = useRef()

  const keydownHandler = useCallback((e) => {
    if (e.keyCode === 81 && e.ctrlKey) {
      inputRef.current.focus()
    }

    if (e.keyCode === 27) {
      inputRef.current.blur()
      setSearchResult([])
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler)

    return () => {
      document.removeEventListener('keydown', keydownHandler)
    }
  })

  const handleClick = useCallback(() => {
    if (validator.isEmpty(searchValue)) {
      setIsActive(true)
      inputRef.current.focus()
    } else {
      setSearchResult([])
      setIsShowResult(false)
      setSearchValue('')
    }
  }, [searchValue])

  const handleFocus = useCallback(() => {
    setIsActive(true)
    if (searchResult.length > 0) {
      setIsShowResult(true)
    } else {
      setIsShowResult(false)
    }
  }, [searchResult.length])

  const handleBlur = useCallback(() => {
    setIsActive(false)
    setIsShowResult(false)
  }, [])

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      return false
    }
  }, [])

  const handleHideResult = useCallback(() => {
    setIsShowResult(false)
  }, [])

  const getSearchResultByKeyword = useCallback((keyword) => {
    if (!validator.isEmpty(keyword)) {
      const orderListApi = new OrderListAPI()
      const onNext = (response) => {
        if (response.success) {
          const orderList = toArray(response.data.orders)
          setSearchResult(orderList)
        }
      }
      const onComplete = () => {
        setIsSearching(false)
      }
      const onError = () => {
        setIsSearching(false)
        toastCenter.messageServerError()
      }

      // we need speed up searching with limit 10
      const queryString = urlSearchParams({ customSearch: keyword, limit: 10 })
      orderListApi.subscribe(queryString, onNext, onComplete, onError)
    } else {
      setSearchResult([])
    }
  }, [])

  const handleChange = useCallback(
    (e) => {
      e.persist()

      setSearchValue(e.target.value)
      setIsSearching(true)

      if (quicksearchTimer !== undefined) {
        clearTimeout(quicksearchTimer)
      }

      quicksearchTimer = setTimeout(function () {
        if (!validator.isEmpty(e.target.value)) {
          getSearchResultByKeyword(e.target.value)
          setIsShowResult(true)
        } else {
          setSearchResult([])
          setIsShowResult(false)
          setIsSearching(false)
        }
      }, 500)
    },
    [getSearchResultByKeyword]
  )

  return (
    <div className="position-relative quick-search-wrapp">
      <div
        className={`quick-search-input-wrapp ${
          isActive || !validator.isEmpty(searchValue) ? 'active' : ''
        }`}
      >
        <form>
          <input
            type="text"
            ref={inputRef}
            className=""
            value={searchValue}
            placeholder="Quick Search (CTRL+Q)"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
          <button
            type="button"
            className="btn btn-icon-search"
            onClick={handleClick}
          >
            {validator.isEmpty(searchValue) ? (
              <Icon.Search className="feather" />
            ) : (
              <Icon.X className="feather" />
            )}
          </button>
        </form>
      </div>
      {isSearching && <Spinner />}
      <QuickSearchResult
        results={searchResult}
        showResult={isShowResult}
        onHideResult={handleHideResult}
      />
    </div>
  )
}

export default React.memo(QuickSearch)
