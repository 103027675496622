export default {
  LOGIN: 'login',
  FETCH_MESSAGES: 'fetch-messages',
  RELOAD_PROFILE: 'reload-profile',
  RELOAD_ORDER_LIST: 'reload-order-list',
  RELOAD_ORDER: 'reload-order',
  RELOAD_ORDER_TIMELINE: 'reload-timeline',
  RELOAD_SIDEBAR: 'reload-sidebar',
  NOTIFY_ORDER_DELETED: 'order-deleted',
  NOTIFY_SIDEBAR: 'notify-sidebar',
  RELOAD_OFFICEHOUR: 'reload-officehour',
  FORCE_LOGOUT: 'force-logout',
  SHOW_NOTIFICATION: 'show-notification',
  RELOAD_VERSION_LIST: 'reload-version-list',
  RELOAD_VERSION_DETAIL: 'reload-version',
  RELOAD_CLIENT_BOARD: 'reload-client-board',
  BULK_DOWNLOAD_READY: 'bulk-download-ready',
  BULK_DOWNLOAD_FAILED: 'bulk-download-failed',
  NOTIFY_CARD_WORKING_STOPPED: 'notify-card-working-time-stopped',
  NOTIFY_WORKTIME_AUTO_STOP: 'confirm-worktime-auto-stop',
  WORKTIME_AUTO_STOPPED: 'notify-worktime-stopped',
  RELOAD_SINGLE_COLUMN: 'reload-single-column',
}
