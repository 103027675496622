import * as types from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const userSuccessLoaded = (state, object) => updateObject(state, object);
const userFailedLoaded = (state) => updateObject(state);

const reducer = (state = null, action) => {
    switch (action.type) {
        case types.USER_LOADED_SUCCESS:
            return userSuccessLoaded(state, action.user);
        case types.USER_LOADED_FAILED:
            return userFailedLoaded(state);
        default:
            return state
    }
}

export default reducer;