import React from 'react'

const SvgAppArt = (props) => (
  <svg
    data-name="Layer 1"
    viewBox="0 0 24 24"
    className="app-art-logo"
    {...props}
  >
    <path
      id="blue"
      fill="#E62154"
      d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"
    />
    <path
      id="red"
      fill="#66C1BF"
      d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z"
    />
  </svg>
)

export default SvgAppArt
