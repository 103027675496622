import React, {
  useEffect,
  useRef,
  useContext,
  useCallback,
  useState,
} from 'react'
import { getUnixTime } from 'date-fns'
import { useMediaQuery } from 'react-responsive'

import './PdfRender.scss'
import './PdfToolsRenderer.scss'
import { AbilityContext } from '../../../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../../../shared/ability'
import { PreviewContext } from '../../context/previewContext'
import { PdfContext } from '../../context/pdfContext'
import { VersionContext } from '../../context/versionContext'
import { AnnotationActionContext } from '../../context/annotationActionContext'
import { LoaderContext } from '../../context/loaderContext'
import { hasQueryParams } from '../../../../shared/utility'
import {
  PdfWebViewer,
  PdfFitMode,
  FileType,
} from '@pdf-tools/four-heights-pdf-web-viewer'
import { pdfToolsAPIKey } from '../../../../shared/constant'
import { pdfAnnotationModules } from '../../shared/constants'
import { downloadProofService } from '../../../../services/annotationService/downloadProofService'

const PdfToolsRenderer = () => {
  const containerViewRef = useRef()
  const pdfViewerRef = useRef()
  const viewerContainer = useRef()

  const { onSendComment, annotations, onClose } = useContext(
    AnnotationActionContext
  )
  const { isLoadingFile, onIsLoadingFile, imageBoundRect } = useContext(
    PreviewContext
  )

  const { onChangeScale, updateTotalPage, updateActivePage, user } = useContext(
    PdfContext
  )
  const { selectedVersion } = useContext(VersionContext)
  const { onLoadedPercent } = useContext(LoaderContext)
  const abilityContext = useContext(AbilityContext)

  const updateTotalPageCallback = useCallback(updateTotalPage, [
    updateTotalPage,
  ])

  const [pdfBlob, setPdfBlob] = useState(null)
  const [fdfDataParam, setFdfDataParam] = useState(null)
  const [initPage, setInitPage] = useState(1)

  const isMobileSize = useMediaQuery({ query: `(max-width: 768px)` })

  const handlePagesloaded = useCallback(
    (_) => {
      onChangeScale(parseFloat(imageBoundRect.scale))

      setTimeout(() => {
        onIsLoadingFile(false)
      }, 300)
    },
    [onChangeScale, imageBoundRect.scale, onIsLoadingFile]
  )

  useEffect(() => {
    if (selectedVersion) {
      const docUrl = `${selectedVersion.url}${
        hasQueryParams(selectedVersion.url) ? '&v=' : '?v='
      }${getUnixTime(new Date())}`

      const isDataURL = (str) => {
        const regex = /^\s*data:([a-zA-Z0-9]+\/[a-zA-Z0-9.-]+)?(;charset=[a-zA-Z0-9.-]+)?(;base64)?,/
        return regex.test(str)
      }

      const tryParseJson = (jsonString) => {
        try {
          const jsonData = JSON.parse(jsonString)
          return jsonData
        } catch (e) {
          return jsonString
        }
      }

      function dataURLToBlob(dataURL) {
        const binaryString = atob(dataURL.split(',')[1])
        const len = binaryString.length
        const bytes = new Uint8Array(len)

        for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i)
        }

        return new Blob([bytes], { type: '' })
      }

      const formatSavedAnnotation = (savedAnnotation) => {
        let initialPageNumber = 1
        let dataAnnotation = tryParseJson(savedAnnotation)
        let dataUrl = dataAnnotation
        if (dataAnnotation.page) initialPageNumber = dataAnnotation.page
        if (dataAnnotation.annotation) dataUrl = dataAnnotation.annotation

        let fdfDataParam = isDataURL(dataUrl)
          ? [{ data: dataURLToBlob(dataUrl) }]
          : null
        const formattedAnnotation = {
          fdfData: fdfDataParam,
          initialPageNumber: initialPageNumber,
        }

        return formattedAnnotation
      }

      if (
        selectedVersion.isInternal &&
        annotations.internal.length > 0 &&
        annotations.internal[0].annotation &&
        annotations.internal[0].pdfAnnotationModule ===
          pdfAnnotationModules.pdfTools
      ) {
        const formattedAnnotation = formatSavedAnnotation(
          annotations.internal[0].annotation
        )
        setFdfDataParam(formattedAnnotation.fdfData)
        setInitPage(formattedAnnotation.initialPageNumber)
      } else if (
        annotations.public.length > 0 &&
        annotations.public[0].annotation &&
        annotations.public[0].pdfAnnotationModule ===
          pdfAnnotationModules.pdfTools
      ) {
        const formattedAnnotation = formatSavedAnnotation(
          annotations.public[0].annotation
        )
        setFdfDataParam(formattedAnnotation.fdfData)
        setInitPage(formattedAnnotation.initialPageNumber)
      } else {
        setFdfDataParam(null)
        setInitPage(1)
      }

      const fetchPdfBlob = async () => {
        try {
          const response = await fetch(docUrl)
          if (!response.ok) {
            throw new Error('Network response was not ok')
          }
          let blob = await response.blob()
          blob.name = selectedVersion
            ? selectedVersion.fileName
            : 'PdfWebViewer.pdf'
          setPdfBlob(blob)
        } catch (error) {
          console.error('Error fetching PDF:', error)
        }
      }

      fetchPdfBlob()
    }
  }, [selectedVersion, annotations])

  useEffect(() => {
    async function handleSaveDocument() {
      const currentPage = _viewer.getPageNumber()
      const fdfData = await _viewer.save({
        fileType: FileType.Fdf,
      })
      const reader = new FileReader()
      reader.onloadend = function () {
        let dataAnnotation = {
          page: currentPage ? currentPage : 1,
          annotation: reader.result,
        }
        onSendComment(
          selectedVersion.id,
          '_',
          [],
          null,
          JSON.stringify(dataAnnotation),
          true,
          pdfAnnotationModules.pdfTools
        )
      }
      reader.readAsDataURL(fdfData)
    }

    const options = {
      viewer: {
        general: {
          user: user ? `${user.firstname} ${user.lastname}` : 'User',
        },
        permissions: {
          allowOpenFile: false,
        },
        sidebar: {
          outlineNavigation: false,
        },
        callbacks: {
          onSaveFileButtonClicked: handleSaveDocument,
        },
      },
      annotation: {
        hideAnnotationSubject: true,
        onlyAuthorCanEdit: true,
      },
    }

    const _viewer = new PdfWebViewer(
      viewerContainer.current,
      pdfToolsAPIKey,
      options
    )

    const downloadProof = downloadProofService.reload().subscribe((_) => {
      _viewer.downloadFile()
    })

    const insertButton = (args) => {
      if (args.elemenToInsert) {
        const newButton = document.createElement('button')
        if (args.innerHTML) newButton.innerHTML = args.innerHTML
        if (args.className) newButton.className = args.className
        if (args.attributes && args.attributes.length > 0) {
          for (const attr of args.attributes) {
            newButton.setAttribute(attr.name, attr.value)
          }
        }
        if (args.clickListener) {
          newButton.addEventListener('click', args.clickListener)
        }
        args.elemenToInsert.appendChild(newButton)
      }
    }

    _viewer.addEventListener('appLoaded', async () => {
      onLoadedPercent(`100%`)
      const pwvAppBar = document.getElementsByClassName('pwv-AppBar')
      if (pwvAppBar && pwvAppBar.length > 0) {
        const pwvDocumentBar = pwvAppBar[0].getElementsByClassName(
          'pwv-DocumentBar'
        )
        if (pwvDocumentBar && pwvDocumentBar.length > 0) {
          if (user) {
            insertButton({
              elemenToInsert: pwvDocumentBar[0],
              innerHTML: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="icon-header-annotation"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>`,
              className:
                'btn btn-header-annotation btn-header-annotation-close',
              attributes: [{ name: 'type', value: 'button' }],
              clickListener: () => {
                onClose()
              },
            })

            const fileNameDiv = document.createElement('div')
            fileNameDiv.className = 'd-flex title pl-0 pdf-tools-file-name'
            fileNameDiv.innerHTML = `
                <span>${
                  selectedVersion.fileName
                }</span><span>&nbsp;-&nbsp;</span><span>${
              selectedVersion.version
            }</span>
                ${
                  abilityContext.can(
                    abilityAction.READ,
                    abilityComponent.ACTION_CM_APPROVE
                  ) && selectedVersion.isInternalApproved
                    ? '<span class="badge badge-darkprimary"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather"><path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline points="22 4 12 14.01 9 11.01"></polyline></svg><span>Checked</span></span>'
                    : ''
                }
            `
            pwvDocumentBar[0].appendChild(fileNameDiv)

            insertButton({
              elemenToInsert: pwvDocumentBar[0],
              innerHTML: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather-14"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="7 10 12 15 17 10"></polyline><line x1="12" y1="15" x2="12" y2="3"></line></svg> Download Proof`,
              className:
                'btn btn-sm btn-outline-primary ml-3 d-flex justify-content-center btn-action',
              attributes: [{ name: 'type', value: 'button' }],
              clickListener: () => {
                _viewer.downloadFile()
              },
            })

            insertButton({
              elemenToInsert: pwvDocumentBar[0],
              innerHTML: `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather-14"><path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path><polyline points="17 21 17 13 7 13 7 21"></polyline><polyline points="7 3 7 8 15 8"></polyline></svg> Save Changes`,
              className:
                'btn btn-sm btn-outline-primary ml-2 d-flex justify-content-center btn-action',
              attributes: [{ name: 'type', value: 'button' }],
              clickListener: () => {
                handleSaveDocument()
              },
            })
          }
        }
      }
      if (pdfBlob) {
        _viewer.open({ data: pdfBlob }, fdfDataParam, null, {
          initialFitMode: PdfFitMode.FIT_PAGE,
          initialPageNumber: initPage,
        })
      }
    })

    _viewer.addEventListener('documentLoaded', () => {
      updateTotalPageCallback(_viewer.getPageCount())
      _viewer.setPageNumber(initPage)
      updateActivePage(initPage)
      handlePagesloaded()
    })

    _viewer.addEventListener('pageNumberChanged', (pageNum) => {
      updateActivePage(pageNum)
    })

    return () => {
      _viewer.destroy()
      downloadProof.unsubscribe()
    }
  }, [
    pdfBlob,
    selectedVersion,
    onSendComment,
    handlePagesloaded,
    updateTotalPageCallback,
    updateActivePage,
    onLoadedPercent,
    fdfDataParam,
    initPage,
    user,
    isMobileSize,
    abilityContext,
    onClose,
  ])

  return (
    <div
      className="viewer-container"
      ref={containerViewRef}
      style={{
        visibility: isLoadingFile ? 'hidden' : 'visible',
        position: 'relative',
        display: 'flex',
        width: '100%',
        paddingLeft: '50px',
        paddingRight: '50px',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      <div
        id="viewer"
        className="pdf-viewer"
        ref={pdfViewerRef}
        style={{ visibility: 'hidden' }}
      ></div>
      <div id="viewer-container">
        <div ref={viewerContainer} id="pdf-tools-viewer"></div>
      </div>
    </div>
  )
}

export default React.memo(PdfToolsRenderer)
