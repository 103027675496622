import * as actionTypes from "./actionTypes";

export const filterOrder = (preset) => {
  return {
    type: actionTypes.PRESET_FILTER,
    preset: preset
  };
};

export const filterOrderClear = () => {
  return {
    type: actionTypes.PRESET_FILTER_CLEAR,
  };
};