import { isEqual } from 'lodash'
import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'
import { defaultFilterOrder } from '../../components/OrderFilter/OrderFilterContainer'

const initialState = {
  preset: null,
}

const filterOrder = (state, action) => {
  if (isEqual(action.preset, defaultFilterOrder) && state.preset === null) {
    return state
  }

  return updateObject(state, {
    preset: action.preset,
  })
}

const filterOrderClear = (state) => {
  return updateObject(state, {
    preset: null,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PRESET_FILTER:
      return filterOrder(state, action)
    case actionTypes.PRESET_FILTER_CLEAR:
      return filterOrderClear(state)
    default:
      return state
  }
}

export default reducer
