import React from 'react';
import PropTypes from 'prop-types';

import { withLabel } from '../../../hoc/withLabel/withLabel';
import { withEditable } from '../../../hoc/withEditable/withEditable';

const InputField = React.forwardRef(({
  isEmpty,
  isInvalid,
  onChange,
  value,
  maxLength,
  onFocus = null,
  placeholder = null,
  isRequired = false,
  isText = false,
  style = null,
  inputStyle = null,
  name = null,
  id = null,
  type = "text",
  autoComplete = "off",
  disabled = false,
  readOnly = false,
  pattern = null,
  onKeyDown = null,
  formControlClassName = null
}, ref) => {
  return (
    <>
      {isText
        ? <div><span className="font-weight-bold">{value}</span></div>
        :
        <div className="textbox-form-container" style={style}>
          <input type={type}
            pattern={pattern}
            id={id}
            onKeyDown={onKeyDown}
            onFocus={onFocus}
            className={`form-control ${formControlClassName ? formControlClassName : ''}`}
            style={inputStyle}
            onChange={onChange}
            value={value}
            name={name}
            ref={ref}
            disabled={disabled}
            readOnly={readOnly}
            placeholder={placeholder}
            autoComplete={autoComplete}
            maxLength={maxLength}
          />

          {isRequired && isEmpty && (
            <div className={`input-validation-msg ${isEmpty && 'show'}`}>
              <span className="font-weight-bold">Required!</span>
            </div>
          )}
          {isInvalid && (
            <div className={`input-validation-msg ${isInvalid && 'show'}`}>
              <span>Not Valid</span>
            </div>
          )}
        </div>
      }
    </>
  );
});

InputField.propTypes = {
  id: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  isRequired: PropTypes.bool,
  isEmpty: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  style: PropTypes.object,
  inputStyle: PropTypes.object,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.any,
  pattern: PropTypes.any,
  onKeyDown: PropTypes.any,
  formControlClassName: PropTypes.any,
};

export default withEditable(withLabel(InputField));