import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import tippy from 'tippy.js'

import ProgressBar from '../../ProgressBar/ProgressBar'
import { isFloat } from '../../../shared/utility'

const ActiveOrder = ({ user = {}, isLoading = false, style = {} }) => {
  const [activeOrderUsed, setActiveOrderUsed] = useState(0)
  const [percentOfUsed, setPercentOfUsed] = useState(0)
  const [maximumActiveOrder, setMaximumActiveOrder] = useState(0)
  const [percentOfUsedTitle, setPercentOfUsedTitle] = useState('0%')
  const [subscriptionRetail, setSubscriptionRetail] = useState(0)
  const [customTippy, setCustomTippy] = useState()
  const containerRef = useRef()

  useEffect(() => {
    const activeOrderPopup = document.getElementById('active-order-popup')

    if (customTippy)
      customTippy.setProps({
        content: activeOrderPopup.innerHTML,
        animation: 'shift-away',
        zIndex: 1020,
        appendTo: containerRef.current,
        allowHTML: true,
      })
  }, [customTippy])

  useEffect(() => {
    if (user !== null) {
      const currentActiveOrder = parseInt(
        user.membership.orders.currentActiveOrder
      )
      const maximumActiveOrder =
        parseInt(user.membership.orders.maximumActiveOrder) +
        parseInt(user.retail ? user.retail.activeOrderQuota : 0)
      const percent = maximumActiveOrder
        ? (currentActiveOrder / maximumActiveOrder) * 100
        : 0
      setActiveOrderUsed(currentActiveOrder)
      setPercentOfUsed(percent)
      setPercentOfUsedTitle(
        `${isFloat(percent) ? parseFloat(percent).toFixed(1) : percent}%`
      )
      let totalSubscription = 0

      totalSubscription += parseInt(
        user.retail ? user.retail.activeOrderQuota : 0
      )
      if (totalSubscription > 0) setSubscriptionRetail(totalSubscription)
    }

    const activeOrderPopup = document.getElementById('active-order-popup')

    const customTippy = tippy(containerRef.current, {
      content: activeOrderPopup.innerHTML,
      animation: 'shift-away',
      zIndex: 1020,
      appendTo: containerRef.current,
      allowHTML: true,
    })
    setCustomTippy(customTippy)
  }, [user])

  useEffect(() => {
    if (user !== null) {
      setMaximumActiveOrder(
        user.membership.orders.maximumActiveOrder +
          (user.retail ? user.retail.activeOrderQuota : 0)
      )
    }
  }, [user])

  if ((user === null && isLoading) || (user === null && !isLoading)) {
    return null
  }

  return (
    <div
      className="membership-status active-order"
      ref={containerRef}
      style={Object.assign(style, {})}
    >
      {/* 
        Tippy custom HTML
      */}
      <div id="active-order-popup" style={{ display: 'none' }}>
        <span>Your active orders which can be processed at any one time.</span>
        {subscriptionRetail > 0 && (
          <>
            <div>
              <div className="d-flex justify-content-between mt-2">
                <div>{user.membership.title} membership</div>
                <div>
                  {user.membership.orders.maximumActiveOrder} active order
                </div>
              </div>
              {subscriptionRetail > 0 ? (
                <div className="d-flex justify-content-between mt-1 text-subscription-retail">
                  <div>Subscription Active Orders</div>
                  <div>{user.retail.activeOrderQuota} active order</div>
                </div>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </div>
      <div className="clearfix text-membership-status w-100">
        <div className="float-left d-flex">
          <span className="text-12 text-light">Active Orders</span>
          {subscriptionRetail > 0 && (
            <span className="dot-subscription-retail"> </span>
          )}
        </div>
        <div className="float-right clearfix d-flex align-items-center">
          <div
            className={`text-12 ${
              parseInt(percentOfUsed) >= 90 ? 'text-danger' : 'text-light'
            }`}
          >
            <strong>{activeOrderUsed}</strong>
            <span>&nbsp;/&nbsp;</span>
            <strong>{maximumActiveOrder}</strong>
            <strong>&nbsp;Orders</strong>
            <span>&nbsp;({percentOfUsedTitle})</span>
          </div>
        </div>
      </div>
      <ProgressBar
        valuenow={activeOrderUsed}
        valuemin={0}
        valuemax={maximumActiveOrder}
      />
    </div>
  )
}

ActiveOrder.propTypes = {
  style: PropTypes.object,
}

export default ActiveOrder
