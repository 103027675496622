import React from 'react'
import SkeletonLine from '../../components/Skeleton/SkeletonLine'

export const withLabel = (WrappedComponent) => {
  return React.forwardRef((props, ref) => {
    const withLabel = props.withLabel !== undefined ? props.withLabel : true

    return (
      <div
        className={`form-group ${
          props.formClassName ? props.formClassName : ''
        }`}
      >
        {props.isLoading ? (
          <SkeletonLine style={{ ...props.style, height: '32px' }} />
        ) : withLabel ? (
          <>
            {props.label && (
              <label htmlFor="title">
                {props.label}&nbsp;
                {props.isRequired && <span className="text-danger">*</span>}
              </label>
            )}
            <WrappedComponent {...props} ref={ref} />
          </>
        ) : (
          <WrappedComponent {...props} ref={ref} />
        )}
      </div>
    )
  })
}
