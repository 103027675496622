import * as actionTypes from './actionTypes'

export const archivedQuickSearch = (search) => {
  return {
    type: actionTypes.ARCHIVED_QUICK_SEARCH,
    search: search,
  }
}

export const archivedQuickSearchClear = () => {
  return {
    type: actionTypes.ARCHIVED_QUICK_SEARCH_CLEAR,
  }
}
