import React, { useState } from 'react';
import * as Icon from 'react-feather';
import PropTypes from 'prop-types';

import './PasswordShowHide.scss';

const PasswordShowHide = ({
  password,
  onChange,
  name = null,
  id = null,
  style = null,
  maxLength = null,
  isEmpty = false,
  isInvalid = false,
  onKeyDown = null,
  autoComplete = "off",
  placeholder = 'Password',
}) => {
  const [hidden, setHidden] = useState(true);

  const toggleShow = () => {
    setHidden(!hidden);
  };

  return (
    <div className="password-show-hide" style={style}>
      <input
        type={hidden ? "password" : "text"}
        id={id}
        name={name}
        onKeyDown={onKeyDown}
        value={password}
        onChange={onChange}
        autoComplete={autoComplete}
        maxLength={maxLength}
        placeholder={placeholder}
      />
      <div className={`input-validation-msg ${isEmpty && 'show'}`}>
        <span className="font-weight-bold">Required!</span>
      </div>
      <div className={`input-validation-msg ${isInvalid && 'show'}`}>
        <span>Not Valid</span>
      </div>
      <button type="button" className="btn btn-icon" onClick={toggleShow.bind(this)}>
        {hidden
          ? <Icon.EyeOff width={18} height={18} />
          : <Icon.Eye width={18} height={18} />
        }
      </button>
    </div>
  );
};

PasswordShowHide.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  maxLength: PropTypes.any,
  password: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func,
  isEmpty: PropTypes.bool,
  isInvalid: PropTypes.bool,
  onKeyDown: PropTypes.any,
};

export default PasswordShowHide;