import * as actionTypes from "./actionTypes";
import toastCenter from "../../shared/toastCenter";
import { NotificationAPI } from "../../api/Notification/GetNotification";
import { NotificationDetailAPI } from "../../api/Notification/GetNotificationGroupDetail";

export const pushCountNotification = (count) => {
  return {
    type: actionTypes.NOTIFICATION_COUNT,
    count: count
  };
};

export const getAllMessageNotification = (messages = [], meta = {}) => {
  return {
    type: actionTypes.NOTIFICATION_MESSAGES,
    messages: messages,
    meta: meta
  };
};

export const fetchMessageNotification = (params = null, callback = null) => {
  return dispatch => {
    const onNext = (response) => {
      if (response.success) {
        if (typeof callback === "function") {
          callback(response);
        }
        dispatch(getAllMessageNotification(response.data, response.meta));
      }
    };
    const onError = () => {
      toastCenter.messageServerError();
      if (typeof callback === "function") {
        callback();
      }
    };
    const onComplete = () => {};

    const notificationAPI = new NotificationAPI();
    if (params) {
      notificationAPI.setParams(params);
    }
    notificationAPI.subscribe(onNext, onComplete, onError);
  };
};

export const fetchMessageDetailNotification = (group, params = null, callback = null) => {
  return dispatch => {
    const onNext = (response) => {
      if (response.success) {
        if (typeof callback === "function") {
          callback(response);
        }
        dispatch(getAllMessageNotification(response.data, response.meta));
      }
    };
    const onError = () => {
      toastCenter.messageServerError();
      if (typeof callback === "function") {
        callback();
      }
    };
    const onComplete = () => {};

    const notificationDetailAPI = new NotificationDetailAPI();
    if (params) {
      notificationDetailAPI.setParams(params);
    }
    notificationDetailAPI.subscribe(group, onNext, onComplete, onError);
  };
};

export const pushMessageNotification = (data) => {
  return {
    type: actionTypes.NOTIFICATION_PUSH_MESSAGE,
    content: data
  };
};