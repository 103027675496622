import React, { useState, useRef, useEffect, useContext } from 'react'
import { EditorState } from 'draft-js'
import Editor from 'draft-js-plugins-editor'
import createMentionPlugin, {
  defaultSuggestionsFilter,
} from 'draft-js-mention-plugin'
import createEmojiPlugin from 'draft-js-emoji-plugin'
import update from 'immutability-helper'

import 'draft-js/dist/Draft.css'
import 'draft-js-mention-plugin/lib/plugin.css'
import 'draft-js-emoji-plugin/lib/plugin.css'

import './CommentEditingEditor.scss'
// import { getSelectionContent } from '../../shared/utils';
import { saveKeyBindingFn } from '../../shared/utils'
import { ShapeContext } from '../../context/shapeContext'

const CommentEditingEditor = ({ editCommentState, onSend, onCancel }) => {
  const [previewEditorState, setPreviewEditorState] = useState(
    EditorState.createEmpty()
  )
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [inMentioned, setInMentioned] = useState([])
  const [suggestions, setSuggestions] = useState([])
  const [{ plugins }] = useState(() => {
    const mentionPlugin = createMentionPlugin({
      mentionPrefix: '@',
      supportWhitespace: true,
      entityMutability: 'IMMUTABLE',
      mentionComponent: ({ entityKey, children }) => {
        return (
          <span key={entityKey} className="mentioned-user">
            {children}
          </span>
        )
      },
    })
    const emojiPlugin = createEmojiPlugin()

    const plugins = [mentionPlugin, emojiPlugin]
    return {
      plugins,
    }
  })
  const [{ pluginsEditing, MentionSuggestions, EmojiSuggestions }] = useState(
    () => {
      const mentionPluginEditing = createMentionPlugin({
        mentionPrefix: '@',
        supportWhitespace: true,
        entityMutability: 'IMMUTABLE',
        mentionComponent: ({ entityKey, children }) => {
          return (
            <span key={entityKey} className="mentioned-user">
              {children}
            </span>
          )
        },
      })
      const { MentionSuggestions } = mentionPluginEditing
      const emojiPluginEditing = createEmojiPlugin()
      const { EmojiSuggestions } = emojiPluginEditing

      const pluginsEditing = [mentionPluginEditing, emojiPluginEditing]
      return {
        pluginsEditing,
        EmojiSuggestions,
        MentionSuggestions,
      }
    }
  )

  const editorRef = useRef()
  const shapeContextValue = useContext(ShapeContext)
  const listMentions = shapeContextValue.listMentions

  useEffect(() => {
    editorRef.current.focus()
  }, [])

  useEffect(() => {
    if (listMentions.length > 0) {
      setSuggestions(listMentions)
    }
  }, [listMentions])

  useEffect(() => {
    if (editCommentState.getCurrentContent().hasText()) {
      setPreviewEditorState((x) => update(x, { $set: editCommentState }))
      // async, wait until all draft js plugin ready
      // const selection = getSelectionContent(editCommentState);
      // setTimeout(() => {
      //   setEditorState(x => update(x, { $set: EditorState.acceptSelection(editCommentState, selection) }));
      // }, 50);

      // setTimeout(() => {
      //   editorRef.current.focus();
      // }, 100);
    }
  }, [editCommentState])

  const handleChange = (newState) => {
    setEditorState(newState)
  }

  const handleChangePreview = (newState) => {
    setPreviewEditorState(newState)
  }

  const focusEditor = () => {
    if (editorRef) {
      editorRef.current.focus()
    }
  }

  const handleKeyCommand = (command) => {
    if (command === 'editor-send-comment') {
      handleUpdateComment(editorState)

      return 'handled'
    }
    return 'not-handled'
  }

  const handleUpdateComment = (editorState) => {
    onSend(editorState, inMentioned)

    setEditorState(EditorState.moveFocusToEnd(EditorState.createEmpty()))
  }

  const handleOnAddMention = (mention) => {
    setInMentioned((o) => {
      let pushedMention = o
      if (pushedMention.length > 0) {
        pushedMention = pushedMention.filter((ol) => ol.id !== mention.id)
      }
      return pushedMention.concat(mention)
    })

    // exclude mention twice
    setSuggestions((o) => o.filter((m) => m.id !== mention.id))
  }

  const handleOnSearchChange = (data) => {
    setSuggestions(defaultSuggestionsFilter(data.value, listMentions))
  }

  return (
    <div className="comment-edit-wrapper" onClick={focusEditor}>
      <div className="d-flex flex-column">
        <div className="title">
          <span className="font-weight-bold">Edit comment</span>
        </div>
        <div className="old-comment">
          <Editor
            editorState={previewEditorState}
            plugins={plugins}
            onChange={handleChangePreview}
            readOnly
          />
        </div>
      </div>
      <div className="edit-comment-editor">
        <Editor
          ref={editorRef}
          editorState={editorState}
          placeholder="New comment"
          onChange={handleChange}
          plugins={pluginsEditing}
          handleKeyCommand={handleKeyCommand}
          onEscape={onCancel}
          keyBindingFn={saveKeyBindingFn}
        />

        <EmojiSuggestions />

        <MentionSuggestions
          onSearchChange={handleOnSearchChange}
          onAddMention={handleOnAddMention}
          suggestions={suggestions}
        />
      </div>
      <div className="d-flex flex-row">
        <button
          type="button"
          className="btn btn-sm btn-darkprimary"
          onClick={() => handleUpdateComment(editorState)}
        >
          Save
        </button>
        <button
          type="button"
          className="btn btn-sm btn-secondary ml-2"
          onClick={onCancel}
        >
          Cancel
        </button>
      </div>
    </div>
  )
}

export default CommentEditingEditor
