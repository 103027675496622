import React, { useRef, useContext, useState, useEffect } from 'react'
import * as Icon from 'react-feather'
import { Transition } from 'react-transition-group'

import './Tooltip.scss'
import { positionCommentPlace } from '../../shared/constants'
import CommentContainer from '../CommentContainer/CommentContainer'
import { TooltipContext } from '../../context/tooltipContext'
import { ShapeContext } from '../../context/shapeContext'

const duration = 0

const defaultStyle = {
  transition: `opacity ${duration}ms ease-in-out`,
  visibility: 'visible',
  opacity: 0,
}

const transitionStyles = {
  entered: { opacity: 1, visibility: 'visible' },
  exited: { opacity: 0, visibility: 'hidden' },
}

const Tooltip = () => {
  const {
    selectedTopic,
    tooltipPosition,
    tooltipPlace,
    onShowTooltip,
    onIsCloseTooltipEvent,
    isShowTooltip,
  } = useContext(TooltipContext)
  const { clearActiveDrawingArea, clearUnsavedTopic } = useContext(ShapeContext)

  const [comments, setComments] = useState([])
  const [isResolve, setIsResolve] = useState(null)

  useEffect(() => {
    if (selectedTopic !== null) {
      setComments(selectedTopic.comments)
      setIsResolve(selectedTopic.isResolve)
    } else {
      setComments([])
      setIsResolve(false)
    }
  }, [selectedTopic])

  const wrapperRef = useRef(null)
  const tailHeight = 8

  let classPosition = 'tooltip-top'
  let xPos = tooltipPosition ? tooltipPosition.x : 0
  let yPos = tooltipPosition ? tooltipPosition.y - tailHeight : 0

  if (tooltipPlace === positionCommentPlace.BOTTOM) {
    classPosition = 'tooltip-bottom'
    yPos = tooltipPosition ? tooltipPosition.y + tailHeight : 0
  }

  const defaultStyleRenderer = {
    ...defaultStyle,
    top: `${yPos}px`,
    left: `${xPos}px`,
  }

  const handleClose = (e) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    onShowTooltip(false)
    onIsCloseTooltipEvent(true)

    if (typeof clearActiveDrawingArea === 'function') {
      clearActiveDrawingArea()
    }

    if (typeof clearUnsavedTopic === 'function') {
      clearUnsavedTopic()
    }
  }

  return (
    <Transition in={isShowTooltip} timeout={150} unmountOnExit mountOnEnter>
      {(state) => (
        <div
          ref={wrapperRef}
          className="tooltip-wrapper"
          style={{
            ...defaultStyleRenderer,
            ...transitionStyles[state],
          }}
        >
          <div className="position-relative">
            <div className={`tooltip-content-wrapper ${classPosition}`}>
              <button
                type="button"
                className="btn btn-close"
                onClick={handleClose}
              >
                <Icon.XCircle className="close-icon" />
              </button>
              <div className="d-flex flex-column">
                <CommentContainer
                  comments={comments}
                  isResolve={isResolve}
                  onTooltipClose={handleClose}
                  isTooltip
                />
              </div>
            </div>

            <div className={`tooltip-tail ${classPosition}`}></div>
          </div>
        </div>
      )}
    </Transition>
  )
}

export default Tooltip
