import React, { useContext, useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Transition } from 'react-transition-group'
import { connect } from 'react-redux'
import * as Icon from 'react-feather'

import './PanelVersion.scss'
import { HeaderAnnotationContext } from '../../context/headerAnnotationContext'
import { convertToUserTimezone } from '../../../../shared/dateTimeHelper'
import { PreviewContext } from '../../context/previewContext'
import { VersionContext } from '../../context/versionContext'
import { TooltipContext } from '../../context/tooltipContext'
import { AbilityContext } from '../../../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../../../shared/ability'
import {
  attachmentTypeStartFromStruct,
  pdfAnnotationModules,
} from '../../shared/constants'

const duration = 300

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  visibility: 'visible',
  opacity: 0,
  overflow: 'auto',
  // transform: `translateX(-100%)`,
}

const transitionStyles = {
  entered: {
    opacity: 1,
    // transform: `translateX(0)`,
    // visibility: 'visible'
  },
  exited: {
    opacity: 0,
    // transform: `translateX(-100%)`,
    // visibility: 'hidden'
  },
}

const VersionItem = ({
  version,
  onApproveVersion,
  onReviseVersion,
  orderProgress,
  user,
  onChangeVersion,
  className,
  index,
  onDeleteAttachmentVersion,
}) => {
  const abilityContext = useContext(AbilityContext)
  const [disabledApproveButton, setDisabledApproveButton] = useState(false)
  const [disabledReviseButton, setDisabledReviseButton] = useState(false)
  const [isDisabledDeleteVersion, setIsDisabledDeleteVersion] = useState(false)
  const { thumbnail } = version

  const handleApproveVersion = (e) => {
    setDisabledApproveButton(true)
    onApproveVersion(e, version.id)
    setTimeout(() => {
      setDisabledApproveButton(false)
    }, 10000)
  }

  const handleReviseVersion = (e) => {
    setDisabledReviseButton(true)
    onReviseVersion(e, version.id)
    setTimeout(() => {
      setDisabledReviseButton(false)
    }, 10000)
  }

  const handleDeleteAttachmentVersion = (e, version) => {
    setIsDisabledDeleteVersion(true)
    onDeleteAttachmentVersion(e, version)
    setTimeout(() => {
      setIsDisabledDeleteVersion(false)
    }, 5000)
  }

  return (
    <li onClick={(e) => onChangeVersion(e, index)} className={className}>
      <div className="d-flex flex-column">
        <div
          className="preview-version-file"
          style={{
            backgroundImage: `url(${thumbnail})`,
          }}
        >
          {abilityContext.can(
            abilityAction.READ,
            abilityComponent.ACTION_CM_APPROVE
          ) &&
            version.isInternalApproved && (
              <span className="badge badge-darkprimary">
                <Icon.CheckCircle className="feather" />
                <span>Checked</span>
              </span>
            )}

          {/* {abilityContext.can(
            abilityAction.UPDATE,
            abilityComponent.ACTION_CM_APPROVE
          ) &&
            version.isAllowedToReview &&
            orderProgress === 'in-progress' && ( */}
          <div className="toolbox-action">
            {abilityContext.can(
              abilityAction.UPDATE,
              abilityComponent.ACTION_CM_APPROVE
            ) &&
              version.isAllowedToReview &&
              orderProgress === 'in-progress' && (
                <>
                  {version.isInternalApproved ? (
                    <button
                      type="button"
                      className="btn btn-sm btn-danger"
                      id="btn-revise-version"
                      onClick={handleReviseVersion}
                      disabled={disabledReviseButton ? true : false}
                    >
                      <Icon.RefreshCcw className="feather" />
                      <span>Revise</span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-sm btn-darkprimary"
                      id="btn-approve-version"
                      onClick={handleApproveVersion}
                      disabled={disabledApproveButton ? true : false}
                    >
                      <Icon.CheckCircle className="feather" />
                      <span>Approve</span>
                    </button>
                  )}
                </>
              )}
            {abilityContext.can(
              abilityAction.UPDATE,
              abilityComponent.ASSIGNED_OPERATOR
            ) && (
              <>
                <button
                  type="button"
                  className="btn ml-2 btn-sm btn-danger"
                  id="btn-revise-version"
                  onClick={(e) => handleDeleteAttachmentVersion(e, version)}
                  disabled={
                    version.isInternalApproved ||
                    isDisabledDeleteVersion ||
                    version.disabledDelete
                      ? true
                      : false
                  }
                >
                  <Icon.Trash2 className="feather" />
                  <span>Delete</span>
                </button>
              </>
            )}
          </div>
          {/*  )} */}
        </div>
        <div className="text-center">
          {abilityContext.can(
            abilityAction.READ,
            abilityComponent.ACTION_CM_APPROVE
          ) &&
          version.version.toString() !== version.draftVersion.toString() ? (
            <span className="title">{`${version.fileName} - ${version.version} (checked from ${version.draftVersion})`}</span>
          ) : (
            <span className="title">{`${version.fileName} - ${version.version}`}</span>
          )}
        </div>
        <div className="text-center">
          <span className="time">
            {
              convertToUserTimezone(version.createdAt, user.timeZone)
                .simpleFormatDate
            }
          </span>
        </div>
      </div>
    </li>
  )
}

const PanelVersion = ({
  user = null,
  onTogglePanel,
  pdfAnnotationModule = null,
}) => {
  const {
    isShowVersionPanel,
    isShowPublicComment,
    isInternalTabDisabled,
    onShowInternalComment,
    onShowPublicComment,
    isPublicTabDisabled,
  } = useContext(HeaderAnnotationContext)

  const {
    versions,
    onSelectedVersion,
    selectedInternalVersionIndex,
    selectedPublicVersionIndex,
    onSelectedInternalVersionIndex,
    onSelectedPublicVersionIndex,
    onChangeVersion,
    onApproveVersion,
    onReviseVersion,
    orderProgress,
    onDeleteAttachmentVersion,
    attachmentTypeStartFrom,
  } = useContext(VersionContext)
  const { onIsLoadingFile } = useContext(PreviewContext)
  const { onShowTooltip } = useContext(TooltipContext)
  const abilityContext = useContext(AbilityContext)

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const history = useHistory()

  const handleChangeTab = useCallback(
    (type = 'internal') => {
      if (
        queryParams &&
        (queryParams.has('versionId') || queryParams.has('commentId'))
      ) {
        queryParams.delete('versionId')
        queryParams.delete('commentId')
      }
      if (type === 'internal') {
        onShowInternalComment()
        queryParams.set('public', false)
      } else {
        onShowPublicComment()
        queryParams.set('public', true)
      }
      history.replace({
        search: queryParams.toString(),
      })
    },
    [history, onShowInternalComment, onShowPublicComment, queryParams]
  )

  const handleChangeVersion = useCallback(
    (e, index) => {
      e.stopPropagation()
      e.nativeEvent.stopImmediatePropagation()

      if (isShowPublicComment) {
        if (selectedPublicVersionIndex !== index) {
          onIsLoadingFile(true)
          onShowTooltip(false)
          onSelectedPublicVersionIndex(index)
          onSelectedVersion(versions.public[index])
          onChangeVersion(versions.public[index].id, isShowPublicComment)
        }
      } else {
        if (selectedInternalVersionIndex !== index) {
          onIsLoadingFile(true)
          onShowTooltip(false)
          onSelectedInternalVersionIndex(index)
          onSelectedVersion(versions.internal[index])
          onChangeVersion(versions.internal[index].id, isShowPublicComment)
        }
      }
      if (
        queryParams &&
        (queryParams.has('versionId') || queryParams.has('commentId'))
      ) {
        queryParams.delete('versionId')
        queryParams.delete('commentId')
        history.replace({
          search: queryParams.toString(),
        })
      }
    },
    [
      history,
      isShowPublicComment,
      onChangeVersion,
      onIsLoadingFile,
      onSelectedInternalVersionIndex,
      onSelectedPublicVersionIndex,
      onSelectedVersion,
      onShowTooltip,
      queryParams,
      selectedInternalVersionIndex,
      selectedPublicVersionIndex,
      versions,
    ]
  )

  return (
    <div
      className={`panel-versions-wrapper ${
        isShowVersionPanel ? 'collapsed' : ''
      }`}
    >
      <div className="collapse-panel" onClick={onTogglePanel}>
        <span>
          {isShowVersionPanel ? (
            <Icon.ChevronsLeft className="feather" />
          ) : (
            <Icon.ChevronsRight className="feather" />
          )}
        </span>
      </div>
      <Transition
        in={isShowVersionPanel}
        timeout={duration}
        unmountOnExit
        mountOnEnter
      >
        {(state) => (
          <div
            className="content"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            {pdfAnnotationModule === pdfAnnotationModules.pdfTools &&
              abilityContext.can(
                abilityAction.READ,
                abilityComponent.ANNOTATION_PUBLIC_INTERNAL_COMMENT
              ) &&
              attachmentTypeStartFrom ===
                attachmentTypeStartFromStruct.INTERNAL_REVIEW && (
                <ul className="list-inline m-0 version-visibility">
                  <li className="list-inline-item first">
                    <button
                      type="button"
                      className={`btn btn-header-annotation position-relative ${
                        !isShowPublicComment ? 'active' : ''
                      }`}
                      disabled={isInternalTabDisabled}
                      onClick={() => handleChangeTab('internal')}
                    >
                      <Icon.Lock className="icon-header-annotation" />
                      <span>Internal</span>
                    </button>
                  </li>
                  <li className="list-inline-item">
                    <button
                      type="button"
                      className={`btn btn-header-annotation position-relative ${
                        isShowPublicComment ? 'active' : ''
                      }`}
                      disabled={isPublicTabDisabled}
                      onClick={() => handleChangeTab('public')}
                    >
                      <Icon.Users className="icon-header-annotation" />
                      <span>Public</span>
                    </button>
                  </li>
                </ul>
              )}

            {isShowPublicComment
              ? versions.public.length > 0 && (
                  <ul className="list-unstyled m-0">
                    {versions.public.map((version, key) => (
                      <VersionItem
                        key={key}
                        index={key}
                        version={version}
                        onApproveVersion={onApproveVersion}
                        onReviseVersion={onReviseVersion}
                        orderProgress={orderProgress}
                        oChangeVersion={handleChangeVersion}
                        className={
                          isShowPublicComment
                            ? selectedPublicVersionIndex === key
                              ? 'active'
                              : ''
                            : selectedInternalVersionIndex === key
                            ? 'active'
                            : ''
                        }
                        user={user}
                        onChangeVersion={handleChangeVersion}
                        onDeleteAttachmentVersion={onDeleteAttachmentVersion}
                      />
                    ))}
                  </ul>
                )
              : versions.internal.length > 0 && (
                  <ul className="list-unstyled m-0">
                    {versions.internal.map((version, key) => (
                      <VersionItem
                        key={key}
                        index={key}
                        version={version}
                        onApproveVersion={onApproveVersion}
                        onReviseVersion={onReviseVersion}
                        orderProgress={orderProgress}
                        oChangeVersion={handleChangeVersion}
                        className={
                          isShowPublicComment
                            ? selectedPublicVersionIndex === key
                              ? 'active'
                              : ''
                            : selectedInternalVersionIndex === key
                            ? 'active'
                            : ''
                        }
                        user={user}
                        onChangeVersion={handleChangeVersion}
                        onDeleteAttachmentVersion={onDeleteAttachmentVersion}
                      />
                    ))}
                  </ul>
                )}
          </div>
        )}
      </Transition>
    </div>
  )
}

const mapStateToProps = ({ user }) => {
  return { user }
}

export default connect(mapStateToProps, null)(React.memo(PanelVersion))
