import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import * as actions from '../../../store/actions/index'
import { socketRemoveAllListeners } from '../../../socket/socket'
import { useAuth0 } from '@auth0/auth0-react'
import AppLoader from '../../../components/Loader/AppLoader/AppLoader'
import branding from '../../../shared/branding'

const Logout = ({ onLogout }) => {
  const { logout } = useAuth0()

  useEffect(() => {
    socketRemoveAllListeners()
    onLogout()
    setTimeout(() => {
      logout()
    }, 2000)
  }, [onLogout, logout])

  return <AppLoader branding={branding} loading={true} />
}

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (cb) => dispatch(actions.logout(cb)),
  }
}

export default connect(null, mapDispatchToProps)(Logout)
