import { Subject } from 'rxjs'

const subject = new Subject()
export const archivedOrderFilterService = {
  emitReload: (val) => subject.next(val),
  reload: () => subject.asObservable(),
}

const subjectQuickSearch = new Subject()
export const archiveQuickSearchService = {
  emitReload: (val) => subjectQuickSearch.next(val),
  reload: () => subjectQuickSearch.asObservable(),
}
