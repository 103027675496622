import React from 'react'

export const AnnotationActionContext = React.createContext({
  onDownloadFile: null,
  onOpenNewTab: null,
  onCopyFileUrl: null,
  onSendComment: null,
  onSaveEditComment: null,
  onDeleteComment: null,
  onResolveComment: null,
  onReopenComment: null,
  onClose: null,
  annotations: null,
})
