import React from 'react'

import ApiLoader from '../ApiLoader/ApiLoader'

function RefreshComponent(props) {
  return (
    <div>
      <div>
        <span style={{ fontSize: '18px' }}>
          Taking a long time to load component...
        </span>
      </div>
      <div>
        <button className="btn btn-darkprimary btn-sm" onClick={props.retry}>
          Retry
        </button>
      </div>
    </div>
  )
}

function RefreshPage(props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -100%)',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginBottom: '15px' }}>
          <span style={{ fontSize: '18px' }}>
            We have deployed this site with a new version.
          </span>
        </div>
        <div>
          {/* <button className="btn btn-darkprimary btn-sm" onClick={props.retry}>Refresh</button> */}
          <button
            className="btn btn-darkprimary btn-sm"
            onClick={() => window.location.reload()}
          >
            Refresh
          </button>
        </div>
      </div>
    </div>
  )
}

export const LoadingComponent = (props) => {
  if (props.error) {
    return <RefreshComponent {...props} />
  } else if (props.timedOut) {
    return <RefreshComponent {...props} />
  } else if (props.pastDelay) {
    return <div>Loading...</div>
  } else {
    return null
  }
}

export const LoadingContainer = (props) => {
  if (props.error) {
    return <RefreshPage {...props} />
  } else if (props.timedOut) {
    return <RefreshPage {...props} />
  } else if (props.pastDelay) {
    return <ApiLoader />
  } else {
    return null
  }
}
