import React from 'react'
import { toast } from 'react-toastify'

import ToastMessage from '../components/ToastMessage/ToastMessage'
import { capitalizeFirstLetter } from './utility'
import branding from './branding'

export const toastMessageType = {
  SUCCESS: 'SUCCESS',
  WARNING: 'WARNING',
  DANGER: 'DANGER',
  ERROR: 'ERROR',
  LOADING: 'LOADING',
  DOWNLOAD: 'DOWNLOAD',
  UPLOAD: 'UPLOAD',
}

export const notificationMessageType = {
  INFO: 'info',
  WARNING: 'warning',
  ERROR: 'error',
}

const notificationServerError = () => {
  const msg = (
    <ToastMessage
      type="danger"
      title="Ooops!"
      content={`Error encountered while processing. Please try again later or contact ${branding.NAME} admin.`}
    />
  )

  toast(msg, {
    autoClose: true,
    className: 'bg-danger',
    progressClassName: 'bg-light',
  })
}

const notificationServerErrorCustom = (response) => {
  if (response.message) {
    const msg = (
      <ToastMessage
        type="danger"
        title={response.message}
        messages={response.messages}
      />
    )
    toast(msg, {
      autoClose: true,
      className: 'bg-danger',
      progressClassName: 'bg-light',
    })
  } else {
    notificationServerError()
  }
}

const loading = (message = '') => {
  const msg = (
    <ToastMessage type="loading" title="Loading..." content={message} />
  )
  return toast(msg, {
    autoClose: false,
    className: 'bg-darkprimary',
  })
}

const downloading = (toastId = '', message = '') => {
  const msg = (
    <ToastMessage
      toastId={toastId}
      type={toastMessageType.DOWNLOAD}
      title="Downloading"
      content={message}
    />
  )
  return toast(msg, {
    toastId: toastId,
    autoClose: false,
    className: 'bg-dark',
  })
}

// array content by uploading file
const uploading = (toastId = '', files = []) => {
  const msg = (
    <div className="h-100">
      {files.map((_, idx) => {
        return (
          <div className="d-flex flex-column" key={idx}>
            <ToastMessage
              toastId={toastId}
              type={toastMessageType.UPLOAD}
              title=""
              uploadIndex={idx}
            />
          </div>
        )
      })}
    </div>
  )
  return toast(msg, {
    toastId: toastId,
    autoClose: false,
    className: 'bg-dark',
  })
}

const message = (
  title,
  content,
  typeMessage = toastMessageType.SUCCESS,
  autoClose = true
) => {
  const props = {
    type: typeMessage,
    title: title,
    content: typeof content === 'string' ? content : null,
    messages: typeof content === 'object' ? content : [],
  }

  let bgToast = 'bg-darkprimary'

  switch (typeMessage) {
    case toastMessageType.WARNING:
      bgToast = 'bg-warning'
      break
    case toastMessageType.DANGER:
    case toastMessageType.ERROR:
      bgToast = 'bg-danger'
      break
    case toastMessageType.SUCCESS:
      bgToast = 'bg-success'
      break
    case toastMessageType.LOADING:
      bgToast = 'bg-darkprimary'
      break
    default:
      bgToast = 'bg-darkprimary'
  }

  const msg = <ToastMessage {...props} />

  return toast(msg, {
    autoClose: autoClose,
    className: bgToast,
    progressClassName: 'bg-light',
  })
}

const pushNotification = (
  message,
  title = null,
  typeMessage = notificationMessageType.INFO,
  autoClose = true
) => {
  const props = {
    type: typeMessage,
    title: title !== null ? title : capitalizeFirstLetter(typeMessage),
    content: typeof message === 'string' ? message : null,
    message: typeof message === 'object' ? message : [],
  }

  const msg = <ToastMessage {...props} />

  return toast(msg, {
    autoClose: autoClose,
    className: 'bg-light',
    progressClassName: 'bg-light',
  })
}

export default {
  messageServerError: notificationServerError,
  messageServerErrorCustom: notificationServerErrorCustom,
  message,
  pushNotification,
  loading,
  downloading,
  uploading,
}
