import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { tourGuideAction } from '../../shared/tourGuideAction';
import { addNewPaymentMethodService, addNewPaymentMethodStepTwoService } from '../../services/tourGuideService/addNewPaymentMethodService';
import { userDetailService } from '../../services/userDetailService/userDetailService';

function AddNewPaymentMethodTourGuide({ run }) {
    const [autoRun, setAutoRun] = useState(false);

    const [steps, setSteps] = useState([
        {
            target: '.add-new-payment-method-form',
            title: 'Billing Information Screen',
            content: (<p>Please note that every mandatory field must be filled (marked with a red asterisk <span className="tour-red">*</span>). Therefore, please complete the information by filling out the empty fields.</p>),
            disableBeacon: true,
            lastStep: false
        },
        {
            target: '.add-new-payment-method-form-fake',
            title: 'Fake step',
            content: 'Fake step to show skip button',
            disableBeacon: true,
            lastStep: false
        }
    ]);

    const customStyles = {
        overlay: {
            position: 'fixed',
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 999999
        },
        spotlight: {
            zIndex: 999999
        },
        buttonClose: {
            display: 'none',
        },
        floater: {
            tooltip: {
                zIndex: 999999
            }
        }
    };

    const handleJoyrideCallback = data => {
        const { status, lifecycle } = data;

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && lifecycle === "complete") {

            if (data.step.lastStep === true) {
                tourGuideAction("addNewPaymentMethod", "false", () => {
                    userDetailService.emitReload(true);
                });
            }
            else {
                setAutoRun(false);
            }
        }
    };

    useEffect(() => {
        const subscription1 = addNewPaymentMethodService.reload().subscribe((value) => {
            if (value && run) {
                setAutoRun(run);
            }
        })

        const subscription2 = addNewPaymentMethodStepTwoService.reload().subscribe((value) => {
            if (value && run) {
                setSteps([
                    {
                        target: '.add-new-payment-method-form',
                        title: 'Add New Payment Method Screen',
                        content: (<p>Please note that every mandatory field must be filled (marked with a red asterisk <span className="tour-red">*</span>). Therefore, please complete the information by filling out the empty fields.</p>),
                        disableBeacon: true,
                        lastStep: true
                    },
                    {
                        target: '.add-new-payment-method-form-fake',
                        title: 'Fake step',
                        content: 'Fake step to show skip button',
                        disableBeacon: true,
                        lastStep: false
                    }
                ]);

                setAutoRun(run);
            }
        })

        return () => {
            subscription1.unsubscribe();
            subscription2.unsubscribe();
        } 
    }, [run])

    return (
        <div className="tour-guide-add-new-payment-method">
            <Joyride
                callback={handleJoyrideCallback}
                run={autoRun}
                steps={steps}
                styles={customStyles}
                floaterProps={{ disableAnimation: true }}
                showSkipButton={true}
                locale={{ back: 'Back', close: 'Close', last: 'OK', next: 'Next', skip: 'Skip' }}
                continuous={true} />
        </div>
    )
}

export default AddNewPaymentMethodTourGuide
