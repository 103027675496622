import * as actionTypes from './actionTypes'
import { buttonPromptType, promptType } from '../../shared/promptType'

export const promptShow = (
  title,
  content,
  callback,
  btnType = buttonPromptType.CONTINUECANCEL,
  type = promptType.PRIMARY,
  childComponent = null,
  cancelCallback = null
) => {
  return {
    type: actionTypes.PROMPT_SHOW,
    titlePrompt: title,
    contentPrompt: content,
    callbackPrompt: callback,
    buttonPromptType: btnType,
    promptType: type,
    childComponent: childComponent,
    cancelCallback: cancelCallback,
  }
}

export const promptHide = () => {
  return {
    type: actionTypes.PROMPT_HIDE,
  }
}

export const promptActionOk = () => {
  return {
    type: actionTypes.PROMPT_ACTION_OK,
  }
}

export const promptActionCancel = () => {
  return {
    type: actionTypes.PROMPT_ACTION_CANCEL,
  }
}
