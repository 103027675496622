import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import validator from 'validator'
import update from 'immutability-helper'

import './ForgotPassword.scss'
import InputField from '../../../components/Form/InputField/InputField'
import branding from '../../../shared/branding'
import { ForgotPasswordAPI } from '../../../api/User/ForgotPassword'
import FormHandler from '../../../factory/FormHandler'
import toastCenter, { toastMessageType } from '../../../shared/toastCenter'
import Spinner from '../../../components/Loader/Spinner/Spinner'
import LogoLogin from '../Logo.png'
import hero1 from '../hero.jpg'
import AppApiLoader from '../../../components/Loader/AppApiLoader/AppApiLoader'

const ForgotPassword = () => {
  const defaultValueInput = {
    value: '',
    isEmpty: false,
    isInvalid: false,
  }

  const [email, setEmail] = useState(defaultValueInput)
  const [isSendedEmail, setIsSendedEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendEmail()
    }
  }

  const handleSendEmail = () => {
    if (validator.isEmpty(email.value) || !validator.isEmail(email.value)) {
      setEmail(
        update(email, {
          isEmpty: { $set: validator.isEmpty(email.value) },
          isInvalid: {
            $set:
              !validator.isEmpty(email.value) &&
              !validator.isEmail(email.value),
          },
        })
      )
    } else {
      const data = new FormHandler()
      data.append('email', email.value)

      setIsLoading(true)

      const onNext = (response) => {
        if (response.success) {
          setIsSendedEmail(true)
        } else {
          toastCenter.message(
            'Not Found',
            response.messages[0],
            toastMessageType.ERROR
          )
        }
      }
      const onComplete = () => {
        setIsLoading(false)
      }
      const onError = () => {
        toastCenter.messageServerError()
      }

      const forgotPasswordApi = new ForgotPasswordAPI()
      forgotPasswordApi.subscribe(data.all(), onNext, onComplete, onError)
    }
  }

  const onChange = (e) => {
    setEmail(
      update(email, {
        value: { $set: e.target.value },
        isEmpty: { $set: validator.isEmpty(e.target.value) },
        isInvalid: {
          $set:
            !validator.isEmpty(e.target.value) &&
            !validator.isEmail(e.target.value),
        },
      })
    )
  }

  setTimeout(() => {
    setPageLoading(false)
  }, 2000)

  return (
    <div className="forgot-password-wrapper h-100">
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <Helmet>
        <title>{branding.NAME} - Login</title>
      </Helmet>

      {pageLoading ? (
        <AppApiLoader isLoading={true} />
      ) : (
        <>
          {isLoading && <Spinner />}

          <div className="header">
            <div className="container-fluid container-custom position-relative h-100">
              <div className="row">
                <div className="col-6 col-sm-6 pr-5">
                  <div className="d-flex">
                    <div className="flex-fill logo d-flex align-items-center">
                      <a
                        href={branding.DOMAIN}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={LogoLogin} className="logo" alt="" />
                      </a>
                    </div>
                  </div>
                </div>

                <div className="col-6 col-sm-6">&nbsp;</div>
              </div>
            </div>
          </div>

          <div className="container-fluid position-relative">
            <div className="row d-flex h-100 align-items-end z-1 position-relative flex-row justify-content-end justify-content-md-start">
              <div className="col-12 order-2 order-sm-1 col-sm-6  section-content">
                <div
                  className={`form-forgot-password fade ${
                    isSendedEmail ? 'hide invisible' : 'show visible'
                  }`}
                >
                  <h1>Reset your password</h1>
                  <p>
                    Enter your account's email address and we'll send you an
                    email to reset the password.
                  </p>

                  <div className="custom-form-inline">
                    <InputField
                      placeholder="Enter Email Address"
                      type="email"
                      name="username"
                      id="email"
                      autoComplete="username"
                      isRequired
                      onKeyDown={handleKeyDown}
                      isInvalid={email.isInvalid}
                      isEmpty={email.isEmpty}
                      onChange={onChange}
                      value={email.value}
                    />

                    <button
                      type="button"
                      className="btn btn-lg btn-block rounded-pill btn-send-email"
                      onClick={handleSendEmail}
                    >
                      <span>Submit</span>{' '}
                      <img
                        alt="get started"
                        src="data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='15px' height='9px' viewBox='0 0 15 9' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Earrow%3C/title%3E%3Cdefs%3E%3Cpolygon id='path-1' points='14.8041175 8.70307812 18.0981411 12.0014446 5 12.0014446 5 13.0005556 18.0981411 13.0005556 14.8041175 16.297922 15.5056966 17 20 12.4994999 15.506696 8'%3E%3C/polygon%3E%3C/defs%3E%3Cg id='Symbols' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Button-/-Inline' transform='translate(-97.000000, -5.000000)'%3E%3Cg id='Icon-/-Arrow' transform='translate(92.000000, -3.000000)'%3E%3Cmask id='mask-2' fill='white'%3E%3Cuse xlink:href='%23path-1'%3E%3C/use%3E%3C/mask%3E%3Cuse id='Icon' fill='%23181818' xlink:href='%23path-1'%3E%3C/use%3E%3Cg id='Group' mask='url(%23mask-2)' fill='%2360269E'%3E%3Cg id='Color'%3E%3Crect x='0' y='0' width='25' height='25'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E"
                      />
                    </button>
                  </div>
                </div>

                <div
                  className={`success-submit fade ${
                    isSendedEmail ? 'show visible' : 'hide invisible'
                  }`}
                >
                  <h1>
                    Great, check
                    <br />
                    your inbox
                  </h1>
                  <p className="desc">
                    An email has been sent to{' '}
                    <span className="font-weight-bold">{email.value}.</span>{' '}
                    Click the link in the email to reset your password.
                  </p>
                  <p className="mb-0">
                    Didn't get the email?{' '}
                    <button className="btn btn-link" onClick={handleSendEmail}>
                      Resend
                    </button>
                  </p>
                </div>
              </div>

              <div className="col-6 order-1 order-sm-2 col-sm-6 section-img px-0">
                <img src={hero1} alt="" />
              </div>
            </div>
          </div>

          <footer className="footer py-3">
            <div className="container-fluid container-custom">
              <div className="row">
                <div className="col-lg-6 col-sm-12">
                  <div className="d-flex align-items-center copyright">
                    <span>
                      Copyright © 2022 <i>Snap</i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </>
      )}
    </div>
  )
}

export default ForgotPassword
