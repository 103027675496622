import { Observable } from 'rxjs';
import AxiosSubscriber from '../../factory/AxiosSubscriber';
// import sampleService from './json/sample/userdetail.json';

export class UserDetailAPI {
  constructor() {
    this.subscription = null;
  }

  subscribe(next, complete, error, token = null) {
    const userToken = token === null ? localStorage.getItem("token") : token;
    const httpConfig = {
      url: '/profile',
      method: 'get',
      headers: {
        'Authorization': `Bearer ${userToken}`
      }
    };

    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig);
    });

    // let observable$ = new Observable.create((observer) => {
    //   observer.next(sampleService);
    //   observer.complete();
    // });

    this.subscription = observable$.subscribe({
      next: next,
      complete: complete,
      error: error
    });
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe();
    }
  }
}