import React from 'react'

export const ShapeContext = React.createContext({
  user: null,
  listMentions: [],
  isLoadAnnotation: true,
  isShapeShowed: true,
  onIsShapeShowed: null,
  selectedAttrId: '',
  onSelectedShape: null,
  canvasObjects: [],
  updateCanvasObjects: null,
  isReadOnly: false,
  isNewVersionCanvas: false,
  groupCount: 1,
  clearActiveDrawingArea: null,
  clearUnsavedTopic: null,
})
