import React from 'react';
import { connect } from 'react-redux';

import './BackDrop.scss';

const BackDrop = ({ backdrop }) => {
  return (
    <div className={`backdrop-wrapper ${backdrop && backdrop.show ? 'active' : ''}`}></div>
  )
}

const mapStateToProps = ({ backdrop }) => {
  return {
    backdrop
  };
};


export default connect(mapStateToProps, null)(BackDrop);