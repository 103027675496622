import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  isPromptShow: false,
  label: '',
  promptCallback:  { 
    okAction: null,
    cancelAction: null 
  }
};

const promptReasonShow = (state, action) => {
  const callback = { 
    okAction: action.callbackOkActionPrompt,
    cancelAction: action.callbackCancelActionPrompt 
  };

  return updateObject(state, {
    isPromptShow: true,
    label: action.label,
    promptCallback: callback
  });
};

const promptReasonHide = (state) => {
  return updateObject(state, {
    isPromptShow: false,
    promptCallback: { 
      okAction: null,
      cancelAction: null 
    }
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.PROMPT_REASON_SHOW:
      return promptReasonShow(state, action);
    case actionTypes.PROMPT_REASON_HIDE:
      return promptReasonHide(state);
    default:
      return state;
  }
};

export default reducer;