import React from 'react'

import './Downloading.scss'
import Spinner from '../Loader/Spinner'

function Downloading() {
  return (
    <div className="loader-downloading">
      <Spinner />
    </div>
  )
}

export default Downloading
