import React, { useState, useEffect, useContext } from 'react'
import { Can, AbilityContext } from '../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../shared/ability'
import { connect } from 'react-redux'

import DashboardTourGuide from './DashboardTourGuide'
import UserMenuTourGuide from './UserMenuTourGuide'
import ManageProfileTourGuide from './ManageProfileTourGuide'
import ManageInvoiceTourGuide from './ManageInvoiceTourGuide'
import TeamMemberTourGuide from './TeamMemberTourGuide'
import GeneralSettingsTourGuide from './GeneralSettingsTourGuide'
import CreateNewOrderTourGuide from './CreateNewOrderTourGuide'
import CreateTeamMemberTourGuide from './CreateTeamMemberTourGuide'
import ManagePaymentTourGuide from './ManagePaymentTourGuide'
import AddNewPaymentMethodTourGuide from './AddNewPaymentMethodTourGuide'
import DeleteAccountTourGuide from './DeleteAccountTourGuide'
import AfterEditProfileTourGuide from './AfterEditProfileTourGuide'
import SelectServiceTourGuide from './SelectServiceTourGuide'

const TourGuide = ({ user }) => {
  const abilityContext = useContext(AbilityContext)

  const [dashboardTourGuide, setDashboardTourGuide] = useState(false)
  const [userMenuTourGuide, setUserMenuTourGuide] = useState(false)
  const [manageProfileTourGuide, setManageProfileTourGuide] = useState(false)
  const [manageInvoiceTourGuide, setManageInvoiceTourGuide] = useState(false)
  const [teamMemberTourGuide, setTeamMemberTourGuide] = useState(false)
  const [generalSettingsTourGuide, setGeneralSettingsTourGuide] = useState(
    false
  )
  const [createNewOrderTourGuide, setCreateNewOrderTourGuide] = useState(false)
  const [createTeamMemberTourGuide, setCreateTeamMemberTourGuide] = useState(
    false
  )
  const [managePaymentTourGuide, setManagePaymentTourGuide] = useState(false)
  const [
    addNewPaymentMethodTourGuide,
    setAddNewPaymentMethodTourGuide,
  ] = useState(false)
  const [deleteAccountTourGuide, setDeleteAccountTourGuide] = useState(false)
  const [selectServiceTourGuide, setSelectServiceTourGuide] = useState(false)

  useEffect(() => {
    if (
      abilityContext.can(abilityAction.READ, abilityComponent.TOUR_GUIDE) &&
      user &&
      user.tour &&
      user.isProfileComplete &&
      user.isPaymentComplete
    ) {
      setDashboardTourGuide(user.tour.dashboardTourGuide)
      setUserMenuTourGuide(user.tour.userMenuTourGuide)
      setManageProfileTourGuide(user.tour.manageProfileTourGuide)
      setManageInvoiceTourGuide(user.tour.manageInvoiceTourGuide)
      setTeamMemberTourGuide(user.tour.teamMemberTourGuide)
      setGeneralSettingsTourGuide(user.tour.generalSettingsTourGuide)
      setCreateNewOrderTourGuide(user.tour.createNewOrderTourGuide)
      setCreateTeamMemberTourGuide(user.tour.createTeamMemberTourGuide)
      setManagePaymentTourGuide(user.tour.managePaymentTourGuide)
      setAddNewPaymentMethodTourGuide(user.tour.addNewPaymentMethodTourGuide)
      setDeleteAccountTourGuide(user.tour.deleteAccountTourGuide)
      setSelectServiceTourGuide(user.tour.createNewOrderTourGuide)
    }
  }, [user, abilityContext])

  return (
    <div>
      <Can I={abilityAction.READ} a={abilityComponent.TOUR_GUIDE}>
        <DashboardTourGuide run={dashboardTourGuide} />
        <UserMenuTourGuide run={userMenuTourGuide} />
        <ManageProfileTourGuide run={manageProfileTourGuide} />
        <ManageInvoiceTourGuide run={manageInvoiceTourGuide} />
        <TeamMemberTourGuide run={teamMemberTourGuide} />
        <GeneralSettingsTourGuide run={generalSettingsTourGuide} />
        <CreateNewOrderTourGuide run={createNewOrderTourGuide} />
        <CreateTeamMemberTourGuide run={createTeamMemberTourGuide} />
        <ManagePaymentTourGuide run={managePaymentTourGuide} />
        <AddNewPaymentMethodTourGuide run={addNewPaymentMethodTourGuide} />
        <DeleteAccountTourGuide run={deleteAccountTourGuide} />
        <AfterEditProfileTourGuide user={user} />
        <SelectServiceTourGuide run={selectServiceTourGuide} />
      </Can>
    </div>
  )
}

const mapStateToProps = ({ user }) => {
  return { user }
}

export default connect(mapStateToProps)(TourGuide)
