import React, { useState } from 'react'
import * as Icon from 'react-feather'

import './widthEditable.scss'

export const withEditable = (WrappedComponent) => {
  return React.forwardRef((props, ref) => {
    const {
      isEditable,
      onRevert,
      onSave,
      defaultValue,
      ...formControlProp
    } = props
    const [isShowAction, setIsShowAction] = useState(false)

    const handleFocus = () => {
      setIsShowAction(true)
    }

    const handleSave = () => {
      if (typeof onSave === 'function') {
        if (props.value) {
          if (typeof props.value === 'object') {
            onSave()
          } else if (
            typeof props.value === 'string' ||
            typeof props.value === 'number' ||
            typeof props.value === 'boolean'
          ) {
            onSave(props.value)
          }
        } else if (props.editorHtml) {
          onSave(props.editorHtml)
        } else if (props.selectedValue) {
          onSave(props.selectedValue.value)
        } else if (
          props.selectedValues &&
          typeof props.selectedValues === 'object' &&
          props.selectedValues.length > 0
        ) {
          onSave(props.selectedValues.maps((x) => x.value))
        }
      }
      setIsShowAction(false)
    }

    const handleRevert = () => {
      if (typeof onRevert === 'function' && defaultValue) {
        onRevert(defaultValue)
      }

      setIsShowAction(false)
    }

    return (
      <div
        className={
          props.wrapperClass
            ? `wrapper-form-group ${props.wrapperClass}`
            : `wrapper-form-group`
        }
      >
        <WrappedComponent
          ref={ref}
          onFocus={handleFocus}
          {...formControlProp}
        />
        {isEditable && isShowAction && (
          <div className="d-flex flex-row mt-2 mb-2 justify-content-end">
            <button
              type="button"
              className="btn btn-darkprimary btn-sm mr-2"
              onClick={handleSave}
            >
              <Icon.Check width={12} height={12} />
            </button>
            <button
              type="button"
              className="btn btn-secondary btn-sm"
              onClick={handleRevert}
            >
              <Icon.X width={12} height={12} />
            </button>
          </div>
        )}
      </div>
    )
  })
}
