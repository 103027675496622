import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { deleteAccountTourGuideService } from '../../services/tourGuideService/deleteAccountTourGuideService';
import { tourGuideAction } from '../../shared/tourGuideAction';
import { userDetailService } from '../../services/userDetailService/userDetailService';

function DeleteAccountTourGuide({ run }) {
    const [autoRun, setAutoRun] = useState(false);
    const [steps] = useState([
        {
            target: '.account-deleteion-wrapper',
            title: 'Account Deletion Screen',
            content: "You must fill out the reason and password field to proceed with Account Deletion. Please be aware that once you have deleted your Account, it cannot be retrieved after 30 days. You cannot delete your Account if you still have active orders.",
            disableBeacon: true,
            lastStep: true
        }
    ]);

    const customStyles = {
        overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.8)",
            zIndex: 999999
        },
        spotlight: {
            zIndex: 999999
        },
        buttonClose: {
            display: 'none',
        },
        floater: {
            tooltip: {
                zIndex: 999999
            }
        }
    };

    const handleJoyrideCallback = data => {
        const { status, lifecycle } = data;

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && lifecycle === "complete") {
            tourGuideAction("deleteAccount", "false", () => {
                userDetailService.emitReload(true);
            });
        }
    };

    useEffect(() => {
        const subscription = deleteAccountTourGuideService.reload().subscribe((value) => {
            if (value && run) {
                setAutoRun(run);
            }
        });

        return () => {
            subscription.unsubscribe();
        }
    }, [run])

    return (
        <div className="tour-guide-account-deletion">
            <Joyride
                callback={handleJoyrideCallback}
                run={autoRun}
                steps={steps}
                styles={customStyles}
                floaterProps={{disableAnimation: true}}
                showSkipButton={true}
                locale={{ back: 'Back', close: 'Close', last: 'Close', next: 'Next', skip: 'Skip' }}
                continuous={true} />
        </div>
    )
}

export default DeleteAccountTourGuide
