import React, { useState, useEffect } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { teamMemberTourGuideService } from '../../services/tourGuideService/teamMemberTourGuideService';
import { tourGuideAction } from '../../shared/tourGuideAction';
import { userDetailService } from '../../services/userDetailService/userDetailService';
import { dummyTeamMemberTourGuideService } from '../../services/tourGuideService/dummyTourGuideService';

function TeamMemberTourGuide({ run }) {
    const [autoRun, setAutoRun] = useState(false);
    const [steps] = useState([
        {
            target: '.add-team-member',
            title:'Add Team Member button',
            content: 'The button will navigate you to the Add Team Member screen, complete the form to add a new team member.',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            spotlightPadding: 0,
            styles: {
                spotlight: {
                    borderRadius:'5px'
                }
            }
        },
        {
            target: '.table-listing-wrapper',
            title: 'Team Member List section',
            content: 'The section will show you the list of added team members. You can edit, suspend or delete a team member from this list.',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            spotlightPadding: 0,
            styles: {
                spotlight: {
                    borderRadius:'10px'
                }
            }
        },
        {
            target: '.table-pagination',
            title: 'Team Member page navigation ',
            content: 'If you have many Team Members they will be listed on multiple pages, move between pages by clicking the page number or clicking the next, previous, last-page, or first-page button. You can also change the number of displayed rows per page.',
            disableBeacon: true,
            type: 'hover',
            isFixed: true,
            spotlightPadding: 0,
            lastStep: true
        }
    ]);

    const customStyles = {
        overlay: {
            position: 'fixed',
            backgroundColor: "rgba(0, 0, 0, 0.8)"
        },
        buttonClose: {
            display: 'none',
        },
    };

    const handleJoyrideCallback = data => {
        const { status, lifecycle } = data;

        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) && lifecycle === "complete") {
            tourGuideAction("teamMember", "false", () => {
                userDetailService.emitReload(true);
                dummyTeamMemberTourGuideService.emitReload(false);
            });
        }
    };

    useEffect(() => {
        const subscription = teamMemberTourGuideService.reload().subscribe((value) => {
            if (value) {
                setAutoRun(run);

                if(run) {
                    dummyTeamMemberTourGuideService.emitReload(true);
                }                
            }
        });

        return () => {
            subscription.unsubscribe();
        }
    }, [run])

    return (
        <div className="tour-guide-team-member">
            <Joyride
                callback={handleJoyrideCallback}
                run={autoRun}
                steps={steps}
                styles={customStyles}
                floaterProps={{ disableAnimation: true }}
                showSkipButton={true}
                locale={{ back: 'Back', close: 'Close', last: 'Finish', next: 'Next', skip: 'Skip' }}
                continuous={true} />
        </div>
    )
}

export default TeamMemberTourGuide
