import React, { useState, useEffect } from 'react'

import Logo from '../Logo/Logo'
import Modal from '../Modal/Modal'
import { startTourVideoService } from '../../services/tourGuideService/tourVideo'

const VideoTour = ({ videoUrl }) => {
  const [showModal, setShowModal] = useState(false)

  const videoStyle = {
    width: '100%',
    height: 'auto',
  }

  const handleClose = () => {
    setShowModal(false)
  }

  useEffect(() => {
    const subscription = startTourVideoService.reload().subscribe((value) => {
      if (value) {
        setShowModal(value)
      }
    })

    return () => {
      subscription.unsubscribe()
    }
  }, [])

  return (
    <Modal
      show={showModal}
      onHideModal={handleClose}
      isForceShow={true}
      modalSize="modal-xl"
      useClose={true}
    >
      <div className="d-flex flex-column">
        <div className="mb-3">
          <Logo width={120} />
        </div>
        <div className="">
          <video controls id="uploaded-video" style={{ ...videoStyle }}>
            <source src={videoUrl} id="uploaded-video-src" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
    </Modal>
  )
}

export default VideoTour
