import React from 'react'

const SvgEdge = (props) => (
  <svg width={48} height={48} viewBox="0 0 280 280" {...props}>
    <defs>
      <radialGradient
        id="edge_svg__b"
        cx={161.83}
        cy={68.91}
        r={95.38}
        gradientTransform="matrix(1 0 0 -.95 0 248.84)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.72} stopOpacity={0} />
        <stop offset={0.95} stopOpacity={0.53} />
        <stop offset={1} />
      </radialGradient>
      <radialGradient
        id="edge_svg__d"
        cx={-340.29}
        cy={62.99}
        r={143.24}
        gradientTransform="matrix(.15 -.99 -.8 -.12 176.64 -125.4)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0.76} stopOpacity={0} />
        <stop offset={0.95} stopOpacity={0.5} />
        <stop offset={1} />
      </radialGradient>
      <radialGradient
        id="edge_svg__e"
        cx={113.37}
        cy={570.21}
        r={202.43}
        gradientTransform="matrix(-.04 1 2.13 .08 -1179.54 -106.69)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#35c1f1" />
        <stop offset={0.11} stopColor="#34c1ed" />
        <stop offset={0.23} stopColor="#2fc2df" />
        <stop offset={0.31} stopColor="#2bc3d2" />
        <stop offset={0.67} stopColor="#36c752" />
      </radialGradient>
      <radialGradient
        id="edge_svg__f"
        cx={376.52}
        cy={567.97}
        r={97.34}
        gradientTransform="matrix(.28 .96 .78 -.23 -303.76 -148.5)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#66eb6e" />
        <stop offset={1} stopColor="#66eb6e" stopOpacity={0} />
      </radialGradient>
      <linearGradient
        id="edge_svg__a"
        x1={63.33}
        y1={84.03}
        x2={241.67}
        y2={84.03}
        gradientTransform="matrix(1 0 0 -1 0 266)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#0c59a4" />
        <stop offset={1} stopColor="#114a8b" />
      </linearGradient>
      <linearGradient
        id="edge_svg__c"
        x1={157.35}
        y1={161.39}
        x2={45.96}
        y2={40.06}
        gradientTransform="matrix(1 0 0 -1 0 266)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset={0} stopColor="#1b9de2" />
        <stop offset={0.16} stopColor="#1595df" />
        <stop offset={0.67} stopColor="#0680d7" />
        <stop offset={1} stopColor="#0078d4" />
      </linearGradient>
    </defs>
    <path
      d="M235.68 195.46a93.73 93.73 0 01-10.54 4.71 101.87 101.87 0 01-35.9 6.46c-47.32 0-88.54-32.55-88.54-74.32A31.48 31.48 0 01117.13 105c-42.8 1.8-53.8 46.4-53.8 72.53 0 73.88 68.09 81.37 82.76 81.37 7.91 0 19.84-2.3 27-4.56l1.31-.44a128.34 128.34 0 0066.6-52.8 4 4 0 00-5.32-5.64z"
      transform="translate(-4.63 -4.92)"
      fill="url(#edge_svg__a)"
    />
    <path
      d="M235.68 195.46a93.73 93.73 0 01-10.54 4.71 101.87 101.87 0 01-35.9 6.46c-47.32 0-88.54-32.55-88.54-74.32A31.48 31.48 0 01117.13 105c-42.8 1.8-53.8 46.4-53.8 72.53 0 73.88 68.09 81.37 82.76 81.37 7.91 0 19.84-2.3 27-4.56l1.31-.44a128.34 128.34 0 0066.6-52.8 4 4 0 00-5.32-5.64z"
      transform="translate(-4.63 -4.92)"
      style={{
        isolation: 'isolate',
      }}
      opacity={0.35}
      fill="url(#edge_svg__b)"
    />
    <path
      d="M110.34 246.34A79.2 79.2 0 0187.6 225a80.72 80.72 0 0129.53-120c3.12-1.47 8.45-4.13 15.54-4a32.35 32.35 0 0125.69 13 31.88 31.88 0 016.36 18.66c0-.21 24.46-79.6-80-79.6-43.9 0-80 41.66-80 78.21a130.15 130.15 0 0012.11 56 128 128 0 00156.38 67.11 75.55 75.55 0 01-62.78-8z"
      transform="translate(-4.63 -4.92)"
      fill="url(#edge_svg__c)"
    />
    <path
      d="M110.34 246.34A79.2 79.2 0 0187.6 225a80.72 80.72 0 0129.53-120c3.12-1.47 8.45-4.13 15.54-4a32.35 32.35 0 0125.69 13 31.88 31.88 0 016.36 18.66c0-.21 24.46-79.6-80-79.6-43.9 0-80 41.66-80 78.21a130.15 130.15 0 0012.11 56 128 128 0 00156.38 67.11 75.55 75.55 0 01-62.78-8z"
      transform="translate(-4.63 -4.92)"
      style={{
        isolation: 'isolate',
      }}
      opacity={0.41}
      fill="url(#edge_svg__d)"
    />
    <path
      d="M156.94 153.78c-.81 1.05-3.3 2.5-3.3 5.66 0 2.61 1.7 5.12 4.72 7.23 14.38 10 41.49 8.68 41.56 8.68a59.56 59.56 0 0030.27-8.35 61.38 61.38 0 0030.43-52.88c.26-22.41-8-37.31-11.34-43.91-21.19-41.45-66.93-65.29-116.67-65.29a128 128 0 00-128 126.2c.48-36.54 36.8-66.05 80-66.05 3.5 0 23.46.34 42 10.07 16.34 8.58 24.9 18.94 30.85 29.21 6.18 10.67 7.28 24.15 7.28 29.52s-2.74 13.33-7.8 19.91z"
      transform="translate(-4.63 -4.92)"
      fill="url(#edge_svg__e)"
    />
    <path
      d="M156.94 153.78c-.81 1.05-3.3 2.5-3.3 5.66 0 2.61 1.7 5.12 4.72 7.23 14.38 10 41.49 8.68 41.56 8.68a59.56 59.56 0 0030.27-8.35 61.38 61.38 0 0030.43-52.88c.26-22.41-8-37.31-11.34-43.91-21.19-41.45-66.93-65.29-116.67-65.29a128 128 0 00-128 126.2c.48-36.54 36.8-66.05 80-66.05 3.5 0 23.46.34 42 10.07 16.34 8.58 24.9 18.94 30.85 29.21 6.18 10.67 7.28 24.15 7.28 29.52s-2.74 13.33-7.8 19.91z"
      transform="translate(-4.63 -4.92)"
      fill="url(#edge_svg__f)"
    />
  </svg>
)

export default SvgEdge
