import { Subject } from 'rxjs'

const subject = new Subject()
const subjectStart = new Subject()

export const restartTourVideoService = {
  emitReload: (val) => subject.next(val),
  reload: () => subject.asObservable(),
}

export const startTourVideoService = {
  emitReload: (val) => subjectStart.next(val),
  reload: () => subjectStart.asObservable(),
}
