import React, { useRef, useContext, useEffect } from 'react'
import { shapeClassname, attrsShapes } from '../shared/constants'
import { hexToRgb } from '../shared/utils'
import { ShapeContext } from '../context/shapeContext'
// import { marginPage } from '../shared/utils';

const withMouseEvent = (WrappedComponent) => {
  return ({ attrs, scale = 1 }) => {
    const wrapperRef = useRef()

    const shapeContext = useContext(ShapeContext)
    const { isShapeShowed } = shapeContext

    useEffect(() => {
      if (wrapperRef.current.getClassName() === shapeClassname.ARROW) {
        wrapperRef.current.to({
          pointerLength: attrs.pointerLength / scale,
          pointerWidth: attrs.pointerWidth / scale,
          strokeWidth: attrs.strokeWidth / scale,
        })
      } else {
        wrapperRef.current.to({
          strokeWidth: attrs.strokeWidth / scale,
        })
      }
    }, [scale, attrs])

    useEffect(() => {
      if (isShapeShowed) {
        wrapperRef.current.show()
        wrapperRef.current.getLayer().batchDraw()
      } else {
        wrapperRef.current.hide()
        wrapperRef.current.getLayer().batchDraw()
      }
    }, [isShapeShowed])

    useEffect(() => {
      if (shapeContext.selectedAttrId !== '') {
        if (attrs.id !== shapeContext.selectedAttrId) {
          if (wrapperRef.current.getClassName() === shapeClassname.RECT) {
            wrapperRef.current.to({
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 0,
              shadowOpacity: 0,
              fill: attrsShapes.shapeFill,
            })
          } else {
            wrapperRef.current.to({
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: 0,
              shadowOpacity: 0,
            })
          }
        } else {
          if (wrapperRef.current.getClassName() === shapeClassname.RECT) {
            const fill = hexToRgb(wrapperRef.current.attrs.stroke)
            wrapperRef.current.to({
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: attrsShapes.shadowBlur,
              shadowOpacity: attrsShapes.shadowOpacity,
              fill: `rgba(${fill.r}, ${fill.g}, ${fill.b}, 0.125)`,
            })
          } else {
            wrapperRef.current.to({
              shadowOffsetX: 0,
              shadowOffsetY: 0,
              shadowBlur: attrsShapes.shadowBlur,
              shadowOpacity: attrsShapes.shadowOpacity,
            })
          }
        }
      }
    }, [shapeContext.selectedAttrId, attrs])

    const handleMouseDown = (e) => {
      // only left mouse button
      if (e.evt.button !== 0) return

      if (typeof shapeContext.onSelectedShape === 'function') {
        shapeContext.onSelectedShape(e.currentTarget.attrs.id)
      }

      e.evt.stopPropagation()
      e.evt.preventDefault()
    }

    const handleMouseEnter = (e) => {
      // only left mouse button
      if (e.evt.button !== 0) return

      if (attrs.id !== shapeContext.selectedAttrId) {
        if (wrapperRef.current.getClassName() === shapeClassname.RECT) {
          const fill = hexToRgb(wrapperRef.current.attrs.stroke)
          wrapperRef.current.to({
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowBlur: attrsShapes.shadowBlur,
            shadowOpacity: attrsShapes.shadowOpacity,
            fill: `rgba(${fill.r}, ${fill.g}, ${fill.b}, 0.125)`,
          })
        } else {
          wrapperRef.current.to({
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowBlur: attrsShapes.shadowBlur,
            shadowOpacity: attrsShapes.shadowOpacity,
          })
        }
      }

      setCursorByElement(e.evt.currentTarget, 'pointer')

      e.evt.stopPropagation()
      e.evt.preventDefault()
    }

    const handleMouseLeave = (e) => {
      // only left mouse button
      if (e.evt.button !== 0) return

      if (attrs.id !== shapeContext.selectedAttrId) {
        if (wrapperRef.current.getClassName() === shapeClassname.RECT) {
          wrapperRef.current.to({
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowBlur: 0,
            shadowOpacity: 0,
            fill: 'rgba(255, 255, 255, 0)',
          })
        } else {
          wrapperRef.current.to({
            shadowOffsetX: 0,
            shadowOffsetY: 0,
            shadowBlur: 0,
            shadowOpacity: 0,
          })
        }
      }

      setCursorByElement(e.evt.currentTarget, 'default')

      e.evt.stopPropagation()
      e.evt.preventDefault()
    }

    const setCursorByElement = (elem, cursorStyle) => {
      if (elem) {
        if (elem.style) elem.style.cursor = cursorStyle
      }
    }

    return (
      <WrappedComponent
        ref={wrapperRef}
        attrs={{
          ...attrs,
          onMouseDown: handleMouseDown,
          onMouseEnter: handleMouseEnter,
          onMouseLeave: handleMouseLeave,
        }}
      />
    )
  }
}

export default withMouseEvent
