import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

// deprecated
const initialState = {
  search: '',
  result: [],
}

const quicSeach = (state, action) => {
  return updateObject(state, {
    search: action.search,
  })
}

const quicSeachClear = (state) => {
  return updateObject(state, {
    search: '',
    result: [],
  })
}

const quicSeachResult = (state, action) => {
  return updateObject(state, {
    result: action.result,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.QUICK_SEARCH:
      return quicSeach(state, action)
    case actionTypes.QUICK_SEARCH_CLEAR:
      return quicSeachClear(state)
    case actionTypes.QUICK_SEARCH_RESULT:
      return quicSeachResult(state, action)
    default:
      return state
  }
}

export default reducer
