import React from 'react'
import { connect } from 'react-redux'
import { handleLogo } from '../../shared/utility'

function Logo({ user }) {
  return <div style={{ maxWidth: '75%' }}>{handleLogo(user)}</div>
}

const mapStateToProps = ({ user }) => {
  return { user }
}

export default connect(mapStateToProps, null)(Logo)
