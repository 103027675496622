import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  isRedirectPath: false,
  targetRedirectPath: ''
};

const routeAccessPath = (state, action) => {
  return updateObject(state, {
    isRedirectPath: true,
    targetRedirectPath: action.path
  });
};

const routeRemoveRedirect = (state, action) => {
  return updateObject(state, {
    isRedirectPath: false,
    targetRedirectPath: ''
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ROUTE_PATH_REDIRECT:
      return routeAccessPath(state, action);
    case actionTypes.ROUTE_HAS_REDIRECTED:
      return routeRemoveRedirect(state, action);
    default:
      return state;
  }
};

export default reducer;