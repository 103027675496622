import { addMinutes } from 'date-fns'
import { IntervalWorkingTimeReminderAPI } from '../api/DesignOrder/IntervalWorkingTimeReminder'

export const addLocalSchedule = (orderId, cb) => {
  let workingTimeWarningSchedules = {}
  if (localStorage) {
    const intervalWorkingTimeReminderAPI = new IntervalWorkingTimeReminderAPI()
    const onNext = (response) => {
      let minutes = 60
      if (response.success && response.data && response.data.intervalReminder) {
        minutes = parseInt(response.data.intervalReminder)
      }

      /*if (localStorage.getItem('working_time_warning_schedules')) {
        workingTimeWarningSchedules = JSON.parse(
          localStorage.getItem('working_time_warning_schedules')
        )
      }

      if (workingTimeWarningSchedules[orderId]) {
        const oldWarningAt = new Date(
          workingTimeWarningSchedules[orderId].warningAt
        )
        workingTimeWarningSchedules[orderId] = {
          warningAt: addMinutes(oldWarningAt, minutes),
        }
      } else {
        workingTimeWarningSchedules[orderId] = {
          warningAt: addMinutes(new Date(), minutes),
        }
      }*/

      workingTimeWarningSchedules[orderId] = {
        warningAt: addMinutes(new Date(), minutes),
      }

      localStorage.setItem(
        'working_time_warning_schedules',
        JSON.stringify(workingTimeWarningSchedules)
      )

      if (typeof cb === 'function') {
        cb()
      }
    }
    const onComplete = () => {}
    const onError = () => {}

    intervalWorkingTimeReminderAPI.subscribe(onNext, onComplete, onError)
  }
}

export const removeLocalSchedule = (orderId) => {
  let workingTimeWarningSchedules = {}
  if (localStorage && localStorage.getItem('working_time_warning_schedules')) {
    workingTimeWarningSchedules = JSON.parse(
      localStorage.getItem('working_time_warning_schedules')
    )
    if (workingTimeWarningSchedules[orderId]) {
      delete workingTimeWarningSchedules[orderId]
      localStorage.setItem(
        'working_time_warning_schedules',
        JSON.stringify(workingTimeWarningSchedules)
      )
    }
  }
}

export const getLocalSchedules = () => {
  let workingTimeWarningSchedules = null
  if (localStorage && localStorage.getItem('working_time_warning_schedules')) {
    workingTimeWarningSchedules = JSON.parse(
      localStorage.getItem('working_time_warning_schedules')
    )
  }
  return workingTimeWarningSchedules
}

export const destroyLocalSchedules = () => {
  if (localStorage) localStorage.removeItem('working_time_warning_schedules')
}
