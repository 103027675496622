import React from 'react'
import { attachmentTypeStartFromStruct } from '../shared/constants'

export const VersionContext = React.createContext({
  selectedInternalVersionIndex: 0,
  selectedPublicVersionIndex: 0,
  onSelectedInternalVersionIndex: null,
  onSelectedPublicVersionIndex: null,
  selectedVersion: null,
  onSelectedVersion: null,
  versions: [],
  onChangeVersion: null,
  onApproveVersion: null,
  onReviseVersion: null,
  orderProgress: '',
  attachmentTypeStartFrom: attachmentTypeStartFromStruct.SUPPLIED_FILE,
})
