import { Observable } from 'rxjs';
import AxiosSubscriber from '../../factory/AxiosSubscriber';
// import sample from '../../json/sample/workinghours.json';

export class WorkingHoursAPI {
  constructor() {
    this.subscription = null;
  }

  subscribe(next, complete, error) {
    const token = localStorage.getItem("token");
    const httpConfig = {
      url: '/general/business-hours',
      method: 'get',
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig);
    });

    // let observable$ = new Observable.create((observer) => {
    //   observer.next(sample);
    //   observer.complete();
    // });

    this.subscription = observable$.subscribe({
      next: next,
      complete: complete,
      error: error
    });
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe();
    }
  }
}