import { toast } from 'react-toastify'
import * as blobUtil from 'blob-util'

import { UploadMediaAPI } from '../api/Media/UploadMedia'
import { OrderDetailDeleteAttachmentAPI } from '../api/DesignOrder/OrderDetailDeleteAttachment'
import toastCenter, { toastMessageType } from './toastCenter'
import {
  ai,
  cdr,
  csv,
  doc,
  docx,
  eps,
  ico,
  indd,
  pdf,
  ppt,
  psd,
  rtf,
  svg,
  tif,
  txt,
  xls,
  xlsx,
  zip,
  defaultFile,
} from '../assets/index'
import mediaType from './mediaType'
import { uuidv4 } from './utility'
import { uploadProgressService } from '../services/uploadProgressService/uploadProgressService'
import { RequestS3TokenAPI } from '../api/Media/RequestS3Token'
import { UploadToS3API } from '../api/Media/UploadToS3'
import Resizer from 'react-image-file-resizer'

const mimeMediaType = {
  png: 'image/png',
  jpg: 'image/jpg',
  jpeg: 'image/jpeg',
  bitmap: 'image/bmp',
  gif: 'image/gif',
  tiff: 'image/tiff',
  doc: 'application/msword',
  dot: 'application/msword',
  docx:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  dotx:
    'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
  docm: 'application/vnd.ms-word.document.macroEnabled.12',
  dotm: 'application/vnd.ms-word.template.macroEnabled.12',
  csv: 'application/vnd.ms-excel',
  xls: 'application/vnd.ms-excel',
  xlt: 'application/vnd.ms-excel',
  xla: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  xltx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
  xlsm: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  xltm: 'application/vnd.ms-excel.template.macroEnabled.12',
  xlam: 'application/vnd.ms-excel.addin.macroEnabled.12',
  xlsb: 'application/vnd.ms-excel.sheet.binary.macroEnabled.12',
  ppt: 'application/vnd.ms-powerpoint',
  pot: 'application/vnd.ms-powerpoint',
  pps: 'application/vnd.ms-powerpoint',
  ppa: 'application/vnd.ms-powerpoint',
  pptx:
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  potx: 'application/vnd.openxmlformats-officedocument.presentationml.template',
  ppsx:
    'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
  ppam: 'application/vnd.ms-powerpoint.addin.macroEnabled.12',
  pptm: 'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
  potm: 'application/vnd.ms-powerpoint.template.macroEnabled.12',
  ppsm: 'application/vnd.ms-powerpoint.slideshow.macroEnabled.12',
  pdf: 'application/pdf',
  psd: 'application/octet-stream',
  psd2: 'image/vnd.adobe.photoshop',
  psd3: 'application/x-photoshop',
  psd4: 'application/photoshop',
  psd5: 'application/psd',
  psd6: 'image/psd',
  psd7: 'psd',
  txt: 'text/plain',
  ai: 'application/postscript',
  indd: 'application/octet-stream',
  indd2: 'indd',
  zip: 'application/zip',
  zip2: 'application/x-zip-compressed',
  csv2: 'text/csv',
}

const getFileExtension = (filename) => {
  return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined
}

// always allow this file by default
const fileExtAccepted = [
  'xlsx',
  'xls',
  'csv',
  'docx',
  'doc',
  'rtf',
  'psd',
  'indd',
  'eps',
]

export const acceptedFileUpload = () => {
  const fileExtOrderAccepted = [
    '.png',
    '.jpg',
    '.jpeg',
    '.gif',
    '.tif',
    '.tiff',
    '.bmp',
    '.pdf',
    '.doc',
    '.docx',
    '.rtf',
    '.csv',
    '.xls',
    '.xlsx',
    '.txt',
    '.ai',
    '.psd',
    '.indd',
    '.zip',
    '.eps',
  ]

  return fileExtOrderAccepted.sort().join(',')
}

export const avatarFileType = (file) => {
  if (
    file.type === '' &&
    fileExtAccepted.includes(getFileExtension(file.name))
  ) {
    return false
  }

  switch (file.type) {
    case mimeMediaType.png:
    case mimeMediaType.jpg:
    case mimeMediaType.jpeg:
    case mimeMediaType.bitmap:
    case mimeMediaType.gif:
      return true
    default:
      return false
  }
}

export const getPreviewFile = (file) => {
  if (
    file.type === '' &&
    fileExtAccepted.includes(getFileExtension(file.name))
  ) {
    switch (getFileExtension(file.name)) {
      case 'docx':
        return docx
      case 'doc':
        return doc
      case 'ppt':
        return ppt
      case 'pptx':
        return ppt
      case 'xls':
        return xls
      case 'xlsx':
        return xlsx
      case 'rtf':
        return rtf
      case 'svg':
        return svg
      case 'tif':
        return tif
      case 'eps':
        return eps
      case 'txt':
        return txt
      case 'cdr':
        return cdr
      case 'ico':
        return ico
      case 'csv':
        return csv
      default:
        return defaultFile
    }
  }

  // force file icon not detect by mimetype
  if (getFileExtension(file.name) === 'psd') {
    return psd
  } else if (getFileExtension(file.name) === 'indd') {
    return indd
  } else if (getFileExtension(file.name) === 'ai') {
    return ai
  } else if (getFileExtension(file.name) === 'zip') {
    return zip
  }

  switch (file.type) {
    case mimeMediaType.png:
    case mimeMediaType.jpg:
    case mimeMediaType.jpeg:
    case mimeMediaType.bitmap:
    case mimeMediaType.gif:
      return { dataurl: blobUtil.blobToDataURL(file) }
    case mimeMediaType.tiff:
      return tif
    case mimeMediaType.ai:
      return ai
    case mimeMediaType.pdf:
      return pdf
    case mimeMediaType.csv:
      return csv
    case mimeMediaType.psd:
    case mimeMediaType.psd2:
    case mimeMediaType.psd3:
    case mimeMediaType.psd4:
    case mimeMediaType.psd5:
    case mimeMediaType.psd6:
      return psd
    case mimeMediaType.indd:
      return indd
    case mimeMediaType.zip:
    case mimeMediaType.zip2:
      return zip
    case mimeMediaType.docx:
      return docx
    case mimeMediaType.doc:
      return doc
    case mimeMediaType.ppt:
      return ppt
    case mimeMediaType.pptx:
      return ppt
    case mimeMediaType.xls:
      return xls
    case mimeMediaType.xlsx:
      return xlsx
    case mimeMediaType.rtf:
      return rtf
    case mimeMediaType.svg:
      return svg
    case mimeMediaType.tif:
      return tif
    case mimeMediaType.eps:
      return eps
    case mimeMediaType.txt:
      return txt
    case mimeMediaType.cdr:
      return cdr
    case mimeMediaType.ico:
      return ico
    default:
      return defaultFile
  }
}

const validateFileType = (file) => {
  if (
    file.type === '' &&
    fileExtAccepted.includes(getFileExtension(file.name))
  ) {
    return true
  }

  switch (file.type) {
    case mimeMediaType.png:
    case mimeMediaType.jpg:
    case mimeMediaType.jpeg:
    case mimeMediaType.gif:
    case mimeMediaType.tiff:
    case mimeMediaType.pdf:
    case mimeMediaType.doc:
    case mimeMediaType.docx:
    case mimeMediaType.xls:
    case mimeMediaType.xlsx:
    case mimeMediaType.csv:
    case mimeMediaType.txt:
    case mimeMediaType.bitmap:
    case mimeMediaType.rtf:
    case mimeMediaType.ai:
    case mimeMediaType.psd:
    case mimeMediaType.psd2:
    case mimeMediaType.psd3:
    case mimeMediaType.psd4:
    case mimeMediaType.psd5:
    case mimeMediaType.psd6:
    case mimeMediaType.psd7:
    case mimeMediaType.zip:
    case mimeMediaType.zip2:
    case mimeMediaType.csv2:
    case mimeMediaType.indd2:
      return true
    default:
      return false
  }
}

export const formatBytes = (bytes, decimals) => {
  if (bytes === 0) return '0 Bytes'
  let k = 1024
  let dm = decimals || 2
  let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
}

export const getAttachmentFileSize = (attachments) => {
  let totalAttachmentFileSize = 0

  if (attachments.length > 0) {
    for (const attachment of attachments) {
      if (attachment.versions && attachment.versions.length > 0) {
        for (const version of attachment.versions) {
          totalAttachmentFileSize += parseInt(version.fileSizeRaw)
        }
      }
    }
  }

  return totalAttachmentFileSize
}

export const getDeleteFileMessage = (
  totalDeletedFileSize = 0,
  event = 'before'
) => {
  let deletedFileMessage = ''

  if (totalDeletedFileSize > 0) {
    if (event === 'before') {
      deletedFileMessage = `<br/><br/><b>You will recover ${formatBytes(
        totalDeletedFileSize
      )} of your storage</b>`
    } else if (event === 'after') {
      deletedFileMessage = `<b>${formatBytes(
        totalDeletedFileSize
      )} has been recovered to your storage</b>`
    }
  }

  return deletedFileMessage
}

export const truncateFilename = (n, len) => {
  let ext = n.substring(n.lastIndexOf('.') + 1, n.length).toLowerCase()
  let filename = n.replace('.' + ext, '')
  if (filename.length <= len) {
    return n
  }
  filename = filename.substr(0, len) + (n.length > len ? '...' : '')
  return filename + '.' + ext
}

// const changeFileType = file => new Promise((resolve, reject) => {
//   const type = /[^.]+$/.exec(file.name)[0];
//   const mime = mimeMediaType[type] !== undefined ? mimeMediaType[type] : 'text/plain';
//   if (fileExtAccepted.includes(type)) {
//     const blobFile = new Blob([file], { type: mime });
//     if (blobFile.size > 0) {
//       resolve(file);
//     } else {
//       reject();
//     }
//   } else {
//     resolve(file);
//   }
// });

const changeFileType = (file) =>
  new Promise((resolve, reject) => {
    if (file.type === '') {
      const fileType = getFileExtension(file.name)
      // indd
      const blobFile = new Blob([file], {
        type: fileType ? fileType : 'text/plain',
        lastModified: file.lastModified,
      })
      blobFile.name = file.name
      resolve(blobFile)
    } else {
      resolve(file)
    }
  })

export const handleFiles = (file) =>
  new Promise((resolve, reject) => {
    // check file mime type
    if (validateFileType(file)) {
      changeFileType(file).then((f) => {
        resolve(f)
      })
    } else {
      reject(file)
    }
  })

export const deleteTemporaryFiles = (orderId) =>
  new Promise((resolve, reject) => {
    if (localStorage && localStorage.getItem('temp_uploaded_files')) {
      let filesToBeDeleted = JSON.parse(
        localStorage.getItem('temp_uploaded_files')
      )
      if (filesToBeDeleted.length > 0) {
        let removeRequest = 0
        const orderDetailDeleteFileApi = new OrderDetailDeleteAttachmentAPI()
        const toastID = toastCenter.loading(
          'Please wait. Your file is in removing...'
        )
        const onNext = (response) => {
          removeRequest += 1
          if (!response.success) {
            toast.dismiss(toastID)
            toastCenter.message(
              'Failed!',
              response.message,
              toastMessageType.WARNING
            )
          }
        }

        const onComplete = () => {
          if (removeRequest === filesToBeDeleted.length) {
            resolve(true)
            toast.dismiss(toastID)
            localStorage.removeItem('temp_uploaded_files')
          }
        }

        const onError = () => {
          toast.dismiss(toastID)
          toastCenter.messageServerError()
          reject()
        }

        for (const file of filesToBeDeleted) {
          orderDetailDeleteFileApi.subscribe(
            orderId,
            file.id,
            onNext,
            onComplete,
            onError
          )
        }
      }
    } else {
      resolve(true)
    }
  })

export const validateFiles = (files, user = null) =>
  new Promise((resolve) => {
    let acceptedFiles = []
    let rejectedFiles = []
    let uploadedFileSize = user
      ? user.storages.used + user.retail.additionalStorageUsed
      : 0
    let storageQuota = user
      ? user.storages.quota + user.retail.additionalStorageQuota
      : -1

    let isValidSize = validateFileSize(files)
    if (!isValidSize) {
      return
    }

    const checkFilesMeta = files.map(
      (file) =>
        new Promise((resolve) => {
          uploadedFileSize += file.size

          if (storageQuota < 0 || uploadedFileSize <= storageQuota) {
            handleFiles(file)
              .then((file) => {
                acceptedFiles.push(file)
                resolve(true)
              })
              .catch((file) => {
                rejectedFiles.push(file.name)
                resolve(true)
              })
          } else {
            toastCenter.message(
              'Not Enough Storage',
              'Please upgrade your membership to continue uploading more attachments.',
              toastMessageType.WARNING
            )
            resolve(true)
          }
        })
    )

    Promise.all(checkFilesMeta)
      .then(() => {
        resolve({ acceptedFiles, rejectedFiles })
      })
      .catch((err) => {
        resolve({ acceptedFiles, rejectedFiles })
      })
  })

export const doUploadMedia = (
  reffID,
  files,
  type = mediaType.ATTACHMENT,
  config = { timelineRecord: '1', attachmentRecord: '1' },
  filters = null
) =>
  new Promise((resolve, reject) => {
    let imageUploaded = 0
    let imageUploadedFailed = []
    let imageProcessed = 0
    let responseCollection = []

    if (files === undefined) {
      reject('Files is not readable.')
    }

    let isValidSize = validateFileSize(files)
    if (!isValidSize) {
      return
    }

    // const toastID = toastCenter.loading(
    //   `Please wait. Your file upload is in progress. Please don't close or refresh your browser`
    // )

    const toastID = uuidv4()
    toastCenter.uploading(toastID, files)

    const uploadFileStatus = { succeed: 0, failed: 0, total: files.length }

    const onFinishUploadState = (id, state, callback) => {
      if (state.succeed + state.failed === state.total) {
        if (callback && typeof callback === 'function') {
          callback()
        }

        if (state.failed === 0) {
          setTimeout(() => {
            toast.dismiss(id)
          }, 2000)
        }
      }
    }

    const onComplete = () => {
      onFinishUploadState(toastID, uploadFileStatus, () => {
        if (imageUploaded === files.length) {
          resolve({ responseCollection, failed: imageUploadedFailed })
        }
      })
    }

    if (files.length > 0) {
      files.forEach((file, idx) => {
        imageProcessed += 1

        handleFiles(file)
          .then((data) => {
            // Validate fileName
            var excludeFileNameFormat = /[^0-9a-zA-Z\-._ ()+]/
            if (!excludeFileNameFormat.test(data.name)) {
              const uploadMediaAPi = new UploadMediaAPI()
              const requestS3TokenAPI = new RequestS3TokenAPI()
              const uploadToS3API = new UploadToS3API()

              let dataMedia = {
                file: {
                  name: data.name,
                  type: data.type ? data.type : getFileExtension(file.name),
                  size: data.size,
                  key: null,
                  thumbnail: null,
                },
              }

              const onErrorRequestS3Url = (errMessage) => {
                uploadFileStatus.failed += 1
                uploadProgressService.emitReload({
                  idx: idx, // file index
                  toastId: toastID,
                  progress: 'fail',
                  fileName: file.name ? truncateFilename(file.name, 30) : '',
                  fileSize: null,
                  percentCompleted: null,
                  message: errMessage,
                })
              }

              const onError = (error) => {
                // count failed
                uploadFileStatus.failed += 1

                onFinishUploadState(toastID, uploadFileStatus, () => {
                  reject(error)
                })

                uploadProgressService.emitReload({
                  idx: idx, // file index
                  toastId: toastID,
                  progress: 'fail',
                  fileName: file.name ? truncateFilename(file.name, 30) : '',
                  fileSize: null,
                  percentCompleted: null,
                })

                // toastCenter.messageServerError()
                // toast.dismiss(toastID)
              }

              const onUploadProgress = (progressEvent) => {
                const current = progressEvent.loaded
                const total = progressEvent.total

                let percentCompleted = Math.floor((current / total) * 100)

                uploadProgressService.emitReload({
                  idx: idx, // file index
                  toastId: toastID,
                  progress: 'uploading',
                  fileName: file.name ? truncateFilename(file.name, 30) : '',
                  fileSize: formatBytes(progressEvent.total),
                  percentCompleted: percentCompleted,
                })
              }

              const onNextToken = (resToken) => {
                if (resToken.success && resToken.data) {
                  dataMedia.file.key = resToken.data.fields.key
                  uploadToS3API.subscribe(
                    resToken.data,
                    data,
                    onNextUploadS3,
                    () => {},
                    onError,
                    onUploadProgress
                  )
                } else {
                  onErrorRequestS3Url(
                    resToken.message ? resToken.message : 'Upload failed'
                  )
                }
              }

              const onNextUploadS3 = (_) => {
                /** Crop image */
                if (
                  avatarFileType(data) &&
                  [
                    mediaType.ATTACHMENT,
                    mediaType.ATTACHMENT_VERSION,
                    mediaType.AVATAR,
                  ].includes(type)
                ) {
                  Resizer.imageFileResizer(
                    data,
                    316,
                    240,
                    'JPEG',
                    100,
                    0,
                    (cropedFile) => {
                      const cropedFileData = {
                        name: `thumb_${cropedFile.name}`,
                        size: cropedFile.size,
                        type: cropedFile.type,
                      }

                      requestS3TokenAPI.subscribe(
                        reffID,
                        cropedFileData,
                        type,
                        (resTokenCrop) => {
                          if (resTokenCrop.success && resTokenCrop.data) {
                            uploadToS3API.subscribe(
                              resTokenCrop.data,
                              cropedFile,
                              (_) => {
                                dataMedia.file.thumbnail = {
                                  key: resTokenCrop.data.fields.key,
                                  size: cropedFile.size,
                                }
                                /** Do Upload media to api */
                                uploadMediaAPi.subscribe(
                                  reffID,
                                  type,
                                  dataMedia,
                                  config,
                                  onNext,
                                  onComplete,
                                  onError,
                                  filters,
                                  onUploadProgress
                                )
                              },
                              () => {},
                              onError,
                              onUploadProgress
                            )
                          } else {
                            onErrorRequestS3Url(
                              resTokenCrop.message
                                ? resTokenCrop.message
                                : 'Upload failed'
                            )
                          }
                        },
                        () => {},
                        onError,
                        filters
                      )
                    },
                    'file'
                  )
                } else {
                  /** Do Upload media to api */
                  uploadMediaAPi.subscribe(
                    reffID,
                    type,
                    dataMedia,
                    config,
                    onNext,
                    onComplete,
                    onError,
                    filters,
                    onUploadProgress
                  )
                }
              }

              const onNext = (response) => {
                // count everytime request is running
                imageUploaded += 1
                if (response.success) {
                  responseCollection.push(response.data)

                  // count success
                  uploadFileStatus.succeed += 1

                  uploadProgressService.emitReload({
                    idx: idx, // file index
                    toastId: toastID,
                    progress: 'done',
                    fileName: file.name ? truncateFilename(file.name, 30) : '',
                    fileSize: null,
                    percentCompleted: null,
                  })
                } else {
                  // count failed
                  uploadFileStatus.failed += 1

                  uploadProgressService.emitReload({
                    idx: idx, // file index
                    toastId: toastID,
                    progress: 'fail',
                    fileName: file.name ? truncateFilename(file.name, 30) : '',
                    fileSize: null,
                    percentCompleted: null,
                  })

                  imageUploadedFailed.push(response.message)
                }
              }

              requestS3TokenAPI.subscribe(
                reffID,
                data,
                type,
                onNextToken,
                () => {},
                onError,
                filters
              )
            } else {
              const message = `'${file.name}' is invalid file name. We only accept Alphanumericals(A-Z+a-z+0-9), space( ), dash(-), underscore(_) and dot(.) characters`
              toastCenter.message(
                'Not supported file name',
                message,
                toastMessageType.ERROR
              )

              if (imageProcessed === files.length) {
                toast.dismiss(toastID)
              }
            }
          })
          .catch((fileError) => {
            const message = `${fileError.name} is not supported to process. Please use another extension.`
            toastCenter.message(
              'Not supported file',
              message,
              toastMessageType.ERROR
            )
            if (imageProcessed === files.length) {
              toast.dismiss(toastID)
            }
          })
      })
    }
  })

/**
 * Limit upload size in byte
 */
export const limitSizeUpload = 524288000

export const validateFileSize = (files) => {
  if (files.length > 0) {
    let totalSize = 0
    files.forEach((x) => {
      totalSize = totalSize + x.size
    })
    if (totalSize > limitSizeUpload) {
      const message = `The file size is above ${formatBytes(
        limitSizeUpload
      )} max limit and cannot be uploaded. Please adjust the file size and try again.`
      toastCenter.message('File Size Limit', message, toastMessageType.ERROR)
      return false
    } else {
      return true
    }
  } else {
    toastCenter.message('No files', 'No files', toastMessageType.ERROR)
    return false
  }
}

export const base64StringToFile = (value) => {
  const base64string = value.replace(/^data:image\/[a-z]+;base64,/, '')
  const imageType = value.substring('data:'.length, value.indexOf(';base64'))
  const file = blobUtil.base64StringToBlob(base64string, imageType)

  return file
}

export const fileToBase64String = (file) =>
  new Promise((resolve, reject) => {
    blobUtil
      .blobToBase64String(file)
      .then(function (base64String) {
        resolve(`data:${file.type};base64,${base64String}`)
      })
      .catch(function (err) {
        reject(err)
      })
  })

export const defaultFileBlob = new Blob([], { type: 'image/png' })
