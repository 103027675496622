import React, { useState, useEffect, useRef } from 'react'
import * as Icon from 'react-feather'
import { Transition } from 'react-transition-group'

import './ColorPalette.scss'
import { toolboxColorPalette } from '../../shared/constants'

const duration = 300

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  visibility: 'visible',
  opacity: 0,
}

const transitionStyles = {
  entered: { opacity: 1, visibility: 'visible' },
  exited: { opacity: 0, visibility: 'hidden' },
}

const ColorPalette = ({ onChangeColor, selectedColor, isReadOnly }) => {
  const [isShowPalette, setIsShowPalette] = useState(false)

  const wrapperRef = useRef(null)

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside)
    }
  })

  const handleChangeColor = (color) => {
    onChangeColor(color)
  }

  const handleShowPalette = () => {
    if (!isReadOnly) {
      setIsShowPalette(!isShowPalette)
    }
  }

  const handleClickOutside = (e) => {
    if (
      wrapperRef.current &&
      !wrapperRef.current.contains(e.target) &&
      isShowPalette
    ) {
      setIsShowPalette(false)
    }
  }

  return (
    <div ref={wrapperRef} className="color-palette-wrapper">
      <button
        type="button"
        className="btn btn-toolbox btn-toolbox-palette"
        onClick={handleShowPalette}
        data-tippy-content="Choose color"
        disabled={isReadOnly}
      >
        <Icon.Circle
          width={18}
          height={18}
          style={{ fill: selectedColor, stroke: selectedColor }}
        />
      </button>

      <Transition in={isShowPalette} timeout={150} unmountOnExit mountOnEnter>
        {(state) => (
          <div
            className="palette-list"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <ul className="list-unstyled m-0">
              <li>
                <button
                  type="button"
                  className="btn btn-color"
                  onClick={() => handleChangeColor(toolboxColorPalette.GREEN)}
                >
                  <Icon.Circle
                    width={18}
                    height={18}
                    className={`color-green ${
                      selectedColor === toolboxColorPalette.GREEN
                        ? 'active'
                        : null
                    }`}
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="btn btn-color"
                  onClick={() => handleChangeColor(toolboxColorPalette.RED)}
                >
                  <Icon.Circle
                    width={18}
                    height={18}
                    className={`color-red ${
                      selectedColor === toolboxColorPalette.RED
                        ? 'active'
                        : null
                    }`}
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="btn btn-color"
                  onClick={() => handleChangeColor(toolboxColorPalette.BLUE)}
                >
                  <Icon.Circle
                    width={18}
                    height={18}
                    className={`color-blue ${
                      selectedColor === toolboxColorPalette.BLUE
                        ? 'active'
                        : null
                    }`}
                  />
                </button>
              </li>
            </ul>
            <ul className="list-unstyled m-0">
              <li>
                <button
                  type="button"
                  className="btn btn-color"
                  onClick={() => handleChangeColor(toolboxColorPalette.YELLOW)}
                >
                  <Icon.Circle
                    width={18}
                    height={18}
                    className={`color-yellow ${
                      selectedColor === toolboxColorPalette.YELLOW
                        ? 'active'
                        : null
                    }`}
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="btn btn-color"
                  onClick={() => handleChangeColor(toolboxColorPalette.PINK)}
                >
                  <Icon.Circle
                    width={18}
                    height={18}
                    className={`color-pink ${
                      selectedColor === toolboxColorPalette.PINK
                        ? 'active'
                        : null
                    }`}
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="btn btn-color"
                  onClick={() => handleChangeColor(toolboxColorPalette.PURPLE)}
                >
                  <Icon.Circle
                    width={18}
                    height={18}
                    className={`color-purple ${
                      selectedColor === toolboxColorPalette.PURPLE
                        ? 'active'
                        : null
                    }`}
                  />
                </button>
              </li>
            </ul>
          </div>
        )}
      </Transition>
    </div>
  )
}

export default ColorPalette
