import React, { useContext } from 'react'
import { Transition } from 'react-transition-group'
import * as Icon from 'react-feather'

import './PanelComment.scss'
import ThreadComment from './ThreadComment'
import { ShapeContext } from '../../context/shapeContext'
import { VersionContext } from '../../context/versionContext'
import { HeaderAnnotationContext } from '../../context/headerAnnotationContext'

const duration = 300

const defaultStyle = {
  transition: `all ${duration}ms ease-in-out`,
  visibility: 'visible',
  opacity: 0,
  // transform: `translateX(100%)`,
}

const transitionStyles = {
  entered: {
    opacity: 1,
    // transform: `translateX(0)`,
    // visibility: 'visible'
  },
  exited: {
    opacity: 0,
    // transform: `translateX(100%)`,
    // visibility: 'hidden'
  },
}

const PanelComment = ({
  publicCommentCount = 0,
  internalCommentCount = 0,
  isShowCommentPanel,
  onTogglePanel,
  commentThreads = [],
  onSelectedComment = null,
}) => {
  const { isLoadAnnotation, selectedAttrId } = useContext(ShapeContext)

  const { versions } = useContext(VersionContext)
  const { isShowPublicComment, isShowVersionPanel } = useContext(
    HeaderAnnotationContext
  )

  return (
    <div
      className={`panel-comment-wrapper ${
        isShowCommentPanel ? 'collapsed' : ''
      }`}
    >
      <div className="collapse-panel" onClick={onTogglePanel}>
        <span>
          {isShowCommentPanel ? (
            <Icon.ChevronsRight className="feather" />
          ) : (
            <Icon.ChevronsLeft className="feather" />
          )}
        </span>
      </div>
      <div className="nav-panel">
        <div>
          <button
            type="button"
            className={`btn ${isShowCommentPanel ? 'active' : ''}`}
          >
            <Icon.MessageCircle className="icon-header-annotation" />
            {isShowPublicComment
              ? publicCommentCount > 0 && (
                  <span className="badge badge-danger badge-count">
                    {publicCommentCount}
                  </span>
                )
              : internalCommentCount > 0 && (
                  <span className="badge badge-danger badge-count">
                    {internalCommentCount}
                  </span>
                )}
          </button>
        </div>
        <div>
          <button
            type="button"
            className={`btn ${isShowVersionPanel ? 'active' : ''}`}
          >
            <Icon.Clock className="icon-header-annotation" />
            <span className="badge badge-danger badge-count">
              {isShowPublicComment
                ? versions.public.length
                : versions.internal.length}
            </span>
          </button>
        </div>
      </div>
      <Transition
        in={isShowCommentPanel}
        timeout={150}
        unmountOnExit
        mountOnEnter
      >
        {(state) => (
          <div
            className="content"
            style={{
              ...defaultStyle,
              ...transitionStyles[state],
            }}
          >
            <div className="panel-comment-list">
              {commentThreads.length > 0 && (
                <ul>
                  {commentThreads.map((x, idx) => (
                    <ThreadComment
                      key={idx}
                      id={x.annotationAttrId}
                      isResolve={x.isResolve}
                      selectedAttrId={selectedAttrId}
                      comments={x.threads}
                      onSelectedComment={() =>
                        onSelectedComment(x.annotationAttrId)
                      }
                    />
                  ))}
                </ul>
              )}

              {!isLoadAnnotation && commentThreads.length === 0 && (
                <div className="p-3 text-center">
                  <span className="text-grey">
                    You don't have any comment yet.
                  </span>
                </div>
              )}
            </div>
          </div>
        )}
      </Transition>
    </div>
  )
}

export default React.memo(PanelComment)
