import React from 'react'
import PropTypes from 'prop-types'

const Avatar = ({ src = '', radius = 50 }) => {
  return (
    <div>
      <div
        className={`d-flex rounded-circle user-avatar-${radius} user-avatar-cover ${
          src ? '' : 'user-avatar-empty'
        }`}
        style={{ backgroundImage: `url(${src})` }}
      ></div>
    </div>
  )
}

Avatar.propTypes = {
  user: PropTypes.object,
  src: PropTypes.string,
  radius: PropTypes.number,
}

export default Avatar
