import React from 'react';

import './BrokenLink.scss';

const BrokenLink = () => {
  return (
    <div className="broken-link-wrapper">
      <div className="content d-flex flex-column justify-content-center align-items-center">
        <div className="title text-light">404</div>
        <h2 className="text-light">Something is wrong!</h2>
        <p className="text-gray">Maybe you're trying to open the wrong link or it has expired.</p>
      </div>
    </div>
  )
};

export default BrokenLink;
