import React from 'react'

import { shapeClassname } from '../../shared/constants'
import ArrowLine from '../Object/ArrowLine'
import Dot from '../Object/Dot'
import Rectangle from '../Object/Rectangle'
import Pencil from '../Object/Pencil'

const Shapes = React.memo(function ({ objectData = [], scale = 1 }) {
  let comps = []

  if (objectData.length > 0) {
    comps = objectData.map((obj, key) => {
      let shape = null

      switch (obj.className) {
        case shapeClassname.ARROW:
          shape = <ArrowLine attrs={obj.attrs} key={key} scale={scale} />
          break
        case shapeClassname.CIRCLE:
          shape = <Dot attrs={obj.attrs} key={key} scale={scale} />
          break
        case shapeClassname.RECT:
          shape = (
            <Rectangle
              attrs={obj.attrs}
              pageInit={obj.page}
              scaleInit={obj.scale}
              key={key}
              scale={scale}
            />
          )
          break
        default:
          shape = <Pencil attrs={obj.attrs} key={key} scale={scale} />
      }

      return shape
    })
  }

  return <>{comps}</>
})

export default Shapes
