import React from 'react'

import './QuickSearchResult.scss'
import QuickSearchResultItem from './QuickSearchResultItem'

const QuickSearchResult = ({
  results = [],
  showResult = false,
  onHideResult = null,
}) => {
  return (
    <div
      className={`quick-search-result-wrapper ${
        showResult ? 'show-result' : ''
      }`}
    >
      {results.length > 0 && (
        <ul>
          {results.map((item, idx) => (
            <QuickSearchResultItem key={idx} item={item} />
          ))}
        </ul>
      )}
      <div className="backdrop-search-result" onClick={onHideResult}></div>
    </div>
  )
}

export default React.memo(QuickSearchResult)
