import { Subject } from 'rxjs';

const subjectPaymentMethod = new Subject();
const subjectTeamMember = new Subject();
const subjectInvoice = new Subject();

export const dummyPaymentMethodTourGuideService = {
    emitReload: val => subjectPaymentMethod.next(val),
    reload: () => subjectPaymentMethod.asObservable()
};

export const dummyTeamMemberTourGuideService = {
    emitReload: val => subjectTeamMember.next(val),
    reload: () => subjectTeamMember.asObservable()
};

export const dummyInvoiceTourGuideService = {
    emitReload: val => subjectInvoice.next(val),
    reload: () => subjectInvoice.asObservable()
};