import * as actionTypes from './actionTypes'

export const archivedFilterOrder = (preset) => {
  return {
    type: actionTypes.ARCHIVED_PRESET_FILTER,
    preset: preset,
  }
}

export const archivedFilterOrderClear = () => {
  return {
    type: actionTypes.ARCHIVED_PRESET_FILTER_CLEAR,
  }
}
