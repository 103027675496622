import React, { useEffect } from 'react'
import * as Sentry from '@sentry/browser'
import { connect } from 'react-redux'

import './NotSupportBrowser.scss'
import * as actions from '../../store/actions/index'
import Logo from '../../components/Logo/Logo'
import SvgChrome from '../../Icons/Chrome'
import SvgEdge from '../../Icons/Edge'
import SvgFirefox from '../../Icons/Firefox'
import { socketRemoveAllListeners } from '../../socket/socket'

function NotSupportBrowser({ apiStop }) {
  useEffect(() => {
    localStorage.clear()
    socketRemoveAllListeners()

    if (process.env.NODE_ENV === 'production') {
      Sentry.captureException(navigator.userAgent)
    }
  }, [])

  useEffect(() => {
    if (typeof apiStop === 'function') {
      apiStop()
    }
  }, [apiStop])

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-6 offset-3">
          <div className="jumbotron">
            <div className="mb-3 pb-3">
              <Logo width={250} />
            </div>
            <h1>Browser Not Supported!</h1>
            <p>You're using a web browser we don't support.</p>
            <p>Try of these options to have a better experience.</p>
            <div className="browser-logo d-flex flex-row align-items-center justify-content-between pt-4">
              <div className="text-center">
                <SvgChrome />
                <a
                  href="https://www.google.com/intl/en/chrome/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3 className="mt-3">Chrome</h3>
                </a>
              </div>
              <div className="text-center">
                <SvgFirefox />
                <a
                  href="https://www.mozilla.org/en-US/firefox/new/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3 className="mt-3">Firefox</h3>
                </a>
              </div>
              <div className="text-center">
                <SvgEdge />
                <a
                  href="https://www.microsoft.com/en-us/edge"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <h3 className="mt-3">Microsoft Edge</h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    apiStop: () => dispatch(actions.apiStop()),
  }
}

export default connect(null, mapDispatchToProps)(NotSupportBrowser)
