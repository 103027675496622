import React from 'react'
import { Stage, Layer, Group } from 'react-konva'
import PropTypes from 'prop-types'

import './Canvas.scss'
import Shapes from '../Shapes/Shapes'
import { ShapeContext } from '../../context/shapeContext'
import { typeDisplayAnnotation } from '../../shared/constants'

const Canvas = ({
  onMouseDown,
  onTouchStart,
  onMouseUp,
  onTouchEnd,
  onMouseMove,
  onTouchMove,
  onContextMenu,
  objects,
  imageBoundRect = { x: 0, y: 0, width: 0, height: 0, scale: 1 },
  style = {},
  layerPdfScroll = null,
  typeDisplay = typeDisplayAnnotation.IMAGE,
}) => {
  const { scale, x, y, width, height } = imageBoundRect
  const groupWidth =
    typeDisplay === typeDisplayAnnotation.PDF ? width : width * scale
  const groupHeight =
    typeDisplay === typeDisplayAnnotation.PDF ? height : height * scale

  return (
    <ShapeContext.Consumer>
      {(value) => (
        <Stage
          width={window.innerWidth}
          height={window.innerHeight}
          style={{
            ...style,
            width: window.innerWidth,
            height: window.innerHeight,
            position: 'absolute',
            top:
              typeDisplay === typeDisplayAnnotation.PDF ? layerPdfScroll.y : 0,
            left:
              typeDisplay === typeDisplayAnnotation.PDF ? layerPdfScroll.x : 0,
          }}
          className="canvas-wrapper cursor-default"
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          onMouseUp={onMouseUp}
          onTouchEnd={onTouchEnd}
          onMouseMove={onMouseMove}
          onTouchMove={onTouchMove}
          onContextMenu={onContextMenu}
        >
          <ShapeContext.Provider value={value}>
            <Layer>
              <Group
                x={x}
                y={y}
                width={groupWidth}
                height={groupHeight}
                scale={{
                  x: scale,
                  y: scale,
                }}
              >
                <Shapes objectData={objects} scale={scale} />
              </Group>
            </Layer>
          </ShapeContext.Provider>
        </Stage>
      )}
    </ShapeContext.Consumer>
  )
}

Canvas.propTypes = {
  onMouseDown: PropTypes.func.isRequired,
  onTouchStart: PropTypes.func.isRequired,
  onMouseUp: PropTypes.func.isRequired,
  onTouchEnd: PropTypes.func.isRequired,
  onMouseMove: PropTypes.func.isRequired,
  onTouchMove: PropTypes.func.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  objects: PropTypes.array.isRequired,
  imageBoundRect: PropTypes.object.isRequired,
}

export default Canvas
