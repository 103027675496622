export default {
  CARD: 'CARD',
  PLACEHOLDER: 'PLACEHOLDER',
  DISABLED_CARD: 'DISABLED_CARD',
  ACTIVE_CARD: 'ACTIVE_CARD',
  READY_TO_GO: 'READY_TO_GO',
  QUEUE_CARD: 'QUEUE_CARD',
  DRAFT_CARD: 'DRAFT_CARD',
  NEW_ORDER: 'NEW_ORDER',
  IN_PROGRESS: 'IN_PROGRESS',
  IN_REVIEW: 'IN_REVIEW',
  ON_HOLD: 'ON_HOLD',
  APPROVED: 'APPROVED',
  COMPLETED: 'COMPLETED',
  CANCELLED: 'CANCELLED',
  GR_REVISION: 'GR_REVISION',
}
