import React, { useState, useEffect, useRef, useCallback } from 'react'
import * as Icon from 'react-feather'
import validator from 'validator'
import { connect } from 'react-redux'

import './QuickSearchArchived.scss'
import * as actions from '../../store/actions/index'
import Spinner from '../Loader/Spinner/Spinner'

let quicksearchTimer = null

const QuickSearchArchived = ({ onQuickSearchOrder }) => {
  const [isActive, setIsActive] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [isSearching, setIsSearching] = useState(false)

  const inputRef = useRef()

  const keydownHandler = useCallback((e) => {
    if (e.keyCode === 81 && e.ctrlKey) {
      inputRef.current.focus()
    }

    if (e.keyCode === 27) {
      inputRef.current.blur()
    }
  }, [])

  useEffect(() => {
    document.addEventListener('keydown', keydownHandler)

    return () => {
      document.removeEventListener('keydown', keydownHandler)
    }
  })

  const handleClick = useCallback(() => {
    if (validator.isEmpty(searchValue)) {
      setIsActive(true)
      inputRef.current.focus()
    } else {
      setSearchValue('')
      onQuickSearchOrder(null)
    }
  }, [searchValue, onQuickSearchOrder])

  const handleFocus = useCallback(() => {
    setIsActive(true)
  }, [])

  const handleBlur = useCallback(() => {
    setIsActive(false)
  }, [])

  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter') {
      e.stopPropagation()
      e.preventDefault()
      return false
    }
  }, [])

  const getSearchResultByKeyword = useCallback(
    (keyword) => {
      if (!validator.isEmpty(keyword)) {
        onQuickSearchOrder(keyword)
      } else {
        onQuickSearchOrder(null)
      }
    },
    [onQuickSearchOrder]
  )

  const handleChange = useCallback(
    (e) => {
      e.persist()

      setSearchValue(e.target.value)
      setIsSearching(true)

      if (quicksearchTimer) {
        clearTimeout(quicksearchTimer)
      }

      quicksearchTimer = setTimeout(function () {
        getSearchResultByKeyword(e.target.value)
        setIsSearching(false)
      }, 500)
    },
    [getSearchResultByKeyword]
  )

  return (
    <div className="position-relative quick-search-wrapp">
      <div
        className={`quick-search-input-wrapp ${
          isActive || !validator.isEmpty(searchValue) ? 'active' : ''
        }`}
      >
        <form>
          <input
            type="text"
            ref={inputRef}
            className=""
            value={searchValue}
            placeholder="Quick Search (CTRL+Q)"
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
          <button
            type="button"
            className="btn btn-icon-search"
            onClick={handleClick}
          >
            {validator.isEmpty(searchValue) ? (
              <Icon.Search className="feather" />
            ) : (
              <Icon.X className="feather" />
            )}
          </button>
        </form>
      </div>
      {isSearching && <Spinner />}
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    onQuickSearchOrder: (search) =>
      dispatch(actions.archivedQuickSearch(search)),
  }
}

export default connect(null, mapDispatchToProps)(QuickSearchArchived)
