import React, { useContext } from 'react'
import * as Icon from 'react-feather'

import './OrderFilter.scss'
import SelectField from '../Form/SelectField/SelectField'
import InputField from '../Form/InputField/InputField'
import DateRangePicker from '../Form/DateRangePicker/DateRangePicker'
import { OrderFilterContext } from '../../context/orderFilterContext'
import PresetFilterList from './PresetFilterList'
import { Can } from '../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../shared/ability'
import userRole from '../../shared/userRole'

const OrderFilter = ({
  height,
  onEdit,
  onExpandFilter,
  onReset,
  isExpand,
  user,
}) => {
  const orderFilterContext = useContext(OrderFilterContext)

  const handleSave = () => {
    if (typeof orderFilterContext.savePreset === 'function') {
      orderFilterContext.savePreset()
    }
  }

  return (
    <div
      className="order-filter-wrapper"
      style={{ height: isExpand ? height : 'auto' }}
    >
      <div className="filter-header">
        <div className="d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
            <div className="d-flex flex-column">
              <span>
                <Icon.Filter className="feather" />
              </span>
            </div>
            <div className="d-flex flex-column">
              <span className="font-weight-bold">Orders Advance Filter</span>
            </div>
            <div className="d-flex flex-column">
              <button
                type="button"
                className="btn btn-icon"
                onClick={onExpandFilter}
              >
                {isExpand ? (
                  <Icon.ChevronUp className="feather" />
                ) : (
                  <Icon.ChevronDown className="feather" />
                )}
              </button>
            </div>
          </div>
          <hr className="ml- mr-0 w-100 mb-0" />
        </div>
      </div>
      <div
        className="d-flex flex-column filter-body"
        style={{ height: 'calc(100% - 41px)' }}
      >
        <div className="d-flex flex-column preset-list">
          <PresetFilterList onEdit={onEdit} onReset={onReset} />
        </div>
        {isExpand && (
          <div className="d-flex flex-column preset-form mt-3">
            <Can
              I={abilityAction.READ}
              a={abilityComponent.ORDER_FILTER_CLIENT}
            >
              <div className="d-flex flex-column">
                <SelectField
                  label="Client"
                  placeholder="Select Client"
                  className="bg-white"
                  isMulti
                  isClearable
                  closeMenuOnSelect
                  isScrollToSelected={false}
                  selectedValue={orderFilterContext.client.value}
                  options={orderFilterContext.client.options}
                  onChange={orderFilterContext.client.onChange}
                  onFocus={orderFilterContext.client.onFocus}
                />
              </div>
            </Can>
            <Can
              I={abilityAction.READ}
              a={abilityComponent.ORDER_FILTER_CUSTOMER}
            >
              <div className="d-flex flex-column">
                <SelectField
                  label="Customer"
                  placeholder="Select Customer"
                  className="bg-white"
                  isMulti
                  isClearable
                  closeMenuOnSelect
                  isScrollToSelected={false}
                  selectedValue={orderFilterContext.customer.value}
                  onFocus={orderFilterContext.customer.onFocus}
                  options={orderFilterContext.customer.options}
                  onChange={orderFilterContext.customer.onChange}
                />
              </div>
            </Can>
            <Can
              I={abilityAction.READ}
              a={abilityComponent.ORDER_FILTER_CREATOR}
            >
              <div className="d-flex flex-column">
                <SelectField
                  label="Order's Creator"
                  placeholder="Select Order's Creator"
                  className="bg-white"
                  isMulti
                  isClearable
                  closeMenuOnSelect
                  isScrollToSelected={false}
                  selectedValue={orderFilterContext.creator.value}
                  onFocus={orderFilterContext.creator.onFocus}
                  options={orderFilterContext.creator.options}
                  onChange={orderFilterContext.creator.onChange}
                />
              </div>
            </Can>
            <Can
              I={abilityAction.READ}
              a={abilityComponent.ORDER_FILTER_MANAGER}
            >
              <div className="d-flex flex-column">
                <SelectField
                  label="Order's Project Lead"
                  placeholder="Select Order's Project Lead"
                  className="bg-white"
                  isMulti
                  isClearable
                  closeMenuOnSelect
                  isScrollToSelected={false}
                  selectedValue={orderFilterContext.manager.value}
                  options={orderFilterContext.manager.options}
                  onChange={orderFilterContext.manager.onChange}
                  onFocus={orderFilterContext.manager.onFocus}
                />
              </div>
            </Can>
            <Can
              I={abilityAction.READ}
              a={abilityComponent.ORDER_FILTER_OPERATOR}
            >
              <div className="d-flex flex-column">
                <SelectField
                  label="Order's Operator"
                  placeholder="Select Order's Operator"
                  className="bg-white"
                  isMulti
                  isClearable
                  closeMenuOnSelect
                  isScrollToSelected={false}
                  selectedValue={orderFilterContext.orderAssigned.value}
                  options={orderFilterContext.orderAssigned.options}
                  onChange={orderFilterContext.orderAssigned.onChange}
                  onFocus={orderFilterContext.orderAssigned.onFocus}
                />
              </div>
            </Can>
            <div className="d-flex flex-column">
              <SelectField
                label="Order's Status"
                placeholder="Select Order's Status"
                className="bg-white"
                isMulti
                isClearable
                closeMenuOnSelect
                isScrollToSelected={false}
                selectedValue={orderFilterContext.orderStatus.value}
                options={orderFilterContext.orderStatus.options}
                onChange={orderFilterContext.orderStatus.onChange}
              />
            </div>
            <div className="d-flex flex-column">
              <SelectField
                label="Order's Services Category"
                placeholder="Select Order's Services Category"
                className="bg-white"
                isMulti
                isClearable
                closeMenuOnSelect
                isScrollToSelected={false}
                selectedValue={orderFilterContext.serviceCategory.value}
                options={orderFilterContext.serviceCategory.options}
                onChange={orderFilterContext.serviceCategory.onChange}
                onFocus={orderFilterContext.serviceCategory.onFocus}
              />
            </div>
            <div className="d-flex flex-column">
              <SelectField
                label="Order's Services Type"
                placeholder="Select Order's Services Type"
                className="bg-white"
                isMulti
                isClearable
                closeMenuOnSelect
                isScrollToSelected={false}
                selectedValue={orderFilterContext.serviceType.value}
                options={orderFilterContext.serviceType.options}
                onChange={orderFilterContext.serviceType.onChange}
                onFocus={orderFilterContext.serviceType.onFocus}
              />
            </div>
            <div className="d-flex flex-column">
              <DateRangePicker
                formClassName="date-input"
                label="Order's Created Date"
                startDate={orderFilterContext.orderCreated.startDate}
                endDate={orderFilterContext.orderCreated.endDate}
                onChange={orderFilterContext.orderCreated.onChange}
              />
            </div>
            <div className="d-flex flex-column">
              <DateRangePicker
                formClassName="date-input"
                label="Order's Due Date"
                startDate={orderFilterContext.orderDueDate.startDate}
                endDate={orderFilterContext.orderDueDate.endDate}
                onChange={orderFilterContext.orderDueDate.onChange}
              />
            </div>
            <div className="d-flex flex-column">
              <SelectField
                label="Order's Queue Time"
                className="bg-white"
                isMulti
                isClearable
                closeMenuOnSelect
                isScrollToSelected={false}
                selectedValue={orderFilterContext.queueTime.value}
                options={orderFilterContext.queueTime.options}
                onChange={orderFilterContext.queueTime.onChange}
              />
            </div>
            <div className="d-flex flex-column">
              <SelectField
                label="Order's Work Time"
                className="bg-white"
                isMulti
                isClearable
                closeMenuOnSelect
                isScrollToSelected={false}
                selectedValue={orderFilterContext.workTime.value}
                options={orderFilterContext.workTime.options}
                onChange={orderFilterContext.workTime.onChange}
              />
            </div>
            <div className="d-flex flex-column">
              <InputField
                label="Custom Search"
                placeholder="Type Search"
                value={orderFilterContext.customSearch.value}
                onChange={orderFilterContext.customSearch.onChange}
              />
            </div>
            <div className="d-flex flex-column">
              <hr className="ml- mr-0 w-100" />
            </div>
            <div className="d-flex flex-column">
              <InputField
                label="Create Preset Filter"
                placeholder="Filter Name"
                maxLength={30}
                isRequired
                isEmpty={orderFilterContext.presetName.isEmpty}
                value={orderFilterContext.presetName.value}
                onChange={orderFilterContext.presetName.onChange}
                onKeyDown={orderFilterContext.presetName.onKeyDown}
              />
            </div>
            <div className="d-flex flex-column">
              <button
                type="button"
                className="btn btn-darkprimary"
                onClick={handleSave}
                disabled={user && user.viewOnly && user.role !== userRole.CSO}
              >
                Save Preset Filter
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default OrderFilter
