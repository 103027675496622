import tinycolor from 'tinycolor2'
import * as blobUtil from 'blob-util'
import backdropImg from '../backdrop.png'

export const getAverageRGB = (imgEl) => {
  var blockSize = 5, // only visit every 5 pixels
    defaultRGB = { r: 0, g: 0, b: 0 }, // for non-supporting envs
    canvas = document.createElement('canvas'),
    context = canvas.getContext && canvas.getContext('2d'),
    data,
    width,
    height,
    i = -4,
    length,
    rgb = { r: 0, g: 0, b: 0 },
    count = 0

  if (!context) {
    return defaultRGB
  }

  height = canvas.height =
    imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height
  width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width

  context.drawImage(imgEl, 0, 0)

  try {
    data = context.getImageData(0, 0, width, height)
  } catch (e) {
    /* security error, img on diff domain */ alert('x')
    return defaultRGB
  }

  length = data.data.length

  while ((i += blockSize * 4) < length) {
    ++count
    rgb.r += data.data[i]
    rgb.g += data.data[i + 1]
    rgb.b += data.data[i + 2]
  }

  // ~~ used to floor values
  rgb.r = ~~(rgb.r / count)
  rgb.g = ~~(rgb.g / count)
  rgb.b = ~~(rgb.b / count)

  return rgb
}

export const getBase64Image = (img) => {
  // Create an empty canvas element
  var canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height

  // Copy the image contents to the canvas
  var ctx = canvas.getContext('2d')
  ctx.drawImage(img, 0, 0)

  // Get the data-URL formatted image
  // Firefox supports PNG and JPEG. You could check img.src to
  // guess the original format, but be aware the using "image/jpg"
  // will re-encode the image.
  var dataURL = canvas.toDataURL('image/png')

  return dataURL.replace(/^data:image\/(png|jpg);base64,/, '')
}

export const getColorIsDark = (img) => {
  const colorSnapshot = getAverageRGB(img)

  const colorRGB = `rgb (${colorSnapshot.r}, ${colorSnapshot.g}, ${colorSnapshot.b})`
  const isDark = tinycolor(colorRGB).isDark()

  return isDark
}

export const getImage = (dataUrl) => {
  const img = new Image()
  img.src = dataUrl
  img.width = 250
  img.classList.add('d-none')
  img.classList.add('img-generated')

  const imgOldEL = document.body.querySelector('.img-generated')
  if (imgOldEL) {
    imgOldEL.remove()
  }

  document.body.append(img)

  return img
}

export const getDark = (img) => {
  return new Promise((resolve, reject) => {
    img.onload = () => {
      const isDark = getColorIsDark(img)
      resolve(isDark)
    }

    img.onerror = () => {
      reject('error')
    }
  })
}

export const backdrop = () => {
  return new Promise((resolve, reject) => {
    blobUtil
      .imgSrcToDataURL(backdropImg)
      .then((bdDataUrl) => {
        resolve(bdDataUrl)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const generateWatermark = (pdf, wmDataURL, width, height) => {
  // watermark
  const deviderHeight = height < 300 ? height / 2 : 250
  const countHeight = Math.ceil(parseInt(height) / deviderHeight)
  const deviderWidth = width < 300 ? width / 2 : 400
  const countWidth = Math.ceil(parseInt(width) / deviderWidth)

  for (let index = 1; index <= countHeight; index++) {
    for (let yndex = 1; yndex <= countWidth; yndex++) {
      const watermarkPos = {
        x: width - yndex * deviderWidth,
        y: height - index * deviderHeight,
        width: 194,
        height: 58,
      }

      pdf.addImage(
        wmDataURL,
        'PNG',
        watermarkPos.x,
        watermarkPos.y,
        watermarkPos.width,
        watermarkPos.height
      )
    }
  }
}
