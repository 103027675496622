import React, { useState, useEffect, useCallback } from 'react';

import './ThreadComment.scss';
import CommentContainer from '../CommentContainer/CommentContainer';

const ThreadComment = ({ comments, onSelectedComment, id, selectedAttrId, isResolve = false }) => {
  const [isMinimize, setIsMinimize] = useState(true);

  useEffect(() => {
    setIsMinimize(id !== selectedAttrId);
  }, [selectedAttrId, id]);

  const handleMaximize = useCallback(() => {
    if (typeof onSelectedComment === "function") {
      onSelectedComment();
    }
  }, [onSelectedComment]);

  return (
    <li className={`thread-comment-wrapper mb-3 ${!isMinimize ? 'active' : ''}`} onClick={handleMaximize}>
      <CommentContainer
        isMinimize={isMinimize}
        comments={comments}
        isResolve={isResolve} />
    </li>
  );
}

export default React.memo(ThreadComment);