import { Observable } from 'rxjs'
import AxiosSubscriber from '../../factory/AxiosSubscriber'
import {
  requestAccessToken,
  requestTokenSourceType,
  requestTokenSourceByLogin,
} from '../../shared/requestAccessToken'

export class CreateTopicAPI {
  constructor() {
    this.subscription = null
  }

  subscribe(orderId, attachmentId, versionId, data, next, complete, error) {
    const token = localStorage.getItem('token')

    const sourceType = requestTokenSourceByLogin(
      requestTokenSourceType.CARD_ANNOTATION
    )
    requestAccessToken(sourceType, (csrfToken) => {
      const httpConfig = {
        url: `/order/${orderId}/attachment/${attachmentId}/version/${versionId}/annotation`,
        method: 'post',
        data: data,
        headers: {
          'x-csrf': csrfToken,
          accept: 'application/json',
          'Content-Type': `application/json`,
          Authorization: `Bearer ${token}`,
        },
      }

      let observable$ = new Observable((observer) => {
        return new AxiosSubscriber(observer, httpConfig)
      })

      this.subscription = observable$.subscribe({
        next: next,
        complete: complete,
        error: error,
      })
    })
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe()
    }
  }
}
