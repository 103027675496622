import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

const CurrentDateTime = ({ dateUser, isCloseSoon, timezoneUser }) => {
  let h = dateUser.getHours();
  if (h < 10) {
    h = "0" + h;
  }
  let m = dateUser.getMinutes();
  if (m < 10) {
    m = "0" + m;
  }
  let s = dateUser.getSeconds();
  if (s < 10) {
    s = "0" + s;
  }
  const dateTime = dateUser || new Date();
  const resultDate = (<span className="text-12 font-weight-bold">{format(dateTime, 'EEEE, dd MMMM yyyy')}&nbsp;&nbsp;</span>);
  const resultTime = (<span className={`text-12 font-weight-bold ${isCloseSoon ? 'text-danger' : 'text-primary'}`}>{h}:{m}:{s}</span>);

  return (
    <div className="line-height-12">
      <div className="d-flex" style={{ marginBottom: '8px' }}>
        {resultDate}
      </div>
      <div style={{ marginBottom: '2px' }}>
        {resultTime}
        <span className={`text-12 font-weight-bold ${isCloseSoon ? 'text-danger' : 'text-primary'}`}>&nbsp;{timezoneUser}</span>
      </div>
    </div>
  );
};

CurrentDateTime.propTypes = {
  isCloseSoon: PropTypes.bool.isRequired,
  dateUser: PropTypes.object.isRequired,
  timezoneUser: PropTypes.string,
  user: PropTypes.object,
};

export default CurrentDateTime;