import React from 'react'
import * as Icon from 'react-feather'

const CloseButtonToast = () => {
  return (
    <span className="toast-close">
      <Icon.X className="feather" />
    </span>
  )
}

export default CloseButtonToast
