import React from 'react'

export const TooltipContext = React.createContext({
  onIsCloseTooltipEvent: null,
  isCloseTooltipEvent: false,
  isShowTooltip: false,
  onShowTooltip: null,
  onSelectedTopic: null,
  onSelectedTopicByAttrId: null,
  onSelectedCommentTooltip: null,
  selectedTopic: null,
  tooltipPlace: '',
  onTooltipPlaceChanged: null,
  tooltipPosition: null,
  onTooltipPositionChanged: null,
  selectedAnnotation: null,
  onUpdateUnsavedCommentSelectedAnnotation: null,
  onHighlightUnsaved: null,
  hasUnsavedComment: false,
  onHasUnsavedComment: null,
})
