class EventBus {
  constructor(options) {
    this._listeners = Object.create(null)
  }

  on(eventName, listener) {
    this._on(eventName, listener, {
      external: true,
    })
  }

  off(eventName, listener) {
    this._off(eventName, listener, {
      external: true,
    })
  }

  dispatch(eventName) {
    const eventListeners = this._listeners[eventName]

    if (!eventListeners || eventListeners.length === 0) {
      return
    }

    const args = Array.prototype.slice.call(arguments, 1)
    let externalListeners
    eventListeners.slice(0).forEach(function ({ listener, external }) {
      if (external) {
        if (!externalListeners) {
          externalListeners = []
        }

        externalListeners.push(listener)
        return
      }

      listener.apply(null, args)
    })

    if (externalListeners) {
      externalListeners.forEach(function (listener) {
        listener.apply(null, args)
      })
      externalListeners = null
    }
  }

  _on(eventName, listener, options = null) {
    let eventListeners = this._listeners[eventName]

    if (!eventListeners) {
      this._listeners[eventName] = eventListeners = []
    }

    eventListeners.push({
      listener,
      external: (options && options.external) === true,
    })
  }

  _off(eventName, listener, options = null) {
    const eventListeners = this._listeners[eventName]

    if (!eventListeners) {
      return
    }

    for (let i = 0, ii = eventListeners.length; i < ii; i++) {
      if (eventListeners[i].listener === listener) {
        eventListeners.splice(i, 1)
        return
      }
    }
  }
}

export default EventBus
