import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  loading: false
};

const apiStart = (state, action) => {
  return updateObject(state, { loading: true });
};

const apiStop = (state, action) => {
  return updateObject(state, { loading: false });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.API_START:
      return apiStart(state, action);
    case actionTypes.API_STOP:
      return apiStop(state, action);
    default:
      return state;
  }
};

export default reducer;