import { Observable } from 'rxjs'
import AxiosSubscriber from '../../factory/AxiosSubscriber'
import { baseURLEndpoint } from '../../shared/constant'
import {
  requestTokenSourceType,
  requestAccessToken,
} from '../../shared/requestAccessToken'

export class UploadMediaAPI {
  constructor() {
    this.subscription = null
  }

  subscribe(
    id,
    type,
    data,
    config,
    next,
    complete,
    error,
    filters = null,
    onUploadProgress = (_) => {}
  ) {
    const token = localStorage.getItem('token')

    requestAccessToken(requestTokenSourceType.UPLOAD, (csrfToken) => {
      const httpConfig = {
        url: `${baseURLEndpoint}/upload/${type}/${id}`,
        method: 'put',
        data: data,
        params: filters ? filters : null,
        headers: {
          'x-csrf': csrfToken,
          accept: 'application/json',
          'x-file-timeline-record': config.timelineRecord, // 0: skip timeline, 1: post to timeline
          'x-file-attachment-record': config.attachmentRecord, // 0: skip attachment. 1: add to attachment section
          'x-upload-from': config.uploadFrom ? config.uploadFrom : '_',
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: onUploadProgress,
      }

      let observable$ = new Observable((observer) => {
        return new AxiosSubscriber(observer, httpConfig, false)
      })

      this.subscription = observable$.subscribe({
        next: next,
        complete: complete,
        error: error,
      })
    })
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe()
    }
  }
}
