function formatL10nValue(text, args) {
  if (!args) {
    return text
  }

  return text.replace(/\{\{\s*(\w+)\s*\}\}/g, (all, name) => {
    return name in args ? args[name] : '{{' + name + '}}'
  })
}

const NullL10n = {
  async getLanguage() {
    return 'en-us'
  },

  async getDirection() {
    return 'ltr'
  },

  async get(property, args, fallback) {
    return formatL10nValue(fallback, args)
  },

  async translate(element) {},
}

export default NullL10n
