import React, { useEffect } from 'react'
import { socketRemoveAllListeners } from '../../socket/socket'

function ClearhHistory() {
  useEffect(() => {
    socketRemoveAllListeners()
    localStorage.clear()
    window.location.href = '/'
  }, [])

  return <div></div>
}

export default ClearhHistory
