import React from 'react';

export const LoginContext = React.createContext({
  email: {
    value: "",
    onChange: null,
    isEmpty: false,
    isInvalid: false
  },
  password: {
    value: "",
    onChange: null,
    isEmpty: false,
    isInvalid: false
  },
});