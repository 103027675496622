import React from 'react'

export const ArchivedOrderFilterContext = React.createContext({
  creator: {
    value: null,
    options: null,
    onChange: null,
  },
  customer: {
    value: null,
    options: null,
    onChange: null,
  },
  orderAssigned: {
    value: null,
    options: null,
    onChange: null,
  },
  serviceCategory: {
    value: null,
    options: null,
    onChange: null,
  },
  serviceType: {
    value: null,
    options: null,
    onChange: null,
  },
  orderCreated: {
    startDate: null,
    endDate: null,
    onChange: null,
  },
  orderUpdatedDate: {
    startDate: null,
    endDate: null,
    onChange: null,
  },
  customSearch: {
    value: '',
    onChange: null,
  },
  presetName: {
    value: '',
    isEmpty: false,
    onChange: null,
    onKeyDown: null,
  },
  savePreset: null,
})
