import * as actionTypes from './../actions/actionTypes'
import { flashStatusType } from '../actions/flashNotification'

const initialState = {
  content: null,
  status: flashStatusType.PRIMARY,
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FLASH_NOTIFICATION_SHOW:
      return { ...state, ...action }

    case actionTypes.FLASH_NOTIFICATION_HIDE:
      return initialState

    default:
      return state
  }
}

export default reducer
