import React from 'react'
import { Stage, Layer } from 'react-konva'
import PropTypes from 'prop-types'

import './View.scss'
import { typeDisplayAnnotation } from '../../shared/constants'
import { ShapeContext } from '../../context/shapeContext'
import LayerGroup from './LayerGroup'

const View = ({
  onMouseDown,
  onTouchStart,
  onMouseUp,
  onTouchEnd,
  onMouseMove,
  onTouchMove,
  onContextMenu,
  objects,
  imageBoundRect = { x: 0, y: 0, width: 0, height: 0, scale: 1 },
  layerPdfScroll = null,
  typeDisplay = typeDisplayAnnotation.IMAGE,
  groupCount = 1,
  isMouseDrawAction = true,
  activeDrawingArea = '',
}) => {
  return (
    <ShapeContext.Consumer>
      {(value) => (
        <Stage
          width={window.innerWidth}
          height={window.innerHeight}
          style={{
            width: window.innerWidth,
            height: window.innerHeight,
            position: 'absolute',
            top:
              typeDisplay === typeDisplayAnnotation.PDF ? layerPdfScroll.y : 0,
            left:
              typeDisplay === typeDisplayAnnotation.PDF ? layerPdfScroll.x : 0,
          }}
          className="canvas-view cursor-default"
          onMouseDown={onMouseDown}
          onTouchStart={onTouchStart}
          onMouseUp={onMouseUp}
          onTouchEnd={onTouchEnd}
          onMouseMove={onMouseMove}
          onTouchMove={onTouchMove}
          onContextMenu={onContextMenu}
        >
          <ShapeContext.Provider value={value}>
            <Layer>
              <LayerGroup
                imageBoundRect={imageBoundRect}
                objects={objects}
                count={groupCount}
                isMouseDrawAction={isMouseDrawAction}
                activeDrawingArea={activeDrawingArea}
              ></LayerGroup>
            </Layer>
          </ShapeContext.Provider>
        </Stage>
      )}
    </ShapeContext.Consumer>
  )
}

View.propTypes = {
  onMouseDown: PropTypes.func.isRequired,
  onTouchStart: PropTypes.func.isRequired,
  onMouseUp: PropTypes.func.isRequired,
  onTouchEnd: PropTypes.func.isRequired,
  onMouseMove: PropTypes.func.isRequired,
  onTouchMove: PropTypes.func.isRequired,
  onContextMenu: PropTypes.func.isRequired,
  objects: PropTypes.array.isRequired,
  imageBoundRect: PropTypes.object.isRequired,
}

export default React.memo(View)
