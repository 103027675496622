import React, { useState, useEffect } from 'react'
import {
  differenceInHours,
  differenceInSeconds,
  differenceInMinutes,
  addSeconds,
} from 'date-fns'
import { connect } from 'react-redux'

import './WorkingHours.scss'
import CurrentDateTime from './CurrentDateTime'
import { WorkingHoursAPI } from '../../../api/Commons/WorkingHours'
import toastCenter from '../../../shared/toastCenter'
import { timeConvert } from '../../../shared/dateTimeHelper'
import { handleBranding } from '../../../shared/utility'

function formatNextOpenHours(
  workingHoursData,
  diffHours,
  statusWorkingHours,
  isClosed,
  statusDiffHours,
  stateTime,
  currentDescription,
  percentOfTimeHours
) {
  if (!workingHoursData.tomorrowOpenHours.isClose) {
    diffHours = workingHoursData && workingHoursData.tomorrowOpenHours.open
    statusWorkingHours = 'closed,'
    isClosed = true
    statusDiffHours = (
      <span className="text-11">&nbsp;open tomorrow at&nbsp;</span>
    )
    stateTime = ''
    currentDescription = (
      <span className="text-11 font-weight-bold">
        {diffHours}
        {stateTime}
      </span>
    )
    percentOfTimeHours = 100
  } else {
    diffHours = workingHoursData && workingHoursData.nextOpenHours.open
    statusWorkingHours = 'closed,'
    isClosed = true
    statusDiffHours = (
      <span className="text-11">
        &nbsp; open on {workingHoursData.nextOpenHours.dayOfWeek} at&nbsp;
      </span>
    )
    stateTime = ''
    currentDescription = (
      <span className="text-11 font-weight-bold">
        {diffHours}
        {stateTime}
      </span>
    )
    percentOfTimeHours = 100
  }
  return {
    diffHours,
    statusWorkingHours,
    isClosed,
    statusDiffHours,
    stateTime,
    currentDescription,
    percentOfTimeHours,
  }
}

const WorkingHours = ({ user }) => {
  const [dateUser, setDateUser] = useState(new Date())
  const [officeTime, setOfficeTime] = useState(new Date())
  const [workingHoursData, setWorkingHoursData] = useState(null)
  const [localizedDate, setLocalizedDate] = useState(null)
  const [openHourDateTime, setOpenHourDateTime] = useState(new Date())
  const [closeHourDateTime, setCloseHourDateTime] = useState(new Date())
  const [officeIsCLosed, setOfficeIsCLosed] = useState(false)

  let percentOfTimeHours = 0
  let isClosed = true
  let diffHours = 0
  let stateTime = 'hrs'
  let statusWorkingHours = 'closed.'
  let statusDiffHours = null
  let currentDescription = null

  useEffect(() => {
    const workingHoursApi = new WorkingHoursAPI()
    const onNext = (response) => {
      if (response.success) {
        const businessHours = response.data
        const localizedDateNoUTC = businessHours.localeTime.substr(0, 19)
        const officeTimeNoUTC = businessHours.officeTime.substr(0, 19)
        const newDateUser = new Date(localizedDateNoUTC)
        const officeTime = new Date(officeTimeNoUTC)
        setWorkingHoursData(businessHours)
        setLocalizedDate(newDateUser)
        setDateUser(newDateUser)
        setOfficeTime(officeTime)
        setOfficeIsCLosed(businessHours.officeCurrentOpenHours.isClose)

        const openHoursArr = businessHours.officeCurrentOpenHours.open.split(
          ':'
        )
        const closeHoursArr = businessHours.officeCurrentOpenHours.close.split(
          ':'
        )
        const dateOpenHours = new Date(
          officeTime.getFullYear(),
          officeTime.getMonth(),
          officeTime.getDate(),
          parseInt(openHoursArr[0]),
          parseInt(openHoursArr[1])
        )
        const dateCloseHours = new Date(
          officeTime.getFullYear(),
          officeTime.getMonth(),
          officeTime.getDate(),
          parseInt(closeHoursArr[0]),
          parseInt(closeHoursArr[1])
        )

        setOpenHourDateTime(dateOpenHours)
        setCloseHourDateTime(dateCloseHours)

        // const openHoursArr = businessHours.currentOpenHours.open.split(':');
        // const closeHoursArr = businessHours.currentOpenHours.close.split(':');

        // const dateOpenHours = new Date(newDateUser.getFullYear(), newDateUser.getMonth(), newDateUser.getDate(), parseInt(openHoursArr[0]), parseInt(openHoursArr[1]));
        // const dateCloseHours = new Date(newDateUser.getFullYear(), newDateUser.getMonth(), newDateUser.getDate(), parseInt(closeHoursArr[0]), parseInt(closeHoursArr[1]));

        // const convertedDateOpenHourUTC = convertToUTC(dateOpenHours);
        // const convertedCloseHourUTC = convertToUTC(dateCloseHours);

        // const convertedDateOpenHourLocaltime = convertToLocaltime(convertedDateOpenHourUTC, user.timeZone.utc);
        // const convertedCloseHourLocaltime = convertToLocaltime(convertedCloseHourUTC, user.timeZone.utc);

        // setOpenHourDateTime(convertedDateOpenHourLocaltime);
        // setCloseHourDateTime(convertedCloseHourLocaltime);
      }
    }

    const onComplete = () => {}

    if (user) {
      workingHoursApi.subscribe(
        onNext,
        onComplete,
        toastCenter.messageServerError
      )
    }

    return () => workingHoursApi.unsubscribe()
  }, [user])

  useEffect(() => {
    if (localizedDate !== null) {
      const timeoutId = setTimeout(() => {
        setDateUser(addSeconds(dateUser, 1))
      }, 1000)

      return () => clearTimeout(timeoutId)
    }
  }, [dateUser, localizedDate])

  if (workingHoursData !== null) {
    const dateOpenHours = new Date(
      openHourDateTime.getFullYear(),
      openHourDateTime.getMonth(),
      openHourDateTime.getDate(),
      openHourDateTime.getHours(),
      openHourDateTime.getMinutes()
    )
    const dateCloseHours = new Date(
      closeHourDateTime.getFullYear(),
      closeHourDateTime.getMonth(),
      closeHourDateTime.getDate(),
      closeHourDateTime.getHours(),
      closeHourDateTime.getMinutes()
    )
    const diffMinHours = differenceInSeconds(dateCloseHours, dateOpenHours)
    const diffMinCloseHours = Math.abs(
      differenceInSeconds(officeTime, dateCloseHours)
    )
    isClosed = officeIsCLosed

    if (isClosed) {
      statusWorkingHours = 'closed,'
      statusDiffHours = <span className="text-11">&nbsp;open in&nbsp;</span>
    } else {
      statusWorkingHours = 'open'
      statusDiffHours = <span className="text-11">&nbsp;for another&nbsp;</span>
    }

    if (
      differenceInMinutes(officeTime, dateOpenHours) >= 0 &&
      differenceInSeconds(officeTime, dateOpenHours) >= 0 &&
      differenceInMinutes(officeTime, dateCloseHours) <= 0 &&
      differenceInSeconds(officeTime, dateCloseHours) <= 0
    ) {
      diffHours = Math.abs(differenceInHours(officeTime, dateCloseHours))
      stateTime = 'hrs'
      let stateMins = 'mins'
      const diffMins = Math.abs(differenceInMinutes(officeTime, dateCloseHours))

      if (diffHours === 0) {
        diffHours = Math.abs(differenceInMinutes(officeTime, dateCloseHours))
        stateTime = 'mins'
        stateMins = ''
      }

      if (diffHours === 0 && stateTime === 'mins') {
        diffHours = ''
        stateTime = 'few secs'
        stateMins = ''
      }

      statusWorkingHours = 'open'
      statusDiffHours = <span className="text-11">&nbsp;for another&nbsp;</span>

      currentDescription = (
        <span className="text-11 font-weight-bold">
          {stateMins === ''
            ? `${diffHours}${stateTime}`
            : `${timeConvert(diffMins)}`}
          !
        </span>
      )
      percentOfTimeHours =
        (Math.abs(diffMinHours - diffMinCloseHours) / diffMinHours) * 100
    } else if (differenceInMinutes(officeTime, dateOpenHours) <= 0) {
      if (!workingHoursData.localeCurrentOpenHours.isClose) {
        diffHours = Math.abs(differenceInHours(officeTime, dateOpenHours))
        stateTime = 'hrs'

        if (diffHours === 0) {
          diffHours = Math.abs(differenceInMinutes(officeTime, dateOpenHours))
          stateTime = 'mins'
        }

        if (diffHours === 0 && stateTime === 'mins') {
          diffHours = ''
          stateTime = 'few secs'
        }

        statusWorkingHours = 'closed,'
        statusDiffHours = <span className="text-11">&nbsp;open in&nbsp;</span>

        currentDescription = (
          <span className="text-11 font-weight-bold">
            {diffHours}
            {stateTime}!
          </span>
        )
        percentOfTimeHours = 100
      } else {
        ;({
          diffHours,
          statusWorkingHours,
          isClosed,
          statusDiffHours,
          stateTime,
          currentDescription,
          percentOfTimeHours,
        } = formatNextOpenHours(
          workingHoursData,
          diffHours,
          statusWorkingHours,
          isClosed,
          statusDiffHours,
          stateTime,
          currentDescription,
          percentOfTimeHours
        ))
      }
    } else if (differenceInMinutes(officeTime, dateCloseHours) >= 0) {
      ;({
        diffHours,
        statusWorkingHours,
        isClosed,
        statusDiffHours,
        stateTime,
        currentDescription,
        percentOfTimeHours,
      } = formatNextOpenHours(
        workingHoursData,
        diffHours,
        statusWorkingHours,
        isClosed,
        statusDiffHours,
        stateTime,
        currentDescription,
        percentOfTimeHours
      ))
    }

    if (officeIsCLosed) {
      statusWorkingHours = 'closed'
      statusDiffHours = ''
      currentDescription = ''
    }
  }

  return (
    <div className="d-flex flex-column container-working-hours container-grey container-radius-10 p-15">
      {workingHoursData && (
        <CurrentDateTime
          workingHours={workingHoursData}
          isCloseSoon={isClosed}
          dateUser={dateUser}
          timezoneUser={user ? user.timeZone.label : ''}
        />
      )}
      <hr className="ml-0 mr-0" />
      <div className="d-flex">
        <div className="d-flex align-items-center line-height-12">
          <span
            className={
              isClosed ? 'dot dot-12 dot-danger' : 'dot dot-12 dot-primary'
            }
          ></span>
        </div>
        <div
          className="d-flex flex-grow-1 ml-2 line-height-12"
          style={{ flexWrap: 'wrap' }}
        >
          <span
            className={`text-11 font-weight-bold ${
              isClosed ? 'text-danger' : 'text-primary'
            }`}
          >
            We are {statusWorkingHours}
          </span>
          {statusDiffHours}
          {currentDescription}
        </div>
      </div>
      <div className="progress-container mt-3">
        <div className="progress">
          <div
            className={
              isClosed ? 'progress-bar bg-danger' : 'progress-bar bg-primary'
            }
            style={{ width: `${percentOfTimeHours}%` }}
          ></div>
        </div>
      </div>
      <div className="d-flex">
        <div className="d-flex">
          <span className="text-12 font-weight-bold">
            {workingHoursData
              ? workingHoursData.localeCurrentOpenHours.open
              : ''}
          </span>
        </div>
        <div className="d-flex ml-auto">
          <span className="text-12 font-weight-bold">
            {workingHoursData
              ? workingHoursData.localeCurrentOpenHours.close
              : ''}
          </span>
        </div>
      </div>
      <hr className="ml-0 mr-0" />
      <div className="line-height-100 line-height-11">
        <div className="line-height-10">
          <span className="text-10 text-grey">
            {handleBranding(user, 'name')} Business Hours:
          </span>
        </div>
        <div className="line-height-10">
          <span className="text-10 text-grey">
            {workingHoursData && workingHoursData.description}
          </span>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ user }) => {
  return { user }
}

export default connect(mapStateToProps)(WorkingHours)
