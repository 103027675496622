import { Observable } from 'rxjs'
import AxiosSubscriber from '../../factory/AxiosSubscriber'

export class UploadToS3API {
  constructor() {
    this.subscription = null
  }

  subscribe(s3Data, file, next, complete, error, onUploadProgress = (_) => {}) {
    const form = new FormData()
    for (const key in s3Data.fields) {
      form.append(key, s3Data.fields[key])
    }
    form.append('Content-Type', file.type)
    form.append('file', file)
    const httpConfig = {
      url: `${s3Data.url}/`,
      method: 'post',
      data: form,
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Credentials': true,
      },
      onUploadProgress: onUploadProgress,
    }

    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig)
    })

    this.subscription = observable$.subscribe({
      next: next,
      complete: complete,
      error: error,
    })
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe()
    }
  }
}
