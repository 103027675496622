import { CSS_UNITS, orientationStruct } from './constants'

/**
 *  get the ratio
 *  @see https://stackoverflow.com/questions/1186414/whats-the-algorithm-to-calculate-aspect-ratio
 */
const gcd = (a, b) => {
  return b === 0 ? a : gcd(b, a % b)
}

export const getOrientation = (w, h) => {
  let result = orientationStruct.SQUARE

  if (w > h) {
    //it's a landscape
    result = orientationStruct.LANDSCAPE
  } else if (w < h) {
    //it's a portrait
    result = orientationStruct.PORTRAIT
  }

  return result
}

const getLayoutDetail = (width = 0, height = 0) => {
  const r = gcd(width, height)
  const w = width / r
  const h = height / r

  return {
    aspect: `${w}:${h}`,
    orientation: getOrientation(w, h),
  }
}

const distinctFilter = (value, index, self) => {
  return self.indexOf(value) === index
}

export const getWindowScaleList = (
  fileWidth,
  fileHeight,
  withCssUnit = true
) => {
  const screenWidth = window.innerWidth
  const screenHeight = window.innerHeight
  const fileDetail = getLayoutDetail(fileWidth, fileHeight)
  const result = {
    initialScale: 0,
    pageFitScale: 0,
    pageHeightScale: 0,
    pageWidthScale: 0,
    orientation: fileDetail.orientation,
    scaleList: [],
  }

  const heightPadding = 180
  const widthPadding = 30
  const pageWidthScale = getPageScale(
    screenWidth,
    1,
    fileWidth,
    widthPadding,
    withCssUnit
  )
  const pageHeightScale = getPageScale(
    screenHeight,
    1,
    fileHeight,
    heightPadding,
    withCssUnit
  )

  if (fileDetail.orientation === orientationStruct.PORTRAIT) {
    const scaleHeight = [0.75, 1]
    const scaleWidth = [1, 1.25, 1.5]
    const scaleList = []
    scaleHeight.forEach((i) => {
      const scale = getPageScale(
        screenHeight,
        i,
        fileHeight,
        heightPadding,
        withCssUnit
      )
      scaleList.push(scale)
      if (i === 1) {
        result.pageFitScale = scale
        result.pageHeightScale = scale
      }
    })
    scaleWidth.forEach((i) => {
      const scale = getPageScale(
        screenWidth,
        i,
        fileWidth,
        widthPadding,
        withCssUnit
      )
      scaleList.push(scale)
      if (i === 1) {
        result.pageWidthScale = scale
      }
    })
    result.scaleList = [...scaleList].sort().filter(distinctFilter)
  } else if (fileDetail.orientation === orientationStruct.LANDSCAPE) {
    const scaleWidth = [0.5, 0.75, 1]
    const scaleHeight = [1, 1.25, 1.5]
    const scaleList = []
    scaleWidth.forEach((i) => {
      const scale = getPageScale(
        screenWidth,
        i,
        fileWidth,
        widthPadding,
        withCssUnit
      )
      scaleList.push(scale)
      if (i === 1) {
        result.pageFitScale = scale
        result.pageWidthScale = scale
      }
    })
    scaleHeight.forEach((i) => {
      const scale = getPageScale(
        screenHeight,
        i,
        fileHeight,
        heightPadding,
        withCssUnit
      )
      scaleList.push(scale)
      if (i === 1) {
        result.pageHeightScale = scale
      }
    })
    result.scaleList = [...scaleList].sort().filter(distinctFilter)
  }
  result.initialScale = Math.min(pageWidthScale, pageHeightScale)

  return result
}

const getPageScale = (
  screenSize,
  windowScale,
  devider,
  padding = 0,
  withCssUnit = true
) => {
  const fitSize = screenSize / windowScale - padding
  const pageScale = parseFloat((fitSize / devider).toFixed(2))

  if (withCssUnit) {
    return pageScale / CSS_UNITS
  }

  return pageScale
}
