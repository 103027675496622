import React, { useContext, useState, useEffect } from 'react'

import './ProgressReadFile.scss'
import { PreviewContext } from '../../context/previewContext'
import { LoaderContext } from '../../context/loaderContext'

function ProgressReadFile() {
  const { isLoadingFile } = useContext(PreviewContext)
  const { loadedPercent, onLoadedPercent } = useContext(LoaderContext)
  const [stateLoad, setStateLoad] = useState('Loading File...')

  useEffect(() => {
    if (loadedPercent === '100%') {
      setStateLoad('Please wait. Reading File...')
    }
  }, [loadedPercent])

  useEffect(() => {
    if (!isLoadingFile && typeof onLoadedPercent === 'function') {
      onLoadedPercent('0%')
    }
  }, [isLoadingFile, onLoadedPercent])

  if (!isLoadingFile) {
    return null
  }

  return (
    <div className="progress-read-file">
      <div className="progress">
        <div
          className="progress-bar bg-darkprimary"
          role="progressbar"
          aria-valuenow="0"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{ width: loadedPercent }}
        ></div>
      </div>
      <div className="d-flex justify-content-between mt-2">
        <span>{stateLoad}</span>
        {loadedPercent !== '100%' && <span>({loadedPercent})</span>}
      </div>
    </div>
  )
}

export default ProgressReadFile
