import React, { Fragment, useContext } from 'react'
import { connect } from 'react-redux'
import { subDays } from 'date-fns'

import './Membership.scss'
import MembershipOrder from '../MembershipOrder/MembershipOrder'
import MembershipStorage from '../MembershipStorage/MembershipStorage'
import { Can, AbilityContext } from '../../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../../shared/ability'
import MembershipAllocatedHours from '../MembershipAllocatedHours/MembershipAllocatedHours'
import { defaultDisplayDate } from '../../../shared/dateTimeHelper'
import ActiveOrder from '../ActiveOrder/ActiveOrder'
import { getCurrentView } from '../../../shared/utility'

const Membership = ({ user }) => {
  const abilityContext = useContext(AbilityContext)

  if (
    abilityContext.cannot &&
    abilityContext.cannot(
      abilityAction.READ,
      abilityComponent.MEMBERSHIP_CUSTOMER
    )
  ) {
    return null
  }

  if (user === null || user === undefined) {
    return null
  }

  return (
    <Fragment>
      {user && getCurrentView(user) === 'customer' && user.whitelabelInfo && (
        <div className="d-flex flex-column" style={{ marginBottom: '15px' }}>
          <div>
            <span
              className={
                !user.whitelabelInfo.isActive
                  ? 'text-16 font-weight-bold text-danger'
                  : 'text-16 font-weight-bold'
              }
            >
              {user && user.whitelabelInfo && user.whitelabelInfo.name}
            </span>
          </div>
          <div className="d-flex flex-row">
            <div className="d-flex align-items-center mr-2">
              <span
                className={
                  !user.whitelabelInfo.isActive
                    ? 'dot dot-20 dot-danger'
                    : 'dot dot-20 dot-darkprimary'
                }
              ></span>
            </div>
            <div className="d-flex flex-grow-1 flex-column mt-1">
              <div className="line-height-20">
                <span
                  className={
                    !user.whitelabelInfo.isActive
                      ? 'text-14 text-danger'
                      : 'text-14 text-grey'
                  }
                >
                  {user.whitelabelInfo.cycleDate &&
                  user.whitelabelInfo.cycleDate.length > 1
                    ? `${defaultDisplayDate(
                        new Date(user.whitelabelInfo.cycleDate[0])
                      )} - ${defaultDisplayDate(
                        subDays(new Date(user.whitelabelInfo.cycleDate[1]), 1)
                      )}`
                    : null}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      {user && getCurrentView(user) === 'customer' && (
        <div
          className="d-flex flex-column membership-status-container"
          style={{ marginTop: '2px' }}
        >
          <Can
            I={abilityAction.READ}
            a={abilityComponent.MANAGE_ALLOCATED_HOURS}
          >
            <MembershipAllocatedHours
              user={user}
              style={{ margin: '0 0 5px 0' }}
            />
          </Can>
          <Can I={abilityAction.READ} a={abilityComponent.MANAGE_ACTIVE_ORDER}>
            <ActiveOrder user={user} style={{ margin: '5px 0' }} />
          </Can>
          <Can I={abilityAction.READ} a={abilityComponent.MANAGE_MEMBERSHIP}>
            <MembershipOrder user={user} style={{ margin: '5px 0' }} />
          </Can>
          <Can I={abilityAction.READ} a={abilityComponent.MANAGE_STORAGE}>
            <MembershipStorage user={user} style={{ margin: '5px 0' }} />
          </Can>
        </div>
      )}
    </Fragment>
  )
}

const mapStateToProps = ({ user }) => {
  return { user }
}

export default connect(mapStateToProps)(Membership)
