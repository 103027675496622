import React, { useContext } from 'react'

import './LoaderFile.scss'
import { PreviewContext } from '../../context/previewContext'
import { typeDisplayAnnotation } from '../../shared/constants'
import ProgressReadFile from './ProgressReadFile'

const LoaderFile = () => {
  const { typeDisplay } = useContext(PreviewContext)

  if (typeDisplay === typeDisplayAnnotation.PDF) {
    return <ProgressReadFile />
  }

  return null
}

export default LoaderFile
