import React from 'react'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'

import { useAuth0 } from '@auth0/auth0-react'
import AppLoader from '../../components/Loader/AppLoader/AppLoader'
import branding from '../../shared/branding'
import clientLogin from '../../shared/clientLogin'
import * as Router from '../../shared/router'

const Auth0Login = ({ authRedirectPath, isAuthenticated, state, clientId }) => {
  const auth0State = useAuth0()

  if (authRedirectPath && localStorage) {
    localStorage.setItem('authRedirect', authRedirectPath)
  }

  if (!auth0State) {
    return <AppLoader branding={branding} loading={true} />
  }

  if (auth0State.isLoading) {
    return <AppLoader branding={branding} loading={true} />
  }

  let authRedirect = null
  if (isAuthenticated) {
    return <Redirect to={authRedirectPath} />
  }

  if (authRedirect === null && clientId && clientId !== clientLogin.CUSTOMER) {
    return <Redirect to={Router.signinAdmin} />
  }

  return isAuthenticated && authRedirectPath ? (
    <Redirect
      to={
        state && state.route && state.route.targetRedirectPath
          ? state.route.targetRedirectPath
          : authRedirectPath
      }
    />
  ) : (
    auth0State.loginWithRedirect()
  )
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    isAuthenticated: state.auth.token !== null,
    authRedirectPath: state.route.targetRedirectPath,
    state: state,
    clientId: state.auth.clientId,
  }
}

export default connect(mapStateToProps, null)(Auth0Login)
