import React, { useState, useContext, useEffect, useCallback } from 'react'
import * as Icon from 'react-feather'
import update from 'immutability-helper'

import './Zoom.scss'
import { PreviewContext } from '../../context/previewContext'
import { TooltipContext } from '../../context/tooltipContext'

let supportsWheel = false
const Zoom = ({ onChangeZoom }) => {
  const [currentZoomPercentage, setCurrentZoomPercentage] = useState(100)
  const [zoomScaleIndex, setZoomScaleIndex] = useState(0)

  const {
    zoomDetail,
    imageBoundRect,
    onTransitionProperty,
    onImageBoundRectChanged,
    onChangeScale,
  } = useContext(PreviewContext)
  const { onShowTooltip } = useContext(TooltipContext)
  const scaleList = zoomDetail.scaleList
  const scale = imageBoundRect.scale

  // event listener hotkeys for zoom
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown, false)
    document.body.addEventListener('keydown', handleKeyDown, false)
    document.addEventListener('wheel', handleScroll, {
      passive: false,
      capture: true,
    })
    document.addEventListener('mousewheel', handleScroll, {
      passive: false,
      capture: true,
    })
    document.addEventListener('DOMMouseScroll', handleScroll, {
      passive: false,
      capture: true,
    })

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('keydown', handleKeyDown, false)
      document.body.removeEventListener('keydown', handleKeyDown, false)
      document.removeEventListener('wheel', handleScroll, {
        passive: false,
        capture: true,
      })
      document.removeEventListener('mousewheel', handleScroll, {
        passive: false,
        capture: true,
      })
      document.removeEventListener('DOMMouseScroll', handleScroll, {
        passive: false,
        capture: true,
      })
    }
  })

  useEffect(() => {
    setCurrentZoomPercentage((scale * 100).toFixed(0))
  }, [scale])

  useEffect(() => {
    const currentIndex = scaleList.findIndex((x) => x === scale)
    setZoomScaleIndex(currentIndex)
  }, [scale, scaleList])

  const scalePreview = useCallback(
    (nextIndex) => {
      const currentScale = scaleList[nextIndex]

      setZoomScaleIndex(nextIndex)
      onShowTooltip(false)

      /**
       * will moved on onChangeScale
       * only used for img
       */
      onImageBoundRectChanged((v) =>
        update(v, {
          width: { $set: v.naturalWidth * currentScale },
          height: { $set: v.naturalHeight * currentScale },
          scale: { $set: currentScale },
        })
      )

      onTransitionProperty('all')
      setCurrentZoomPercentage((100 * currentScale).toFixed(0))

      onChangeScale(currentScale)

      onChangeZoom(currentScale)
    },
    [
      onChangeScale,
      onChangeZoom,
      onImageBoundRectChanged,
      onShowTooltip,
      onTransitionProperty,
      scaleList,
    ]
  )

  const handleZoomOut = useCallback(
    (index) => {
      if (zoomScaleIndex > 0) {
        scalePreview(index - 1)
      }
    },
    [scalePreview, zoomScaleIndex]
  )

  const handleZoomIn = useCallback(
    (index) => {
      if (zoomScaleIndex < scaleList.length - 1) {
        scalePreview(index + 1)
      }
    },
    [scaleList.length, scalePreview, zoomScaleIndex]
  )

  const handleKeyDown = (event) => {
    /**
     * 48  Num Key  0
     * 107 Num Key  +
     * 109 Num Key  -
     * 173 Min Key  hyphen/underscor Hey
     * 61  Plus key  +/= key
     *
     * mac:
     * 187 Key +
     * 189 Key -
     */
    if (
      (event.ctrlKey === true || event.metaKey) &&
      (event.which === 48 ||
        event.which === 61 ||
        event.which === 107 ||
        event.which === 173 ||
        event.which === 109 ||
        event.which === 187 ||
        event.which === 189)
    ) {
      event.preventDefault()
      if (event.which === 109 || event.which === 173 || event.which === 189) {
        // zoom out
        if (zoomScaleIndex > 0) {
          scalePreview(zoomScaleIndex - 1)
        }
      } else if (
        event.which === 107 ||
        event.which === 61 ||
        event.which === 187
      ) {
        // zoom in
        if (zoomScaleIndex < scaleList.length - 1) {
          scalePreview(zoomScaleIndex + 1)
        }
      } else if (event.which === 48) {
        // reset zoom
        if (zoomScaleIndex > 0) {
          scalePreview(0)
        }
      }
    }
  }

  const handleScroll = function (event) {
    /* Check whether the wheel event is supported. */
    if (event.type === 'wheel') supportsWheel = true
    else if (supportsWheel) return

    if (event.ctrlKey === true || event.metaKey === true) {
      event.preventDefault()

      if (supportsWheel) {
        /* Determine the direction of the scroll (< 0 → up, > 0 → down). */
        const delta =
          (event.deltaY || -event.wheelDelta || event.detail) >> 100 || 1

        if (delta === 1 && zoomScaleIndex > 0) {
          scalePreview(zoomScaleIndex - 1)
        } else if (delta === -1 && zoomScaleIndex < scaleList.length - 1) {
          scalePreview(zoomScaleIndex + 1)
        }
      }
    }
  }

  return (
    <div className="zoom-action-wrapper">
      <div className="d-flex flex-row align-items-center justify-content-center">
        <button
          type="button"
          className="btn btn-zoom btn-zoom-out"
          onClick={() => handleZoomOut(zoomScaleIndex)}
          data-tippy-content="Zoom out"
          disabled={zoomScaleIndex === 0}
        >
          <Icon.Minus className="zoom-out-icon" />
        </button>
      </div>
      <div className="d-flex flex-row align-items-center justify-content-center">
        <span className="current-zoom">{currentZoomPercentage}%</span>
      </div>
      <div className="d-flex flex-row align-items-center justify-content-center">
        <button
          type="button"
          className="btn btn-zoom btn-zoom-in"
          onClick={() => handleZoomIn(zoomScaleIndex)}
          data-tippy-content="Zoom in"
          disabled={zoomScaleIndex >= scaleList.length - 1}
        >
          <Icon.Plus className="zoom-in-icon" />
        </button>
      </div>
    </div>
  )
}

export default React.memo(Zoom)
