import React from 'react'

import './Spinner.scss'

function Spinner() {
  return (
    <div className="my-spinner">
      <svg
        width="32px"
        height="32px"
        viewBox="0 0 32 32"
        xmlns="http://www.w3.org/2000/svg"
        className="loader-icon"
      >
        <g id="spinner" className="spinner-loader">
          <circle
            className="path_bg"
            fill="none"
            strokeWidth="3"
            strokeLinecap="square"
            cx="16"
            cy="16"
            r="13"
          ></circle>
          <circle
            className="path"
            fill="none"
            strokeWidth="3"
            strokeLinecap="square"
            cx="16"
            cy="16"
            r="13"
          ></circle>
        </g>
      </svg>
    </div>
  )
}

export default Spinner
