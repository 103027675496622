import { Subject } from 'rxjs';

const subject = new Subject();
const subjectTwo = new Subject();

export const addNewPaymentMethodService = {
    emitReload: val => subject.next(val),
    reload: () => subject.asObservable()
};

export const addNewPaymentMethodStepTwoService = {
    emitReload: val => subjectTwo.next(val),
    reload: () => subjectTwo.asObservable()
};