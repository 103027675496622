import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import './MessageWelcome.scss';

const MessageWelcome = ({ onHide, guest }) => {
  const [cssShow, setCssShow] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setCssShow("show");
    }, 500);
  }, []);

  const handleHideMessage = () => {
    setTimeout(() => {
      onHide();
    }, 100);

    setCssShow("");
  }

  return (
    <div className="guest-message-welcome-wrapper">
      <div className={`modal fade ${cssShow}`}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="d-flex flex-row">
                <div className="d-flex flex-column mr-3">
                  <div className="d-flex rounded-circle user-avatar-32 user-avatar-cover" style={{ backgroundImage: `url(${guest.inviter.avatar})` }}></div>
                </div>
                <div className="d-flex flex-column">
                  <div className="d-flex flex-column"><span className="font-weight-bold text-16">{guest.inviter.name}</span></div>
                  <div className="d-flex flex-column">
                    <p>{guest.message}</p>
                  </div>
                  <div className="d-flex flex-row mt-3">
                    <button type="button" className="btn btn-darkprimary" onClick={handleHideMessage}>Got it!</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

MessageWelcome.propTypes = {
  onHide: PropTypes.func,
  guest: PropTypes.object
};

export default MessageWelcome
