import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import update from 'immutability-helper'
import * as actions from '../../store/actions'

import './FlashNotification.scss'
import Modal from '../Modal/Modal'
import { handleLogo } from '../../shared/utility'
import { CancelScheduleDowngradeAPI } from '../../api/Commons/CancelScheduleDowngrade'
import { CancelScheduleUpgradeAPI } from '../../api/Commons/CancelScheduleUpgrade'
import toastCenter, { toastMessageType } from '../../shared/toastCenter'
import { userDetailService } from '../../services/userDetailService/userDetailService'
import { flashStatusType } from '../../store/actions/flashNotification'
import { ResendEmailVerficationCode } from '../../api/User/ResendEmailVerificationCode'

const FlashNotification = ({
  flashNotification,
  user,
  hideFlashNotification,
}) => {
  const [messages, setMessages] = useState(null)
  const [status, setStatus] = useState(flashStatusType.PRIMARY)
  const [targetAction, setTargetAction] = useState(null)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [confirmationContent, setConfirmationContent] = useState({
    title: 'Are you sure you want to continue?',
    content: '',
    buttonText: 'Continue',
  })

  useEffect(() => {
    if (flashNotification.content) {
      setTimeout(() => {
        setMessages(flashNotification.content)
        setStatus(flashNotification.status)
      }, 750)
    }

    return () => {
      setMessages(null)
    }
  }, [flashNotification.content, flashNotification.status])

  const contentClickHandler = (e) => {
    const targetLink = e.target.closest('a')
    if (!targetLink) return
    e.preventDefault()

    handleEventTarget(e)
  }

  const handleEventTarget = (e) => {
    const confirmationBox = e.target.getAttribute('data-confirmation-box')
    setTargetAction(confirmationBox)

    switch (confirmationBox) {
      case 'cancel-downgrade':
        setShowConfirmation(true)
        setConfirmationContent((x) =>
          update(x, {
            content: {
              $set:
                'We will cancel your scheduled subscription downgrade as soon as you submit the Cancel Schedule button. You will not receive the downgrade subscription invoice afterwards.',
            },
            buttonText: { $set: 'Cancel Schedule' },
          })
        )
        break
      case 'cancel-upgrade':
        setShowConfirmation(true)
        setConfirmationContent((x) =>
          update(x, {
            content: {
              $set:
                'We will cancel your scheduled subscription upgrade as soon as you submit the Cancel Schedule button. You will not receive the upgrade subscription invoice afterwards.',
            },
            buttonText: { $set: 'Cancel Schedule' },
          })
        )
        break

      case 'resend-email-verification':
        doResendEmailVerification()
        break

      default:
        break
    }
  }

  const renderMessages = (messages) => {
    return { __html: messages }
  }

  const hideNotification = () => {
    setTimeout(() => {
      hideFlashNotification()
    }, 750)
  }

  const handleHideConfirmation = () => {
    setShowConfirmation(false)
  }

  const onContinueConfirmation = (action) => {
    if (action === 'cancel-downgrade') {
      doAPIRequest(new CancelScheduleDowngradeAPI())
    }
    if (action === 'cancel-upgrade') {
      doAPIRequest(new CancelScheduleUpgradeAPI())
    }
  }

  const doResendEmailVerification = () => {
    const resendEmailVerificationCode = new ResendEmailVerficationCode()

    const onNext = (response) => {
      if (response.success) {
        toastCenter.message(
          'Success',
          response.message,
          toastMessageType.SUCCESS
        )
        setShowConfirmation(false)
        return userDetailService.emitReload(true)
      }

      return toastCenter.messageServerErrorCustom(response)
    }
    resendEmailVerificationCode.subscribe(
      onNext,
      () => {},
      toastCenter.messageServerError
    )
  }

  const doAPIRequest = (subscription, cb = null) => {
    const onNext = (response) => {
      if (response.success) {
        toastCenter.message(
          'Success',
          response.message,
          toastMessageType.SUCCESS
        )
        setShowConfirmation(false)
        hideNotification()
        userDetailService.emitReload(true)
        if (typeof cb === 'function') {
          cb(null, response)
        }
        return
      }

      toastCenter.messageServerErrorCustom(response)
      if (typeof cb === 'function') {
        cb(null, response)
      }
      return
    }

    subscription.subscribe(onNext, () => {}, toastCenter.messageServerError)
  }

  return messages ? (
    <>
      <div
        className="flash-notification"
        data-flash-status={status}
        onClick={contentClickHandler}
        dangerouslySetInnerHTML={renderMessages(messages)}
      />
      <Modal
        show={showConfirmation}
        onHideModal={() => {}}
        modalSize="modal-md"
        isForceShow
        useClose={false}
      >
        <div className="d-flex flex-column">
          <div className="d-flex mb-4">{handleLogo(user)}</div>
          <div className="d-flex flex-column mb-3">
            <h3>{confirmationContent.title}</h3>
            <p>{confirmationContent.content}</p>
          </div>
          <div className="d-flex flex-row justify-content-end align-items-end">
            <button
              type="button"
              className="btn btn-danger mr-2"
              onClick={handleHideConfirmation}
            >
              Cancel
            </button>
            <button
              type="button"
              className="btn btn-darkprimary"
              onClick={() => onContinueConfirmation(targetAction)}
            >
              {confirmationContent.buttonText}
            </button>
          </div>
        </div>
      </Modal>
    </>
  ) : null
}

const mapStateToProps = ({ flashNotification, user }) => {
  return { flashNotification, user }
}

const mapDispatchToProps = (dispatch) => {
  return {
    hideFlashNotification: () => dispatch(actions.flashHide()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(FlashNotification)
