import React from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'

import * as Router from './shared/router'
import Logout from './containers/Auth/Logout/Logout'
import DashboardContainer from './containers/DashboardContainer/DashboardContainer'
// import PdfViewerContainer from './containers/PdfViewer/PdfViewerContainer';
import AnnotationContainer from './containers/AnnotationContainer/AnnotationContainer'

import Login from './containers/Auth/Auth'
import LoginAdmin from './containers/Auth/AuthAdmin'
import GuestAnnotation from './containers/GuestAnnotation/GuestAnnotation'
import ForgotPassword from './containers/Auth/ForgotPassword/ForgotPassword'
import ClearhHistory from './containers/ClearhHistory/ClearhHistory'
import NotSupportBrowser from './containers/NotSupportBrowser/NotSupportBrowser'
import ImpersonateCustomer from './containers/Impersonate/ImpersonateCustomer'
import Auth0 from './containers/Auth/Auth0'
import Auth0Login from './containers/Auth/Auth0Login'

const router = ({ isAuthenticated, onAuthLoading }) => {
  let routes = (
    <Switch>
      <Route path={Router.guestAnnotation} exact component={GuestAnnotation} />
      <Route path={Router.forgotPassword} exact component={ForgotPassword} />
      <Route path={Router.signin} exact component={Login} />
      <Route path={Router.signinAdmin} exact component={LoginAdmin} />
      <Route path={Router.clearhHistory} exact component={ClearhHistory} />
      <Route path={Router.notSupport} exact component={NotSupportBrowser} />
      <Route
        path={Router.impersonateCustomer}
        exact
        component={ImpersonateCustomer}
      />
      <Route path={Router.auth0Login} exact component={Auth0Login} />
      <Route path={Router.auth0Validate} exact component={Auth0} />
      <Route path={Router.signout} exact component={Logout} />
      {!onAuthLoading && !isAuthenticated && isAuthenticated != null && (
        <Redirect to={Router.auth0Login} />
      )}
    </Switch>
  )

  if (isAuthenticated) {
    let redirectTo = Router.home
    if (localStorage && localStorage.getItem('authRedirect')) {
      redirectTo = localStorage.getItem('authRedirect')
      localStorage.setItem('authRedirect', '')
      localStorage.removeItem('authRedirect')
    }

    routes = (
      <Switch>
        <Route path={Router.signout} exact component={Logout} />
        <Route path={Router.cart} exact component={DashboardContainer} />
        <Route
          path={Router.checkoutProcess}
          exact
          component={DashboardContainer}
        />
        <Route
          path={Router.checkoutProcessInvoice}
          exact
          component={DashboardContainer}
        />
        {/* <Route path={Router.manageAllocatedHours} exact component={DashboardContainer} /> */}
        {/* <Route path={Router.manageStorage} exact component={DashboardContainer} /> */}
        <Route
          path={Router.managePackage}
          exact
          component={DashboardContainer}
        />
        {/* <Route path={Router.manageService} exact component={DashboardContainer} /> */}
        <Route
          path={Router.manageProfile}
          exact
          component={DashboardContainer}
        />
        <Route
          path={Router.editPaymentMethod}
          exact
          component={DashboardContainer}
        />
        <Route
          path={Router.paymentMethod}
          exact
          component={DashboardContainer}
        />
        <Route
          path={Router.invoiceDetail}
          exact
          component={DashboardContainer}
        />
        <Route path={Router.invoices} exact component={DashboardContainer} />
        <Route path={Router.retail} exact component={DashboardContainer} />
        <Route
          path={Router.generalSetting}
          exact
          component={DashboardContainer}
        />
        <Route path={Router.cloneOrder} exact component={DashboardContainer} />
        <Route
          path={Router.editTeamMember}
          exact
          component={DashboardContainer}
        />
        <Route path={Router.teamMember} exact component={DashboardContainer} />
        {/* <Route path={Router.attachmentId} exact component={PdfViewerContainer} /> */}
        <Route
          path={Router.attachmentDetail}
          exact
          component={AnnotationContainer}
        />
        <Route
          path={Router.versionDetail}
          exact
          component={AnnotationContainer}
        />
        <Route path={Router.orderDetail} exact component={DashboardContainer} />
        <Route path={Router.createOrder} exact component={DashboardContainer} />
        <Route
          path={Router.createCustomer}
          exact
          component={DashboardContainer}
        />
        <Route
          path={Router.customerDetail}
          exact
          component={DashboardContainer}
        />
        <Route path={Router.home} exact component={DashboardContainer} />
        <Route
          path={Router.guestAnnotation}
          exact
          component={GuestAnnotation}
        />
        <Route
          path={Router.archivedOrder}
          exact
          component={DashboardContainer}
        />
        <Route path={Router.myImages} exact component={DashboardContainer} />
        <Route path={Router.reportUsage} exact component={DashboardContainer} />
        <Route
          path={Router.usageReporting}
          exact
          component={DashboardContainer}
        />
        <Route
          path={Router.corporateStaff}
          exact
          component={DashboardContainer}
        />
        <Route
          path={Router.editCorporateStaff}
          exact
          component={DashboardContainer}
        />
        <Route
          path={Router.activeOrderAssignment}
          exact
          component={DashboardContainer}
        />
        <Route path={Router.oldKanban} exact component={DashboardContainer} />
        <Route
          path={Router.orderListView}
          exact
          component={DashboardContainer}
        />
        <Redirect to={redirectTo} />
      </Switch>
    )
  }
  return routes
}

export default router
