import React, { useCallback } from 'react'
import { Rect } from 'react-konva'

const DrawingArea = ({
  id,
  width,
  height,
  isMouseDrawAction = true,
  activeDrawingArea = '',
}) => {
  const handleMouseEnter = useCallback(() => {
    if (isMouseDrawAction) {
      const el = document.querySelector('.canvas-view')

      if (
        activeDrawingArea !== '' &&
        parseInt(activeDrawingArea.replace('group-', '')) !== parseInt(id)
      ) {
        if (el.classList.contains('cursor-default')) {
          el.classList.remove('cursor-default')
          el.classList.add('cursor-disable')
        }
      } else {
        if (el.classList.contains('cursor-default')) {
          el.classList.remove('cursor-default')
          el.classList.add('cursor-cell')
        }
      }
    }
  }, [activeDrawingArea, id, isMouseDrawAction])

  const handleMouseLeave = useCallback(
    (e) => {
      if (isMouseDrawAction) {
        const el = document.querySelector('.canvas-view')

        if (el.classList.contains('cursor-cell')) {
          el.classList.remove('cursor-cell')
          el.classList.add('cursor-default')
        } else if (el.classList.contains('cursor-disable')) {
          el.classList.remove('cursor-disable')
          el.classList.add('cursor-default')
        }
      }
    },
    [isMouseDrawAction]
  )

  return (
    <Rect
      id={`drawing-area-${id}`}
      name="drawing-area"
      x={0}
      y={0}
      width={width}
      height={height}
      fill="rgba(255,255,255,0)"
      strokeWidth={0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    />
  )
}

export default React.memo(DrawingArea)
