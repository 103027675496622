import { isEqual } from 'lodash'
import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'
import { defaultFilterOrder } from '../../components/ArchivedOrderFilter/ArchivedOrderFilterContainer'

const initialState = {
  preset: null,
}

const archivedFilterOrder = (state, action) => {
  if (isEqual(action.preset, defaultFilterOrder) && state.preset === null) {
    return state
  }

  return updateObject(state, {
    preset: action.preset,
  })
}

const archivedFilterOrderClear = (state) => {
  return updateObject(state, {
    preset: null,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ARCHIVED_PRESET_FILTER:
      return archivedFilterOrder(state, action)
    case actionTypes.ARCHIVED_PRESET_FILTER_CLEAR:
      return archivedFilterOrderClear(state)
    default:
      return state
  }
}

export default reducer
