import { baseURLEndpoint } from './constant'

export default {
  NAME: 'Snap',
  LOGIN_TITLE: ' Snap - Login | Graphic Design Prints',
  KANBAN: 'https://app.snap.com.au',
  DOMAIN: baseURLEndpoint.substring(0, baseURLEndpoint.length - 6),
  REGISTER:
    baseURLEndpoint.substring(0, baseURLEndpoint.length - 6) + 'pricing-plans',
}
