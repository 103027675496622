import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

import './Prompt.scss'
import Logo from '../Logo/Logo'
import { buttonPromptType, promptType } from '../../shared/promptType'

const duration = 250

const ButtonAction = ({ btnType, onActionOK, onActionCancel }) => {
  let buttons = null

  switch (btnType) {
    case buttonPromptType.CONTINUECANCEL:
      buttons = (
        <>
          <button type="button" className="btn btn-danger" onClick={onActionOK}>
            Continue
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onActionCancel}
          >
            Cancel
          </button>
        </>
      )
      break
    case buttonPromptType.YESNO:
      buttons = (
        <>
          <button
            type="button"
            className="btn btn-darkprimary"
            onClick={onActionOK}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onActionCancel}
          >
            No
          </button>
        </>
      )
      break
    case buttonPromptType.CONTINUE:
      buttons = (
        <button
          type="button"
          className="btn btn-darkprimary"
          onClick={onActionOK}
        >
          Continue
        </button>
      )
      break
    default:
      buttons = (
        <button
          type="button"
          className="btn btn-darkprimary"
          onClick={onActionOK}
        >
          Ok
        </button>
      )
  }

  return buttons
}

const Prompt = ({
  title,
  content,
  show,
  onActionOK,
  onActionCancel,
  type,
  btnType,
  childComponent,
  cancelCallback = null,
}) => {
  const renderMessages = (messages) => {
    return {
      __html: messages,
    }
  }

  return (
    <CSSTransition
      in={show}
      timeout={duration}
      classNames="prompt-transition"
      mountOnEnter
      unmountOnExit
    >
      <div>
        <div className="modal fade prompt">
          <div className="modal-dialog modal-dialog-centered prompt-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <div className="d-flex mb-3">
                  <Logo width={120} />
                </div>
                <div className="d-flex">
                  {type === promptType.PRIMARY ? (
                    <h5 className="modal-title">{title}</h5>
                  ) : (
                    <h5 className="modal-title text-warning">{title}</h5>
                  )}
                </div>
              </div>
              <div className="modal-body">
                <div
                  className="modal-body-child"
                  dangerouslySetInnerHTML={renderMessages(content)}
                ></div>
                {childComponent}
              </div>
              <div className="modal-footer">
                <ButtonAction
                  btnType={btnType}
                  onActionOK={onActionOK}
                  onActionCancel={() => {
                    onActionCancel()
                    if (typeof cancelCallback === 'function') cancelCallback()
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        {show && <div className="modal-backdrop prompt-backdrop fade"></div>}
      </div>
    </CSSTransition>
  )
}

Prompt.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  className: PropTypes.string,
}

export default Prompt
