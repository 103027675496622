export default class FormHandler {
  constructor () {
    this.data = {};
  }

  append = (name, value) => {
    return this.data[name] = value;
  }

  all = () => this.data;
}