import React from 'react'
import PropTypes from 'prop-types'
import * as Icon from 'react-feather'

import './Navigation.scss'

const Navigation = ({ onNext = () => {}, onPrev = () => {} }) => {
  return (
    <div className="attachment-nav-wrapper">
      <div className="nav-left">
        <button type="button" className="btn btn-icon" onClick={onPrev}>
          <Icon.ChevronLeft className="feather" />
        </button>
      </div>
      <div className="nav-right">
        <button type="button" className="btn btn-icon" onClick={onNext}>
          <Icon.ChevronRight className="feather" />
        </button>
      </div>
    </div>
  )
}

Navigation.propTypes = {
  onNext: PropTypes.any,
  onPrev: PropTypes.any,
}

export default Navigation
