import { Observable } from 'rxjs'
import AxiosSubscriber from '../../factory/AxiosSubscriber'
import { baseURLEndpoint } from '../../shared/constant'

export class RequestS3TokenAPI {
  constructor() {
    this.subscription = null
  }

  subscribe(id, file, type, next, complete, error, filters = null) {
    const token = localStorage.getItem('token')
    let fileName = file.name.replaceAll(' ', '-')
    fileName = fileName.replaceAll('(', '-')
    fileName = fileName.replaceAll(')', '-')
    fileName = fileName.replaceAll('+', '-')
    const data = {
      name: fileName,
      size: file.size,
      type: file.type,
    }
    const httpConfig = {
      url: `${baseURLEndpoint}/upload/upload-token/${type}/${id}`,
      method: 'post',
      data: data,
      params: filters ? filters : null,
      headers: {
        accept: 'application/json',
        'Content-Type': `application/json`,
        Authorization: `Bearer ${token}`,
      },
    }

    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig, false)
    })

    this.subscription = observable$.subscribe({
      next: next,
      complete: complete,
      error: error,
    })
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe()
    }
  }
}
