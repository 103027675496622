import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'

import Logo from '../Logo/Logo'
import Modal from '../Modal/Modal'
import { ReactivateSubscriptionLeaveAPI } from '../../api/User/ReactivateSubscriptionLeave'
import toastCenter from '../../shared/toastCenter'
import { userDetailService } from '../../services/userDetailService/userDetailService'
import { AbilityContext } from '../../context/abilityContext'
import { abilityAction, abilityComponent } from '../../shared/ability'

const SubscriptionLeaveModal = ({ reactivatedDate = null, show = false }) => {
  const [showModal, setShowModal] = useState(false)

  const abilityContext = useContext(AbilityContext)

  useEffect(() => {
    if (show && localStorage.getItem('subscription-leave-modal') == null) {
      setTimeout(() => {
        setShowModal(true)
        localStorage.setItem('subscription-leave-modal', true)
      }, 500)
    }
    return () => {
      setShowModal(false)
    }
    // eslint-disable-next-line
  }, [])

  const handleReactivateSubscription = () => {
    const reactivateSubscriptionLeaveAPI = new ReactivateSubscriptionLeaveAPI()

    const onNext = (response) => {
      if (response.success) {
        toastCenter.message('Success', response.message)
      } else {
        toastCenter.messageServerErrorCustom(response)
      }

      userDetailService.emitReload(true)
      setShowModal(false)
      localStorage.removeItem('subscription-leave-modal')
    }
    const onComplete = () => {}
    const onError = () => {
      toastCenter.messageServerError()
    }

    reactivateSubscriptionLeaveAPI.subscribe(onNext, onComplete, onError)
  }

  return (
    <Modal
      show={showModal}
      onHideModal={() => setShowModal(false)}
      isForceShow={true}
      modalSize="modal-md"
      useClose={false}
    >
      <div className="d-flex flex-column">
        <div className="mb-3">
          <Logo width={120} />
        </div>
        <div className="mb-3">
          <span className="text-16 font-weight-bold">Subscription Leave</span>
        </div>
        <div className="">
          {abilityContext.can(
            abilityAction.UPDATE,
            abilityComponent.SUBSCRIPTION_LEAVE
          ) ? (
            <p>
              You're in a subscription leave period and will automatically be
              reactivated on {reactivatedDate ? reactivatedDate : 'unknown'}. If
              you consider continuing your subscription, please click the
              reactivate subscription button.
            </p>
          ) : (
            <p>
              You're in a subscription leave period and will automatically be
              reactivated on {reactivatedDate ? reactivatedDate : 'unknown'}.
              Please contact the the owner about the subscription.
            </p>
          )}
        </div>
        <div>
          {abilityContext.can(
            abilityAction.UPDATE,
            abilityComponent.SUBSCRIPTION_LEAVE
          ) ? (
            <>
              <button
                type="button"
                className="btn btn-secondary mr-3"
                onClick={() => setShowModal(false)}
              >
                Continue Subscription Leave
              </button>
              <button
                type="button"
                className="btn btn-darkprimary"
                onClick={handleReactivateSubscription}
              >
                Reactivate Subscription
              </button>
            </>
          ) : (
            <button
              type="button"
              className="btn btn-secondary mr-3"
              onClick={() => setShowModal(false)}
            >
              Close
            </button>
          )}
        </div>
      </div>
    </Modal>
  )
}

SubscriptionLeaveModal.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default SubscriptionLeaveModal
