import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import tippy from 'tippy.js'

import './MembershipStorage.scss'
import { formatBytes } from '../../../shared/fileHelper'

const MembershipStorage = ({ user, isLoading, style, history }) => {
  const [subscriptionRetail, setSubscriptionRetail] = useState(0)
  const containerRef = useRef()
  const [customTippy, setCustomTippy] = useState()

  useEffect(() => {
    const additionalStoragePopup = document.getElementById(
      'additional-storage-popup'
    )

    if (customTippy)
      customTippy.setProps({
        content: additionalStoragePopup.innerHTML,
        animation: 'shift-away',
        zIndex: 1020,
        appendTo: containerRef.current,
        allowHTML: true,
      })
  }, [customTippy])

  useEffect(() => {
    if (user !== null) {
      let totalSubscription = 0

      totalSubscription += parseInt(
        user.retail ? user.retail.additionalStorageQuota : 0
      )
      if (totalSubscription > 0) setSubscriptionRetail(totalSubscription)
    }

    const additionalStoragePopup = document.getElementById(
      'additional-storage-popup'
    )

    const customTippy = tippy(containerRef.current, {
      content: additionalStoragePopup.innerHTML,
      animation: 'shift-away',
      zIndex: 1020,
      appendTo: containerRef.current,
      allowHTML: true,
    })
    setCustomTippy(customTippy)
  }, [user])

  if ((user === null && isLoading) || (user === null && !isLoading)) {
    return null
  }

  const customerUsed = parseInt(user.storages.used)
  const customerQuota = parseInt(user.storages.quota)
  const used =
    customerUsed + (user.retail ? user.retail.additionalStorageUsed : 0)
  const space =
    customerQuota === 0
      ? 0
      : parseInt(customerQuota) +
        parseInt(user.retail ? user.retail.additionalStorageQuota : 0)

  const percentOfUsed = customerUsed !== 0 ? (used / space) * 100 : 0
  const isWarningFull = parseInt(percentOfUsed) >= 90

  return (
    <div
      className="membership-status"
      ref={containerRef}
      style={Object.assign(style, {})}
    >
      {/* 
        Tippy custom HTML
      */}
      <div id="additional-storage-popup" style={{ display: 'none' }}>
        <span>
          Your allocated subscription storage minus the amount used for your
          orders.
        </span>
        {subscriptionRetail > 0 && (
          <>
            <div>
              <div className="d-flex justify-content-between mt-2">
                <div>{user.membership.title} membership</div>
                <div>{formatBytes(parseInt(user.storages.quota))}</div>
              </div>
              {subscriptionRetail > 0 ? (
                <div className="d-flex justify-content-between mt-1 text-subscription-retail">
                  <div>Subscription Additional Storage</div>
                  <div>
                    {formatBytes(parseInt(user.retail.additionalStorageQuota))}
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </>
        )}
      </div>
      <div className="clearfix text-membership-status w-100">
        <div className="float-left d-flex">
          <span className="text-12 text-light">Used Storage</span>
          {subscriptionRetail > 0 && (
            <span className="dot-subscription-retail"> </span>
          )}
        </div>
        <div className="float-right clearfix d-flex align-items-center">
          <div
            className={`text-12 d-flex flex-row align-items-center ${
              user.storages.quota !== '&infin;' && isWarningFull
                ? 'text-danger'
                : 'text-light'
            }`}
          >
            <strong>{formatBytes(parseInt(used))}</strong>
          </div>
        </div>
      </div>
    </div>
  )
}

MembershipStorage.propTypes = {
  style: PropTypes.object,
}

export default withRouter(MembershipStorage)
