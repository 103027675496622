import React from 'react'

const SvgLogo = (props) => (
  <svg viewBox="0 0 121.6 120.2" {...props}>
    <path
      d="M78.5 14.2C57.7 1.5 30.5 8.1 17.8 29s-6.1 48 14.8 60.7c.3.2.6.4 1 .6 22.8 13.5 66.1 5.7 73.9-7.6s-6.2-55-29-68.5zM62.7 73.5c-9.7 0-17.5-7.8-17.5-17.5S53 38.5 62.7 38.5 80.2 46.3 80.2 56s-7.8 17.5-17.5 17.5z"
      fill="#63C7A9"
    />
    <path
      d="M85.3 9.2C71.4 2.5 31.1 20.2 19.7 44c-10.5 22-1.2 48.4 20.7 58.9s48.4 1.2 58.9-20.7c11.5-23.9-.1-66.3-14-73zM62.7 73.5c-9.7 0-17.5-7.8-17.5-17.5S53 38.5 62.7 38.5 80.2 46.3 80.2 56s-7.8 17.5-17.5 17.5z"
      fill="#eb0045"
    />
    <path
      d="M102.8 44.3c-5.5-12.3-14-24.1-24.3-30.1-3.6-2.2-7.2-3.9-11.3-4.9-17.7 4.4-39.6 18.3-47.5 34.8-4.1 8.3-5.5 18.1-3.4 27.9 3.8 8 9.7 13.6 17.3 18.2 15.9 9.4 42 8.3 58.8 2.4 2.8-3.1 5.1-6.6 6.9-10.4 5.1-10.5 5.7-24.4 3.5-37.9zM62.7 73.5c-9.7 0-17.5-7.8-17.5-17.5S53 38.5 62.7 38.5 80.2 46.3 80.2 56s-7.8 17.5-17.5 17.5z"
      fill="#031e3b"
    />
  </svg>
)

export default SvgLogo
