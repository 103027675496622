import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  count: 0,
  messages: [],
  append: [],
  meta: {},
  content: null
};

const pushCountNotification = (state, action) => {
  return updateObject(state, {
    count: action.count
  });
};

const fetchMessageNotification = (state, action) => {
  return updateObject(state, {
    messages: action.messages,
    meta: action.meta,
    append: [...state.messages, ...action.messages]
  });
};

const pushMessageNotification = (state, action) => {
  return updateObject(state, {
    content: action.content
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.NOTIFICATION_COUNT:
      return pushCountNotification(state, action);
    case actionTypes.NOTIFICATION_MESSAGES:
      return fetchMessageNotification(state, action);
    case actionTypes.NOTIFICATION_PUSH_MESSAGE:
      return pushMessageNotification(state, action);
    default:
      return state;
  }
};

export default reducer;