import React from 'react';

const NotSupported = () => (
  <div style={{
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff',
    fontSize: '20px',
    fontWeight: 'bold'
  }}>
    No preview available
  </div>
)

export default NotSupported;