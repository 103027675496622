import * as actionTypes from "./actionTypes";

export const promptReasonShow = (label, callbackOkActionPrompt, callbackCancelActionPrompt) => {
  return {
    type: actionTypes.PROMPT_REASON_SHOW,
    label: label,
    callbackOkActionPrompt: callbackOkActionPrompt,
    callbackCancelActionPrompt: callbackCancelActionPrompt,
  };
};

export const promptReasonHide = () => {
  return {
    type: actionTypes.PROMPT_REASON_HIDE
  };
};
