import * as actionTypes from "./actionTypes";

export const routeAccessPath = (path) => {
  return {
    type: actionTypes.ROUTE_PATH_REDIRECT,
    path: path
  };
};

export const routeRemoveRedirect = () => {
  return {
    type: actionTypes.ROUTE_HAS_REDIRECTED
  };
}