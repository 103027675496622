import { Observable } from 'rxjs';
import AxiosSubscriber from '../../factory/AxiosSubscriber';

export class NotificationAPI {
  constructor() {
    this.subscription = null;
    this.params = {
      page: 1,
      limit: 10
    }
  }

  setParams(params) {
    this.params = params
    return this
  }

  subscribe(next, complete, error) {
    const token = localStorage.getItem("token");
    const httpConfig = {
      url: `/message/groups`,
      method: 'get',
      params: this.params,
      headers: {
        'accept': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    };

    let observable$ = new Observable((observer) => {
      return new AxiosSubscriber(observer, httpConfig);
    });

    this.subscription = observable$.subscribe({
      next: next,
      complete: complete,
      error: error
    });
  }

  unsubscribe() {
    if (this.subscription !== undefined && this.subscription !== null) {
      this.subscription.unsubscribe();
    }
  }
}