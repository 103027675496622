import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import feather from 'feather-icons';

const FeatherIcon = ({ name, className }) => {
  useEffect(() => {
    feather.replace();
  }, [name]);

  return (
    <span className={className}><i data-feather={name} className={className}></i></span>
  );
};

FeatherIcon.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default FeatherIcon;