import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../shared/utility";

const initialState = {
  show: false
};

const backdropToggle = (state, action) => {
  return updateObject(state, {
    show: action.show
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BACKDROP_TOGGLE:
      return backdropToggle(state, action);
    default:
      return state;
  }
};

export default reducer;