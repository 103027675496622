import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import Joyride, { STATUS } from 'react-joyride'
import { createNewOrderTourGuideService } from '../../services/tourGuideService/createNewOrderTourGuideService'
import { tourGuideAction } from '../../shared/tourGuideAction'
import { userDetailService } from '../../services/userDetailService/userDetailService'

const CreateNewOrderTourGuide = ({ match, run }) => {
  const [autoRun, setAutoRun] = useState(false)
  const [steps, setSteps] = useState([])

  const customStyles = {
    overlay: {
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
    },
    buttonClose: {
      display: 'none',
    },
  }

  const handleJoyrideCallback = (data) => {
    const { status, lifecycle } = data

    if (
      [STATUS.FINISHED, STATUS.SKIPPED].includes(status) &&
      lifecycle === 'complete'
    ) {
      tourGuideAction('createNewOrder', 'false', () => {
        userDetailService.emitReload(true)
      })
    }
  }

  useEffect(() => {
    const subscription = createNewOrderTourGuideService
      .reload()
      .subscribe((value) => {
        if (
          value.run &&
          value.groupTitle &&
          match &&
          match.path === '/orders/new'
        ) {
          setSteps([
            {
              target: '.new-order-detail-fields',
              title: value.groupTitle,
              content: (
                <p>
                  Please note that every mandatory field must be filled (marked
                  with a red asterisk <span className="tour-red">*</span>).
                  Therefore, please complete the information by filling out the
                  empty fields.
                </p>
              ),
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              spotlightPadding: 0,
            },
            {
              target: '.new-order-sidebar',
              title: 'New Order sidebar',
              content: 'Here you can set due date, add collaborator, etc..',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              spotlightPadding: 3,
              styles: {
                spotlight: {
                  borderRadius: '6px',
                  border: '3px solid #ffffff',
                },
              },
            },
            {
              target: '.tour-due-date',
              title: 'Due Date',
              content: 'The time estimate by which the order will be finished.',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              spotlightPadding: 3,
              styles: {
                spotlight: {
                  borderRadius: '6px',
                  border: '3px solid #ffffff',
                },
              },
            },
            {
              target: '.tour-collaborator',
              title: 'Collaborators',
              content:
                'Is the team member that assists you in working on the order.',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              spotlightPadding: 3,
              styles: {
                spotlight: {
                  borderRadius: '6px',
                  border: '3px solid #ffffff',
                },
              },
            },
            {
              target: '.new-order-attachment',
              title: 'Order attachement',
              content:
                'Please upload your file attachment(s) for the order here using the file types accepted.',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              spotlightPadding: 0,
              placement: 'right-start',
            },
            {
              target: '.create-order-actions',
              content:
                '`Cancel` to discard the order. `Save As Draft` to save but not publish the order. `Create Order` to save and publish the order.',
              disableBeacon: true,
              type: 'hover',
              isFixed: true,
              spotlightPadding: 0,
              lastStep: true,
            },
          ])

          setAutoRun(run)
        }
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [run, match])

  return (
    <div className="tour-guide-create-new-order">
      <Joyride
        callback={handleJoyrideCallback}
        run={autoRun}
        steps={steps}
        styles={customStyles}
        floaterProps={{ disableAnimation: true }}
        showSkipButton={true}
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Finish',
          next: 'Next',
          skip: 'Skip',
        }}
        continuous={true}
      />
    </div>
  )
}

export default withRouter(CreateNewOrderTourGuide)
