import React, { useContext } from 'react'

import CanvasContainer from '../Canvas/CanvasContainer'
import ImgRender from '../ImgRender/ImgRender'
import { ShapeContext } from '../../context/shapeContext'
import CanvasView from '../CanvasView/CanvasView'

function ImgViewer() {
  const { isNewVersionCanvas } = useContext(ShapeContext)

  return (
    <>
      {isNewVersionCanvas ? <CanvasView /> : <CanvasContainer />}

      <ImgRender />
    </>
  )
}

export default React.memo(ImgViewer)
