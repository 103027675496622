import { TourGuideAPI } from "../api/User/TourGuide"
import toastCenter from "./toastCenter"
import FormHandler from "../factory/FormHandler"

export const tourGuideAction = (name, value, callback = null) => {
    const data = new FormHandler();
    data.append("data", value)

    const tourGuideAPI = new TourGuideAPI()
    const onNext = (response) => {
        if(!response.success) {
            toastCenter.messageServerErrorCustom(response);
        }
        else {
            if(typeof callback === "function") {
                callback();
            }            
        }
    }
    const onComplete = () => {}
    const onError = () => {
        toastCenter.messageServerError()
    }

    tourGuideAPI.subscribe(name, data.all(), onNext, onComplete, onError);
}