import * as actionTypes from '../actions/actionTypes'
import { updateObject } from '../../shared/utility'

// deprecated
const initialState = {
  search: '',
}

const archivedQuickSeach = (state, action) => {
  return updateObject(state, {
    search: action.search,
  })
}

const archivedQuickSeachClear = (state) => {
  return updateObject(state, {
    search: '',
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ARCHIVED_QUICK_SEARCH:
      return archivedQuickSeach(state, action)
    case actionTypes.ARCHIVED_QUICK_SEARCH_CLEAR:
      return archivedQuickSeachClear(state)
    default:
      return state
  }
}

export default reducer
