import { Subject } from 'rxjs';

const subject = new Subject();
const subjectStart = new Subject();

export const restartTourGuideService = {
    emitReload: val => subject.next(val),
    reload: () => subject.asObservable()
};

export const startTourGuideService = {
    emitReload: val => subjectStart.next(val),
    reload: () => subjectStart.asObservable()
}