import React from 'react';
import PropTypes from 'prop-types';
import LaddaButton, { S, EXPAND_LEFT } from 'react-ladda';

import './Button.css';
import './DefaultButton.scss';
import theme from '../../shared/theme';

const Button = ({ isLoading, children, disabled, onClick, color, size, style, spinnerSize, spinnerColor, spinnerLine, ...rest }) => {
  return (
    <LaddaButton
      loading={isLoading}
      onClick={onClick}
      data-color={color}
      data-size={size}
      data-style={style}
      data-spinner-size={spinnerSize}
      data-spinner-color={spinnerColor}
      data-spinner-lines={spinnerLine}
      disabled={disabled}
      {...rest}
    >{children}</LaddaButton>
  )
}

Button.defaultProps = {
  color: theme.DARKBLUE,
  size: S,
  style: EXPAND_LEFT,
  spinnerSize: 25,
  spinnerColor: '#fff',
  spinnerLine: 25,
  disabled: false
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired
}

export default Button;